const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
const GET_USER_DETAILS = 'GET_USER_DETAILS';
const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
const SET_USER_DETAILS = 'SET_USER_DETAILS';
const GET_CUSTOMER_PAYMENT_DETAILS = 'GET_CUSTOMER_PAYMENT_DETAILS';
const SET_CUSTOMER_PAYMENT_DETAILS = 'SET_CUSTOMER_PAYMENT_DETAILS';
const CANCEL_CUSTOMER_SUBSCRIPTION = 'CANCEL_CUSTOMER_SUBSCRIPTION';
const LOGOUT_USER = 'LOGOUT_USER';
const SET_AUTHENTICATION_DATA = 'SET_AUTHENTICATION_DATA';
const UPGRADE_SUBSCRIPTION = 'UPGRADE_SUBSCRIPTION';
const SET_IS_HAVING_ACTIVE_SUBSCRIPTION = 'SET_IS_HAVING_ACTIVE_SUBSCRIPTION';
export default {
  AUTHENTICATE_USER,
  LOGOUT_USER,
  SET_AUTHENTICATION_DATA,
  GET_USER_DETAILS,
  SET_USER_DETAILS,
  GET_CUSTOMER_PAYMENT_DETAILS,
  SET_CUSTOMER_PAYMENT_DETAILS,
  UPDATE_USER_DETAILS,
  CANCEL_CUSTOMER_SUBSCRIPTION,
  UPGRADE_SUBSCRIPTION,
  SET_IS_HAVING_ACTIVE_SUBSCRIPTION,
};
