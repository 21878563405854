import { promiseReturn } from './serviceUtils';
import appConfig from '../config/appConfig';
const BASE_URI = `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3`;
const USER_BASE_URI = `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/user`;

export const getAllMusic = async () => {
  const url = `${BASE_URI}`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getMusicLibrary = async () => {
  const url = `${BASE_URI}/library`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getMusicByName = async (name) => {
  const url = `${BASE_URI}/name`;
  const urlData = {
    url,
    method: 'post',
    params: { name },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getUserPlayList = async () => {
  const url = `${BASE_URI}/playlist`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getUserInterestPlaylist = async (data) => {
  const url = `${USER_BASE_URI}/getUserInterestPlaylist`;
  const urlData = {
    url,
    method: 'post',
    params: data,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const syncMusicWithBoxCom = async () => {
  const url = `${BASE_URI}/sync`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getUserSessionPlayList = async (data) => {
  let url = USER_BASE_URI;
  if (data.subscription.toLowerCase() === 'platinum') {
    url = `${url}/getPlatinumSessionPlaylist`;
  } else {
    url = `${url}/getSessionPlaylist`;
  }
  const urlData = {
    url,
    method: 'post',
    params: data,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getMp3ByIdArray = async (list) => {
  const url = `${BASE_URI}/id-array`;
  const urlData = {
    url,
    method: 'post',
    params: { list },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const syncUserSession = () => {
  const urlData = {
    url: `${USER_BASE_URI}/syncSession`,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

