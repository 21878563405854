import types from '../AdminPanel/InterestManagement/Redux/types';
const INITIAL_STATE = {
  interestList: [],
  interestData: null,
};

const reducerHandler = (action, state = INITIAL_STATE) => {
  const reducerObject = {
    [types.SET_INTEREST_LIST]: () => ({
      ...state,
      interestList: action.value,
    }),
    [types.SET_INTEREST_DATA]: () => ({
      ...state,
      interestData: action.value,
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const interestManagement = (state, action) => reducerHandler(action, state);

export default interestManagement;
