import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  pagePara: {
    fontSize: '1.15rem',
  },
  docInformation: {
    marginTop: 20,
  },
  cardDetails: {
    flex: 1,
    fontSize: '1.2rem',
  },
  cardMedia: {
    width: 160,
  },
});

const DocInformation = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} className={classes.docInformation}>
      <Card className={classes.card}>
        <div className={classes.cardDetails}>
          <CardContent>
            <Typography component="h2" variant="h5">
              Terry Brussel-Rogers, CCHt.
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Certified Clinical Hypnotherapist registered with the National Guild of Hypnotists.
            </Typography>
            <Typography variant="subtitle1" align="justify" paragraph className={classes.pagePara}>
              With over 5 decades of experience, Terry Brussel-Rogers, CCHt has been helping clients reach their highest potential most of her life. She has done this through her Seven Keys to Self-Actualization, a systematic program which uses self-hypnosis within a customized framework to enable
              others to realize their personal goals, desires, and dreams.
            </Typography>
            <Typography variant="subtitle1" align="justify" paragraph className={classes.pagePara}>
              Terry graduated Cum Laude from California State University at Northridge with a B.A. in Psychology. She also studied for four years at the Hypnosis Motivation Institute, the Emile Franchel School of Living Science, and the Hypnotism Training Center.
            </Typography>
            <Typography variant="subtitle1" align="justify" paragraph className={classes.pagePara}>
              In her work in private practice and directing Success Center, Terry has assisted her clients and has taught hypnotists, healers, and coaches in the U.S., Canada, U.K., and many other countries to work with such issues as stress management, habit control, success/sales motivation, and learning/memory enhancement.
            </Typography>
            <Typography variant="subtitle1" align="justify" paragraph className={classes.pagePara}>
              Articles she has written have included from Stress to Success Stress Strategists (Royal Publishing forward by Dr. Norman Vincent Peale), Slow Down and Turn Back the Aging Process for Healthy Longevity, and Memory and Mental Excellence through Self Hypnosis. Terry Brussel-Rogers, CCHt has also written many books including Seven Key Turn-Key System for Building a Successful Hypnotherapy Practice, Matchmaker's Corner: Choosing, Finding and Attracting Your Life Mate, The Spiritual Spark: Hypnotic Enhancement of Psychic Abilities, Inspiration at Will, and Take Command of your Body: the Hypnotic Fountain of Youth, among many others.
            </Typography>
            <Typography variant="subtitle1" align="justify" paragraph className={classes.pagePara}>
              She has created many unique audio recordings and books, which are all available for purchase through her web site{' '}
              <a target="_blank" href="https://acesuccess.com/" rel="noopener noreferrer">
                www.acesuccess.com
              </a>
            </Typography>
          </CardContent>
        </div>
        {/* <Hidden xsDown>
            <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
          </Hidden> */}
      </Card>
    </Grid>
  );
};

export default DocInformation;
