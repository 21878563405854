import React, { useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { IS_FOR_HYPNOSIS, IS_FOR_POLYAMORY, IS_MATURE_CONTENT } from '../../../utils/appConstants';
import { findMp3ByName } from '../../../Services/fileManagementServiceClient';
import DialogComponent from '../../../components/DialogComponent';
import './style.scss';
import FileTypeSelection from '../FileTypeSelection';

const CreateNewFile = (props) => {
  const { toggle, open } = props;
  const [fileData, setFileData] = useState({
    file: '',
    cover: '',
    sku: '',
    name: '',
    description: '',
    longDescription: '',
    shortDescription: '',
  });

  const { register, handleSubmit, errors } = useForm();

  const header = 'Create New File';

  const checkMimeType = (event) => {
    const { name } = event.target;
    const types = {
      file: ['audio/mpeg'],
      cover: ['image/jpeg', 'image/png'],
    };
    //getting file object
    const file = event.target.files[0];
    //define message container
    let err = '';
    // list allow mime type

    if (!types[name].includes(file.type)) {
      // create error message and assign to container
      err += file.type + ' is not a supported format\n';
    }

    if (err !== '') {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      toast.warn(err);
      return false;
    }
    return true;
  };

  const onFileChange = (e) => {
    const { name } = e.target;
    if (!checkMimeType(e)) {
      return;
    }
    setFileData({ ...fileData, [name]: e.target.files[0] });
  };

  const isNameExist = async (name) => {
    const response = await findMp3ByName(name);
    return response.data && !!response.data.length;
  };

  const onChangeHandler = async (event) => {
    const { name, value, checked, type } = event.target;
    if (type === 'file') {
      onFileChange(event);
    } else if (type === 'checkbox') {
      setFileData({ ...fileData, [name]: checked });
    } else {
      setFileData({ ...fileData, [name]: value });
    }
  };

  const onUploadProgress = (progressEvent) => {
    const { setProgressBarValue } = props;
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    setProgressBarValue(percent);
  };

  const onSubmit = () => {
    const { createFileData } = props;
    const formData = new FormData();
    for (const i in fileData) {
      if (fileData.hasOwnProperty(i)) {
        formData.append(i, fileData[i]);
      }
    }
    createFileData({ formData, onUploadProgress });
    toggle();
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="create-file-form">
      <Grid container spacing={3} className="create-file-form-container">
        <Grid item xs={12}>
          <TextField
            required
            variant="outlined"
            size="small"
            id="name"
            name="name"
            label="File Name"
            defaultValue={fileData.name}
            fullWidth
            autoComplete="file-name"
            inputRef={register({ required: 'File name Required.', validate: async (value) => !(await isNameExist(value)) || 'File name already exist.', maxLength: { value: 80, message: 'File name should be less than 80 characters.' } })}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="outlined" size="small" id="sku" name="sku" label="SKU" defaultValue={fileData.sku} fullWidth autoComplete="file-sku" onChange={onChangeHandler} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant="outlined"
            size="small"
            id="description"
            name="description"
            label="Mp3 name (End user Side)"
            defaultValue={fileData.description}
            fullWidth
            autoComplete="file-description"
            onChange={onChangeHandler}
            inputRef={register({ required: 'Mp3 name (End user Side) Required.' })}
            error={!!errors.description}
            helperText={errors.description && errors.description.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="outlined" size="small" id="shortDescription" name="shortDescription" label="Short Description" defaultValue={fileData.shortDescription} fullWidth autoComplete="short-description" onChange={onChangeHandler} />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="outlined" size="small" id="longDescription" name="longDescription" label="Long Description" defaultValue={fileData.longDescription} fullWidth autoComplete="long-description" onChange={onChangeHandler} />
        </Grid>
        <Grid item xs={12}>
          <FileTypeSelection fileData={fileData} setFileData={setFileData} />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel label={IS_MATURE_CONTENT} control={<Checkbox name="isMatureContent" id="isMatureContent" checked={fileData.isMatureContent} inputProps={{ 'aria-label': IS_MATURE_CONTENT }} />} onChange={onChangeHandler} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel label={IS_FOR_POLYAMORY} control={<Checkbox name="isForPolyamory" id="isForPolyamory" checked={fileData.isForPolyamory} inputProps={{ 'aria-label': IS_FOR_POLYAMORY }} />} onChange={onChangeHandler} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel label={IS_FOR_HYPNOSIS} control={<Checkbox name="isForHypnosis" id="isForHypnosis" checked={fileData.isForHypnosis} inputProps={{ 'aria-label': IS_FOR_HYPNOSIS }} />} onChange={onChangeHandler} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField type="file" label="Mp3 Cover" variant="outlined" name="cover" autoComplete="cover" fullWidth onChange={onChangeHandler} />
        </Grid>
        <Grid item xs={12}>
          <TextField type="file" label="Mp3 file" variant="outlined" name="file" autoComplete="file-data" inputRef={register({ required: 'File path Required.' })} error={!!errors.file} helperText={errors.file && errors.file.message} fullWidth onChange={onChangeHandler} />
        </Grid>
        <Grid item xs={12} container justify="flex-end">
          <Button color="primary" type="submit" className="upload-button">
            Upload
          </Button>
        </Grid>
      </Grid>
    </form>
  );
  return <DialogComponent body={body} header={header} open={open} onClose={toggle} fullWidth={false} />;
};

CreateNewFile.propTypes = {
  toggle: PropTypes.func,
  open: PropTypes.bool,
  createFileData: PropTypes.func,
  setProgressBarValue: PropTypes.func,
};

export default CreateNewFile;
