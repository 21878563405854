import types from '../AdminPanel/FileManagement/Redux/types';
const INITIAL_STATE = {
  fileList: [],
  fileData: null,
};

const reducerHandler = (action, state = INITIAL_STATE) => {
  const reducerObject = {
    [types.SET_FILE_LIST]: () => ({
      ...state,
      fileList: action.value,
    }),
    [types.SET_FILE_DATA]: () => ({
      ...state,
      fileData: action.value,
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const fileManagement = (state, action) => reducerHandler(action, state);

export default fileManagement;
