import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';

const Autocomplete = ({ id, options, getOptionLabel, getOptionSelected, onChange, onSelect, onBlur, label, name, value, error, helperText, required }) => (
  <MuiAutocomplete
    id={`${id}-autocomplete`}
    options={options}
    getOptionLabel={getOptionLabel}
    getOptionSelected={getOptionSelected}
    clearOnBlur={false}
    renderInput={
      (params) => <TextField
      {...params}
      id={id}
      required={required}
      fullWidth
      variant="outlined"
      size="small"
      value={value}
      onChange={onChange}
      onSelect={onSelect}
      onBlur={onBlur}
      label={label}
      name={name}
      error={error}
      helperText={helperText}
    />}
  />
);

Autocomplete.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(Object),
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Autocomplete.defaultProps = {
  id: '',
  options: [],
  getOptionLabel: () => null,
  getOptionSelected: () => null,
  onChange: () => null,
  onSelect: () => null,
  onBlur: () => null,
  label: '',
  name: '',
  required: false,
  error: false,
  helperText: '',
  value: '',
};

export default Autocomplete;
