import React from 'react';
export const subscriptions = [
  {
    title: 'Free',
    amount: '0',
    color: '#fff',
    pricingDescription: ['Free Membership', 'No Payments'],
    description: [
      'Includes ONLY the Success Center Guided Meditation Gifts.',
      <ul key="free">
        Meditation gifts: <li style={{ listStyle: 'circle' }}>Abundance: Your Money and MORE Magnet</li>
        <li style={{ listStyle: 'circle' }}>Positive Stress Management and a Healthy, Effective Immune System</li>
      </ul>,
      'Push a button and listen while you sleep (Cycles 21 sessions to optimally change your mindset)',
    ],
  },
  {
    title: 'Bronze',
    amount: '5',
    color: '#cd7f32ad',
    pricingDescription: ['30-day Trial Membership', '$14.95/mo thereafter'],
    description: ['Tailored recordings are scheduled based on your priorities', 'Push a button and listen while you sleep', 'May change goals every 90 days'],
  },
  {
    title: 'Silver',
    amount: '10',
    color: '#c0c0c0',
    pricingDescription: ['30-day Trial membership', '$34.95/mo thereafter', <span key="silver">Includes everything from the Bronze package, plus following:</span>],
    description: ['Listen to tailored recordings out of sequence at any time', 'May change goals every 30 days'],
  },
  {
    title: 'Platinum',
    amount: '39.95',
    color: '#e5e4e2',
    pricingDescription: [
      'Monthly fee',
      <>
        Requires a private{' '}
        <a className="href-link" target="_blank" rel="noreferrer noopener" href="https://acesuccess.com/product/life-guidance-discovery-session/">
          Life Guidance Discovery Session
        </a>{' '}
        before purchasing the monthly service
      </>,
      'Includes everything from the Bronze and Silver Packages, plus following:',
    ],

    description: [
      'Includes a free customized goal manifestation recording ($150 value) in sequence',
      'Includes unlimited access to  all recordings in the Success Center library',
      "May change goals every 90 days with Hypnotherapists' assistance",
      'Includes a 15-minute private consultation every 90 days to discuss goals with a Success Center Associate ($285 value)',
    ],
  },
];
