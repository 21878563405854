import { takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import types from './types';
import { syncMusicWithBoxCom, getUserPlayList, getUserSessionPlayList, getUserInterestPlaylist, getMusicLibrary, getMusicByName, getMp3ByIdArray, syncUserSession } from '../../Services/mp3ServiceClient';
import appConfig from '../../config/appConfig';

import rftsLogo from '../../images/rftsLogo.jpg';

import { stopLoader, startLoader } from '../../components/LoaderComponent/Redux/actions';
import actions, { setFreePlaylist } from './actions';
import { getUserDetails, setUserDetails } from '../../SignIn/redux/actions';
import { freePlaylist as freeList, startingMusicName } from '../../utils/appConstants';
import { updateCurrentSessionByMp3ID, updatePerSessionMp3 } from '../../Services/userManagementServiceClient';

function* getPlayList() {
  // For checking session handling
  try {
    yield call(syncUserSession);
  } catch (err) {
    console.log('Not premium user');
  }
  try {
    yield put(startLoader());
    const { userDetails, userId } = yield select((state) => state.authentication);
    let { sessionIndex = 0, subscription = 'Bronze', perSessionMp3 = 2, isFirstResponder = false, isUsingHypnosis = false } = userDetails;
    const lastDate = new Date(userDetails.sessionUpdateDate || userDetails.updatedAt);
    const lastDateStr = `${lastDate.getFullYear()}-${lastDate.getMonth()}-${lastDate.getDay()}`;
    const lastUpdatedAt = moment(lastDateStr, 'YYYY-MM-DD').valueOf();
    const newDate = new Date();
    const newDateStr = `${newDate.getFullYear()}-${newDate.getMonth()}-${newDate.getDay()}`;
    const todayDate = moment(newDateStr, 'YYYY-MM-DD').valueOf();
    let freePlaylist = [];
    if (todayDate > lastUpdatedAt || sessionIndex === 0) {
      sessionIndex = sessionIndex + 1;
    }
    if (subscription === 'Free') {
      freePlaylist = yield call(getMp3ByIdArray, freeList(isFirstResponder, isUsingHypnosis));
    }
    let mp3List = yield call(getUserSessionPlayList, { userId, session: sessionIndex, subscription });
    if (mp3List.data.length > 0) {
      let arrayList = [];
      mp3List.data.map((mp3ListItem) => mp3ListItem.audio);
      if (+perSessionMp3 === 1) {
        arrayList = mp3List.data.filter((mp3ListItem) => !mp3ListItem.played);
        if (arrayList.length > 1) {
          arrayList = arrayList.slice(0, 1);
        } else if (arrayList.length === 0) {
          arrayList = [mp3List.data[1]];
        }
      } else {
        arrayList = [...mp3List.data];
      }
      arrayList = arrayList.map((mp3ListItem) => mp3ListItem.audio);
      mp3List = yield call(getMp3ByIdArray, arrayList);
      const firstMp3 = yield call(getMusicByName, startingMusicName);
      const responseData = firstMp3.data.concat(mp3List.data).map((mp3Item) => ({
        ...mp3Item,
        name: mp3Item.name !== startingMusicName ? mp3Item.description : mp3Item.name,
        cover: mp3Item.thumbnailPath ? `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/thumbnail/${mp3Item._id}` : rftsLogo,
        musicSrc: () => Promise.resolve(`${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/download/${mp3Item._id}`),
      }));
      if (subscription === 'Free') {
        freePlaylist = firstMp3.data.concat(freePlaylist.data).map((mp3Item) => ({
          ...mp3Item,
          name: mp3Item.name !== startingMusicName ? mp3Item.description : mp3Item.name,
          cover: mp3Item.thumbnailPath ? `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/thumbnail/${mp3Item._id}` : rftsLogo,
          musicSrc: () => Promise.resolve(`${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/download/${mp3Item._id}`),
        }));
        yield put(setFreePlaylist(freePlaylist));
      }
      if (responseData) {
        yield put(getUserDetails(userId));
        yield put(actions.setPlayList(responseData));
      }
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching music list');
  }
}

function* getNextSession() {
  try {
    yield put(startLoader());
    const { userDetails, userId } = yield select((state) => state.authentication);
    const { sessionIndex = 0 } = userDetails;
    const mp3List = yield call(getUserSessionPlayList, { userId, session: sessionIndex + 1 });
    if (mp3List) {
      yield put(getUserDetails(userId));
      const responseData = mp3List.data.map((mp3Item) => ({
        ...mp3Item,
        name: mp3Item.name !== startingMusicName ? mp3Item.description : mp3Item.name,
        cover: mp3Item.thumbnailPath ? `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/thumbnail/${mp3Item._id}` : rftsLogo,
        musicSrc: () => Promise.resolve(`${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/download/${mp3Item._id}`),
      }));
      yield put(actions.setShowPlayer(false));
      if (responseData) {
        yield put(actions.setPlayList(responseData));
      }
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching music list');
  }
}

function* getInterestPlaylist() {
  try {
    yield put(startLoader());
    const { userId, userDetails } = yield select((state) => state.authentication);
    let { subscription = 'Bronze' } = userDetails;
    let mp3List = [];
    if (subscription.toLowerCase() === 'platinum') {
      mp3List = yield call(getUserPlayList);
    } else {
      mp3List = yield call(getUserInterestPlaylist, { userId });
    }
    const firstMp3 = yield call(getMusicByName, startingMusicName);
    const responseData = firstMp3.data.concat(mp3List.data).map((mp3Item) => ({
      ...mp3Item,
      name: mp3Item.name !== startingMusicName ? mp3Item.description : mp3Item.name,
      cover: mp3Item.thumbnailPath ? `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/thumbnail/${mp3Item._id}` : rftsLogo,
      musicSrc: () => Promise.resolve(`${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/download/${mp3Item._id}`),
    }));
    if (responseData) {
      yield put(actions.setPlayList(responseData));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching meditation playlist');
  }
}

function* getAllMusicList() {
  try {
    yield put(startLoader());
    const mp3List = yield call(getMusicLibrary);
    const responseData = mp3List.data.map((mp3Item) => ({
      ...mp3Item,
      name: mp3Item.name !== startingMusicName ? mp3Item.description : mp3Item.name,
      cover: mp3Item.thumbnailPath ? `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/thumbnail/${mp3Item._id}` : rftsLogo,
      musicSrc: () => Promise.resolve(`${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/download/${mp3Item._id}`),
    }));
    if (responseData) {
      yield put(actions.setPlayList(responseData));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching music library');
  }
}

function* setPerSessionMp3s(actionObj) {
  try {
    yield put(startLoader());
    const { userId, userDetails } = yield select((state) => state.authentication);
    const perSession = actionObj.value || 2;
    const response = yield call(updatePerSessionMp3, userId, perSession);
    if (response) {
      yield put(setUserDetails({ ...userDetails, perSessionMp3: perSession }));
      yield put(getUserDetails(userId));
      yield call(getPlayList);
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error setting per session MP3');
  }
}

function* setMp3AsPlayed(actionObj) {
  try {
    yield put(startLoader());
    const { userId } = yield select((state) => state.authentication);
    const mp3Id = actionObj.value;
    if (!mp3Id) {
      return;
    }
    const response = yield call(updateCurrentSessionByMp3ID, userId, mp3Id);
    if (response) {
      yield put(getUserDetails(userId));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error setting per session MP3');
  }
}

function* syncMusic() {
  try {
    yield put(startLoader());
    const mp3List = yield call(syncMusicWithBoxCom);
    const responseData = mp3List.data.map((mp3Item) => ({
      ...mp3Item,
      name: mp3Item.name !== startingMusicName ? mp3Item.description : mp3Item.name,
      cover: mp3Item.thumbnailPath ? `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/thumbnail/${mp3Item._id}` : rftsLogo,
      musicSrc: () => Promise.resolve(`${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/download/${mp3Item._id}`),
    }));
    if (responseData) {
      yield put(actions.setPlayList(responseData));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching music list');
  }
}

export const musicPlayerSagas = [
  takeLatest(types.GET_PLAY_LIST, getPlayList),
  takeLatest(types.GET_USER_INTEREST_PLAYLIST, getInterestPlaylist),
  takeLatest(types.GET_ALL_MUSIC, getAllMusicList),
  takeLatest(types.GET_NEXT_SESSION, getNextSession),
  takeLatest(types.SYNC_MUSIC_WITH_BOX_COM, syncMusic),
  takeLatest(types.SET_PER_SESSION_MP3, setPerSessionMp3s),
  takeLatest(types.SET_MP3_AS_PLAYED, setMp3AsPlayed),
];
