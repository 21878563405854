import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DialogComponent from '../../../components/DialogComponent';
import { connect } from 'react-redux';
import { getUserData } from '../Redux/actions';
import './style.scss';
import { IconButton } from '@material-ui/core';
import InterestsOrderingForm from '../../../components/InterestsOrderingForm';

const ViewSessionInfo = (props) => {
  const { viewUserData, userId, userData } = props;
  const { selectedInterests, firstName, lastName, email } = userData || {};
  const header = 'Interest List';
  const [open, setOpen] = useState(false);
  const toggle = () => {
    !open && viewUserData(userId);
    setOpen(!open);
  };
  const body = userData && (
    <div className="view-interest-list-info">
      <div className="user-name">
        Name: {firstName} {lastName}
      </div>
      <div className="user-email">Email: {email}</div>
      {selectedInterests.length > 0 ? <InterestsOrderingForm selected={selectedInterests} handleOrder={() => {}} disabled={true} showAudios={true} /> : 'Interests are not set yet.'}
    </div>
  );

  return (
    <>
      <IconButton title="Interest List" onClick={toggle}>
        <ListAltIcon color="primary" />
      </IconButton>
      <DialogComponent body={body} header={header} open={open} onClose={toggle} fullWidth={false} maxWidth="md" />
    </>
  );
};
ViewSessionInfo.propTypes = {
  viewUserData: PropTypes.func,
  userId: PropTypes.string,
  selectedInterests: PropTypes.array,
  userData: PropTypes.object,
};
const mapStateToProps = (state) => ({ userData: state.userManagement.userData });
const mapDispatchToProps = (dispatch) => ({
  viewUserData: (userId) => dispatch(getUserData(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSessionInfo);
