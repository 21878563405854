import types from './types';

export const setPlayList = (value) => ({
  type: types.SET_PLAY_LIST,
  value,
});

export const getAllMusic = () => ({
  type: types.GET_ALL_MUSIC,
});

export const getPlayList = () => ({
  type: types.GET_PLAY_LIST,
});

export const getUserInterestPlaylist = () => ({
  type: types.GET_USER_INTEREST_PLAYLIST,
});

export const getNextSession = () => ({
  type: types.GET_NEXT_SESSION,
});

export const syncMusicWithBoxCom = () => ({
  type: types.SYNC_MUSIC_WITH_BOX_COM,
});

export const setShowPlayer = (value) => ({
  type: types.SET_SHOW_PLAYER,
  value,
});

export const setPlaylistIndex = (value) => ({
  type: types.SET_PLAYLIST_INDEX,
  value,
});

export const setMusicPlayerOptions = (value) => ({
  type: types.SET_MUSIC_PLAYER_OPTIONS,
  value,
});

export const setPerSessionMp3 = (value) => ({
  type: types.SET_PER_SESSION_MP3,
  value,
});

export const setMp3AsPlayed = (value) => ({
  type: types.SET_MP3_AS_PLAYED,
  value,
});

export const setFreePlaylist = (value) => ({
  type: types.SET_FREE_MP3_PLAYLIST,
  value,
});

export const getFreePlaylist = (value) => ({
  type: types.GET_FREE_MP3_PLAYLIST,
  value,
});

export default {
  setPlayList,
  getPlayList,
  getAllMusic,
  setShowPlayer,
  getNextSession,
  syncMusicWithBoxCom,
  setPlaylistIndex,
  setMusicPlayerOptions,
  setPerSessionMp3,
  setMp3AsPlayed,
  setFreePlaylist,
  getFreePlaylist,
};
