import { Container, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCustomerPaymentDetails, getUserDetails, updateUserDetails } from '../SignIn/redux/actions';
import PaymentDetails from './PaymentDetails';
import PersonalDetails from './PersonalDetails';

class Profile extends Component {
  componentDidMount() {
    const { getUserData, getPaymentDetails, userDetails } = this.props;
    getUserData(userDetails._id);
    getPaymentDetails(userDetails.email);
  }
  render() {
    const { authenticated } = this.props;
    if (!authenticated) {
      return (
        <Container className="profile-container" disableGutters>
          <Typography variant="body1" component="p">
            You are not authenticated to view this page.
          </Typography>
        </Container>
      );
    }
    return (
      <Container className="profile-container" disableGutters maxWidth="md">
        <PaymentDetails />
        <PersonalDetails viewMode={false} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ authenticated: state.authentication.authenticated, userDetails: state.authentication.userDetails });
const mapDispatchToProps = (dispatch) => ({
  updateUserData: (data) => dispatch(updateUserDetails(data)),
  getUserData: (data) => dispatch(getUserDetails(data)),
  getPaymentDetails: (email) => dispatch(getCustomerPaymentDetails(email)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
