import React from 'react';
import moment from 'moment';
import './style.scss';
import { Typography, Grid } from '@material-ui/core';
// import { cancelStripeSubscription } from '../../SignIn/redux/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const PaymentDetails = (props) => {
  const { paymentData, userDetails } = props;
  const { subscription } = userDetails;

  if (subscription.toLowerCase() === 'free') {
    return (
      <div className="payment-details">
        <Grid item xs={12} container justify={'center'}>
          <Typography>You are using a free subscription.</Typography>
        </Grid>
      </div>
    );
  }
  if (!paymentData) {
    return (
      <div className="payment-details">
        <Typography>You do not have any active subscriptions. If you have cancelled the subscription your membership will end at the end of the payment cycle. Please contact Administrator to know about your payment cycles.</Typography>
      </div>
    );
  }
  if (paymentData.status === 'canceled') {
    const endDate = moment(paymentData.current_period_end * 1000).format('LL');
    return (
      <div className="payment-details">
        <Typography>
          You have cancelled your {subscription} subscription. Your membership will end on {endDate}
        </Typography>
      </div>
    );
  }
  return (
    <Grid className="payment-details" container>
      <Grid item xs={12} container justify={'center'}>
        <Typography variant={'body1'} component={'p'}>
          Your {subscription} subscription will be renewed every month automatically unless its cancelled.
        </Typography>
      </Grid>
      {/* <Grid item container xs={12} justify={'center'}>
        <Button color="primary" variant={'contained'} onClick={() => cancelSubscription(userDetails.email)}>
          Cancel Subscription
        </Button>
      </Grid> */}
    </Grid>
  );
};

const mapStateToProps = (state) => ({ authenticated: state.authentication.authenticated, userDetails: state.authentication.userDetails, paymentData: state.authentication.customerPaymentDetails });
// const mapDispatchToProps = (dispatch) => ({
//   cancelSubscription: (email) => dispatch(cancelStripeSubscription(email)),
// });

export default withRouter(connect(mapStateToProps, null)(PaymentDetails));
