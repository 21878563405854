import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import { MenuItem, Divider, Grid, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import useResponsiveFontSize from './useResponsiveFontSize';
import './style.scss';
import SelectField from '../Select';
import Autocomplete from '../Select/Autocomplete';
import { axiosCountry } from '../../Services/axios.service';
import { YupObjectShape } from '../../Services/yup';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  return useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: '"Nunito Sans", sans-serif',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  );
};

const SplitCardForm = (props) => {
  const { handleNext, detailProps } = props;
  const { userDetails, setSignUpDetails } = detailProps;
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [countryName, setCountryName] = React.useState('');
  const [countryList, setCountryList] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [submit, setSubmit] = React.useState(false);

  const initialValues = {
    country: '',
    state: '',
    city: '',
    address1: '',
    address2: '',
    zip: '',
    promoCode: '',
  };

  const validation = YupObjectShape({
    country: Yup.string().required(),
    state: Yup.string().required(),
    city: Yup.string().required(),
    address1: Yup.string().required(),
    address2: Yup.string(),
    zip: Yup.string().required(),
  });

  const getWorldData = async (type = 'country', payload = '') => {
    switch (type) {
      case 'state': {
        const getState = await axiosCountry(`/states/${payload}`);
        setStateList(getState);
        break;
      }
      case 'city': {
        const getCity = await axiosCountry(`/cities/${payload}`);
        setCityList(getCity);
        break;
      }
      default: {
        const getCountry = await axiosCountry('/countries');
        setCountryList(getCountry);
        break;
      }
    }
  };

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    setSignUpDetails({ ...userDetails, ...values});
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setSubmit(false);
      return;
    } else setSubmit(true);
  };

  const handleSubmitPayment = async () => {
    const {
      userDetails: { city, country, address1, address2, zip, state, firstName, lastName, promoCode },
    } = detailProps;

    const billing_details = {
      name: `${firstName} ${lastName}`,
      address: {
        line1: address1,
        line2: address2,
        postal_code: zip,
        city: city,
        state: state,
        country: country,
      },
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details,
    });
    console.log('[PaymentMethod]', paymentMethod);

    if (error) {
      console.log('error', error);
      return;
    }

    handleNext(paymentMethod.id, promoCode);
  };

  React.useEffect(() => {
    if (submit) {
      handleSubmitPayment();
    }
  }, [submit]);

  React.useEffect(() => {
    getWorldData();
  }, []);

  return (
    <Formik initialValues={initialValues} validationSchema={validation} onSubmit={handleSubmit}>
      {(fProps) => (
        <Form onSubmit={fProps.handleSubmit}>
          <Divider style={{ margin: '16px 0' }} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SelectField
                required
                id="country"
                name="country"
                label="Country"
                value={fProps.values.country}
                error={!!(fProps.touched.country && fProps.errors.country)}
                helperText={fProps.touched.country && fProps.errors.country}
                onChange={(event) => {
                  countryList.map((pick) => pick.country_short_name === event.target.value ? setCountryName(pick.country_name) : null);
                  fProps.setFieldValue('country', event.target.value);
                }}
                onBlur={() => {
                  // getWorldData('state', countryName);
                  fProps.setFieldValue('state', '');
                  // fProps.setFieldValue('city', '');
                  // fProps.setFieldValue('zipcode', '');
                }}
              >
                <MenuItem value="">--Select--</MenuItem>
                {
                  countryList.map(((cyMap) => <MenuItem value={cyMap.country_short_name}>{cyMap.country_name}</MenuItem>))
                }
              </SelectField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                size="small"
                id="state"
                name="state"
                label="State"
                fullWidth
                value={fProps.values.state}
                autoComplete="state"
                error={!!(fProps.touched.state && fProps.errors.state)}
                helperText={fProps.touched.state && fProps.errors.state}
                onChange={fProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                size="small"
                id="city"
                name="city"
                label="city"
                fullWidth
                value={fProps.values.city}
                autoComplete="city"
                error={!!(fProps.touched.city && fProps.errors.city)}
                helperText={fProps.touched.city && fProps.errors.city}
                onChange={fProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                size="small"
                id="zip"
                name="zip"
                label="Zip Code"
                value={fProps.values.zip}
                fullWidth
                autoComplete="zip"
                error={!!(fProps.touched.zip && fProps.errors.zip)}
                helperText={fProps.touched.zip && fProps.errors.zip}
                onChange={fProps.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                size="small"
                id="address1"
                name="address1"
                label="Address 1"
                value={fProps.values.address1}
                fullWidth
                autoComplete="address1"
                error={!!(fProps.touched.address1 && fProps.errors.address1)}
                helperText={fProps.touched.address1 && fProps.errors.address1}
                onChange={fProps.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                id="address2"
                name="address2"
                label="Address 2"
                value={fProps.values.address2}
                fullWidth
                autoComplete="address2"
                error={!!(fProps.touched.address2 && fProps.errors.address2)}
                helperText={fProps.touched.address2 && fProps.errors.address2}
                onChange={fProps.handleChange}
              />
            </Grid>
          </Grid>
  
          <Divider style={{ margin: '16px 0' }} />

          <label className="stripe-label">
            Card number
            <CardNumberElement options={options} />
          </label>
          <label className="stripe-label">
            Expiration date
            <CardExpiryElement options={options} />
          </label>
          <label className="stripe-label">
            CVC
            <CardCvcElement options={options} />
          </label>
          <label className="stripe-label">
            Promo Code
            <input type="text" name="promoCode" value={fProps.values.promoCode} onChange={fProps.handleChange} className="InputElement StripeElement" placeholder="Promo Code" />
          </label>
          <button type="submit" disabled={!stripe} className="stripe-button">
            Pay and Signup
          </button>
        </Form>
      )}
    </Formik>
    );

  // return (
  //   <form onSubmit={handleSubmit} className="stripe-form">
  //     <label className="stripe-label">
  //       Card number
  //       <CardNumberElement options={options} />
  //     </label>
  //     <label className="stripe-label">
  //       Expiration date
  //       <CardExpiryElement options={options} />
  //     </label>
  //     <label className="stripe-label">
  //       CVC
  //       <CardCvcElement options={options} />
  //     </label>
  //     <label className="stripe-label">
  //       Promo Code
  //       <input type="text" name="promoCode" className="InputElement StripeElement" placeholder="Promo Code" />
  //     </label>
  //     <button type="submit" disabled={!stripe} className="stripe-button">
  //       Pay and Signup
  //     </button>
  //   </form>
  // );
};

SplitCardForm.propTypes = {
  handleNext: PropTypes.func,
  detailProps: PropTypes.object,
};

export default SplitCardForm;
