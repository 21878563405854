import React from 'react';
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';
import PropTypes from 'prop-types';

const Checkbox = ({ label, name, id, checked, onChange }) => (
  <FormControl>
    <FormControlLabel
      label={label}
      control={<MuiCheckbox
        name={name}
        id={id}
        checked={checked}
        inputProps={{ 'aria-label': label }}
      />}
      onChange={onChange}
    />
  </FormControl>
);

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  label: '',
  name: '',
  id: '',
  checked: false,
  onChange: () => {},
};

export default Checkbox;