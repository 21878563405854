import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, Grid, MenuItem, TextField, makeStyles, Link } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { YupObjectShape } from '../Services/yup';
import timeZone from '../utils/timezone';
import { HIDE_MATURE_CONTENT, IS_FIRST_RESPONDER, IS_USING_HYPNOSIS, IS_USING_POLYAMORY } from '../utils/appConstants';
import SelectField from '../components/Select';
import Checkbox from '../components/Checkbox';


const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const PersonalDetailsForm = (props) => {
  const { detailProps, handleBack, handleNext } = props;
  const { userDetails, setSignUpDetails } = detailProps;

  const classes = useStyles();
  const subscriptionTitle = useSelector((state) => state.subscription.selectedSubscription.title);
  const currentYear = new Date().getFullYear();
  const yearList = Array.from(new Array(5), (val, index) => index + currentYear);

  const initialValues = {
    isSubFree: subscriptionTitle.toLowerCase() === 'free',
    isSubPlatinum: subscriptionTitle.toLowerCase() === 'platinum',
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    email: userDetails.email,
    password: userDetails.password,
    sex: userDetails.sex,
    age: userDetails.age,
    timeZone: userDetails.timeZone,
    cellPhone: userDetails.cellPhone,
    workHours: userDetails.workHours,
    occupation: userDetails.occupation,
    incomeGoal: userDetails.incomeGoal,
    inYear: userDetails.inYear,
    wouldLikeToEarn: userDetails.wouldLikeToEarn,
    isUsingPolyamory: userDetails.isUsingPolyamory,
    isFirstResponder: userDetails.isFirstResponder,
    isUsingHypnosis: userDetails.isUsingHypnosis,
    showMatureContent: !userDetails.hideMatureContent,
    reference: userDetails.reference,
    lifeDiscoverySession: userDetails.lifeDiscoverySession,
    lifeDiscoverySessionDate: userDetails.lifeDiscoverySessionDate,
    lifeDiscoverySessionAssociate: userDetails.lifeDiscoverySessionAssociate,
  }


  const validation = YupObjectShape({
    isSubFree: Yup.boolean(),
    isSubPlatinum: Yup.boolean(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    sex: Yup.string().when('isSubFree', { is: false, then: Yup.string().required() }),
    age: Yup.number().min(1).max(200).when('isSubFree', { is: false, then: Yup.number().min(1).max(200).required() }),
    cellPhone: Yup.string().when('isSubFree', { is: false, then: Yup.string().required() }),
    workHours: Yup.string().when('isSubFree', { is: false, then: Yup.string().required() }),
    timeZone: Yup.string().when('isSubFree', { is: false, then: Yup.string().required() }),
    occupation: Yup.string(),
    incomeGoal: Yup.string(),
    inYear: Yup.string(),
    wouldLikeToEarn: Yup.string(),
    isUsingPolyamory: Yup.bool(),
    isFirstResponder: Yup.bool(),
    isUsingHypnosis: Yup.bool(),
    showMatureContent: Yup.bool(),
    reference: Yup.string(),
    lifeDiscoverySession: Yup.string().when('isSubPlatinum', { is: true, then: Yup.string().required() }),
    lifeDiscoverySessionDate: Yup.string().when('lifeDiscoverySession', { is: 'Yes', then: Yup.string().required() }),
    lifeDiscoverySessionAssociate: Yup.string().when('lifeDiscoverySession', { is: 'Yes', then: Yup.string().required() })
  });

  const onSubmit = (values, actions) => {
    setSignUpDetails({ ...userDetails, ...values, hideMatureContent: !values.showMatureContent });
    handleNext();
    actions.setSubmitting(false);
  };

  return (
  <Formik initialValues={initialValues} validationSchema={validation} onSubmit={onSubmit}>
    {(fProps) => (
      <Form onSubmit={fProps.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              size="small"
              id="firstName"
              name="firstName"
              label="First name"
              value={fProps.values.firstName}
              fullWidth
              autoComplete="first-name"
              error={!!(fProps.touched.firstName && fProps.errors.firstName)}
              helperText={fProps.touched.firstName && fProps.errors.firstName}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              size="small"
              id="lastName"
              name="lastName"
              label="Last name"
              value={fProps.values.lastName}
              fullWidth
              autoComplete="last-name"
              error={!!(fProps.touched.lastName && fProps.errors.lastName)}
              helperText={fProps.touched.lastName && fProps.errors.lastName}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              type="email"
              variant="outlined"
              size="small"
              id="email"
              name="email"
              label="Email"
              value={fProps.values.email}
              fullWidth
              autoComplete="email-new"
              error={!!(fProps.touched.email && fProps.errors.email)}
              helperText={fProps.touched.email && fProps.errors.email}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              type="password"
              size="small"
              id="password"
              name="password"
              label="password"
              value={fProps.values.password}
              fullWidth
              autoComplete="current-password-new"
              error={!!(fProps.touched.password && fProps.errors.password)}
              helperText={fProps.touched.password && fProps.errors.password}
              onChange={fProps.handleChange}
            />
          </Grid>
        </Grid>

        <Divider style={{ margin: '16px 0' }} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SelectField
              id="sex"
              name="sex"
              label="Gender"
              value={fProps.values.sex}
              error={!!(fProps.touched.sex && fProps.errors.sex)}
              helperText={fProps.touched.sex && fProps.errors.sex}
              onChange={fProps.handleChange}
            >
              <MenuItem value="">--Select--</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </SelectField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={!fProps.values.isSubFree}
              type='number'
              variant="outlined"
              size="small"
              id="age"
              name="age"
              label="Age"
              value={fProps.values.age}
              fullWidth
              autoComplete="age"
              error={!!(fProps.touched.age && fProps.errors.age)}
              helperText={fProps.touched.age && fProps.errors.age}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={!fProps.values.isSubFree}
              variant="outlined"
              size="small"
              id="cellPhone"
              name="cellPhone"
              label="Best contact number"
              value={fProps.values.cellPhone}
              fullWidth
              autoComplete="cellPhone"
              error={!!(fProps.touched.cellPhone && fProps.errors.cellPhone)}
              helperText={fProps.touched.cellPhone && fProps.errors.cellPhone}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={!fProps.values.isSubFree}
              variant="outlined"
              size="small"
              id="workHours"
              name="workHours"
              label="Best time(s) Reached"
              value={fProps.values.workHours}
              fullWidth
              autoComplete="workHours"
              error={!!(fProps.touched.workHours && fProps.errors.workHours)}
              helperText={fProps.touched.workHours && fProps.errors.workHours}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              required={!fProps.values.isSubFree}
              id="timeZone"
              name="timeZone"
              label="Time Zone"
              value={fProps.values.timeZone}
              error={!!(fProps.touched.timeZone && fProps.errors.timeZone)}
              helperText={fProps.touched.timeZone && fProps.errors.timeZone}
              onChange={fProps.handleChange}
            >
              {timeZone.map((tzValue) => (<MenuItem value={tzValue.name} key={tzValue.label}>{tzValue.name}</MenuItem>))}
            </SelectField>
          </Grid>
        </Grid>

        <Divider style={{ margin: '16px 0' }} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              id="occupation"
              name="occupation"
              label="Occupation"
              value={fProps.values.occupation}
              fullWidth
              autoComplete="occupation"
              error={!!(fProps.touched.occupation && fProps.errors.occupation)}
              helperText={fProps.touched.occupation && fProps.errors.occupation}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              id="incomeGoal"
              name="incomeGoal"
              label="Your income goal is"
              value={fProps.values.incomeGoal}
              fullWidth
              autoComplete="incomeGoal"
              error={!!(fProps.touched.incomeGoal && fProps.errors.incomeGoal)}
              helperText={fProps.touched.incomeGoal && fProps.errors.incomeGoal}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              id="inYear"
              name="inYear"
              label="Year you want to reach your goal"
              value={fProps.values.inYear}
              error={!!(fProps.touched.inYear && fProps.errors.inYear)}
              helperText={fProps.touched.inYear && fProps.errors.inYear}
              onChange={fProps.handleChange}
            >
              {yearList.map((year, index) => {
                return (
                  <MenuItem key={`year${index}`} value={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              id="wouldLikeToEarn"
              name="wouldLikeToEarn"
              label="This is _____ your current income"
              value={fProps.values.wouldLikeToEarn}
              error={!!(fProps.touched.wouldLikeToEarn && fProps.errors.wouldLikeToEarn)}
              helperText={fProps.touched.wouldLikeToEarn && fProps.errors.wouldLikeToEarn}
              onChange={fProps.handleChange}
            >
              <MenuItem value="">--Select--</MenuItem>
              <MenuItem value="Modest Increase">Modest Increase</MenuItem>
              <MenuItem value="Doubling">Doubling</MenuItem>
              <MenuItem value="Tripling">Tripling</MenuItem>
            </SelectField>
          </Grid>
        </Grid>

        <Divider style={{ margin: '16px 0' }} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Checkbox
              label={IS_FIRST_RESPONDER}
              id='isFirstResponder'
              name='isFirstResponder'
              checked={fProps.values.isFirstResponder}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              label={IS_USING_HYPNOSIS}
              id='isUsingHypnosis'
              name='isUsingHypnosis'
              checked={fProps.values.isUsingHypnosis}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12} style={{ display: fProps.values.age < 18 ? 'none': null }}>
            <Checkbox
              label={HIDE_MATURE_CONTENT}
              id='showMatureContent'
              name='showMatureContent'
              checked={fProps.values.showMatureContent}
              onChange={fProps.handleChange}
            />
          </Grid>
          <Grid item xs={12} style={{ display: !fProps.values.showMatureContent ? 'none': null }}>
            <Checkbox
              label={IS_USING_POLYAMORY}
              id='isUsingPolyamory'
              name='isUsingPolyamory'
              checked={fProps.values.isUsingPolyamory}
              onChange={fProps.handleChange}
            />
          </Grid>
        </Grid>

        {/* PLATINUM CHOICES */}

        {fProps.values.isSubPlatinum && (
          <React.Fragment>
            <Divider style={{ margin: '16px 0' }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectField
                  id="lifeDiscoverySession"
                  name="lifeDiscoverySession"
                  label="Have you had a Life Guidance Discovery Session?"
                  value={fProps.values.lifeDiscoverySession}
                  error={!!(fProps.touched.lifeDiscoverySession && fProps.errors.lifeDiscoverySession)}
                  helperText={fProps.touched.lifeDiscoverySession && fProps.errors.lifeDiscoverySession}
                  onChange={fProps.handleChange}
                >
                  <MenuItem value="">--Select--</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </SelectField>
              </Grid>
              {fProps.values.lifeDiscoverySession === 'Yes' && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <TextField
                      required={fProps.values.lifeDiscoverySession === 'Yes'}
                      variant="outlined"
                      type="date"
                      size="small"
                      id="lifeDiscoverySessionDate"
                      name="lifeDiscoverySessionDate"
                      label="Date of your Life Guidance Discovery Session:"
                      value={fProps.values.lifeDiscoverySessionDate}
                      fullWidth
                      autoComplete="lifeDiscoverySessionDate"
                      InputLabelProps={{ shrink: true }}
                      error={!!(fProps.touched.lifeDiscoverySessionDate && fProps.errors.lifeDiscoverySessionDate)}
                      helperText={fProps.touched.lifeDiscoverySessionDate && fProps.errors.lifeDiscoverySessionDate}
                      onChange={fProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required={fProps.values.lifeDiscoverySession === 'Yes'}
                      variant="outlined"
                      size="small"
                      id="lifeDiscoverySessionAssociate"
                      name="lifeDiscoverySessionAssociate"
                      label="Success Center Associate that worked with you:"
                      value={fProps.values.lifeDiscoverySessionAssociate}
                      fullWidth
                      autoComplete="lifeDiscoverySessionAssociate"
                      error={!!(fProps.touched.lifeDiscoverySessionAssociate && fProps.errors.lifeDiscoverySessionAssociate)}
                      helperText={fProps.touched.lifeDiscoverySessionAssociate && fProps.errors.lifeDiscoverySessionAssociate}
                      onChange={fProps.handleChange}
                    />
                  </Grid>
                </React.Fragment>
              )}
              {fProps.values.lifeDiscoverySession === 'No' && (
                <Grid item xs={12} style={{ padding: '16px 0' }}>
                  The Platinum Package is only available to those who have a Life Guidance Discovery Session. Please{' '}
                  <Link href="https://acesuccess.com/product/life-guidance-discovery-session/" target="_blank">
                    click here
                  </Link>{' '}
                  to purchase one or call the office at 800 GOAL NOW to set an appointment.
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        )}
        
        {/* PLATINUM CHOICES END */}

        <Divider style={{ margin: '16px 0' }} />

        <Grid container item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              id="reference"
              name="reference"
              label="How did you find us?"
              value={fProps.values.reference}
              fullWidth
              error={!!(fProps.touched.reference && fProps.errors.reference)}
              helperText={fProps.touched.reference && fProps.errors.reference}
              onChange={fProps.handleChange}
            />
          </Grid>

        <div className={classes.buttons}>
          <Button onClick={handleBack} className={classes.button}>
            Back
          </Button>
          <Button type="submit" variant="contained" color="primary" className={classes.button}>
            Next
          </Button>
        </div>
      </Form>
    )}
  </Formik>
  );
};

PersonalDetailsForm.propTypes = {
  detailProps: {
    userDetails: PropTypes.instanceOf(Object).isRequired,
    setSignUpDetails: PropTypes.func.isRequired,
  },
  steps: PropTypes.arrayOf(String).isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default PersonalDetailsForm;
