const SET_USER_LIST = 'SET_USER_LIST';
const GET_ROLE_LIST = 'GET_ROLE_LIST';
const SET_ROLE_LIST = 'SET_ROLE_LIST';
const GET_USER_LIST = 'GET_USER_LIST';
const GET_USER_DATA = 'GET_USER_DATA';
const SET_USER_DATA = 'SET_USER_DATA';
const DELETE_USER = 'DELETE_USER';
const CREATE_USER = 'CREATE_USER';
const UPDATE_USER = 'UPDATE_USER';
const GET_MODERATOR_LIST = 'GET_MODERATOR_LIST';
const SET_MODERATOR_LIST = 'SET_MODERATOR_LIST';
const GET_CURRENT_USER_SESSION = 'GET_CURRENT_USER_SESSION';
const SET_CURRENT_USER_SESSION = 'SET_CURRENT_USER_SESSION';
const GET_SESSION_INFO_BY_SESSION_INDEX = 'GET_SESSION_INFO_BY_SESSION_INDEX';
const RESET_USER_SESSIONS = 'RESET_USER_SESSIONS';
const MOVE_USER_SESSION = 'MOVE_USER_SESSION';

export default {
  SET_USER_LIST,
  GET_USER_LIST,
  GET_USER_DATA,
  SET_USER_DATA,
  DELETE_USER,
  CREATE_USER,
  UPDATE_USER,
  GET_ROLE_LIST,
  SET_ROLE_LIST,
  GET_MODERATOR_LIST,
  SET_MODERATOR_LIST,
  GET_CURRENT_USER_SESSION,
  SET_CURRENT_USER_SESSION,
  GET_SESSION_INFO_BY_SESSION_INDEX,
  RESET_USER_SESSIONS,
  MOVE_USER_SESSION,
};
