import { promiseReturn } from './serviceUtils';
import appConfig from '../config/appConfig';
import RftsV2Service from './rftsv2.service';
const BASE_URI = `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/user`;
const ROLE_BASE_URI = `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/role`;

export const getAllUsers = async () => {
  const url = `${BASE_URI}/all`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const downloadUsersCSV = () => {
  const url = `${BASE_URI}/download-csv`;
  window.open(url);
};

export const getUsersByModeratorId = async (data) => {
  const url = `${BASE_URI}/bymoderator`;
  const urlData = {
    url,
    method: 'post',
    params: data,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getAllModerators = async () => {
  const url = `${BASE_URI}/moderator/all`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getAllRoles = async () => {
  const url = `${ROLE_BASE_URI}/all`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getUserInfo = async () => {
  const url = `${BASE_URI}`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getUserById = async (userId) => {
  const url = `${BASE_URI}/id/${userId}`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const createUser = async (data) => {
  const url = `${BASE_URI}/create`;
  const urlData = {
    url,
    method: 'post',
    params: data,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const updateUserDataById = async (data) => {
  const url = `${BASE_URI}/update`;
  const urlData = {
    url,
    method: 'post',
    params: data,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const deleteUserById = async (userId) => {
  const url = `${BASE_URI}/${userId}`;
  const urlData = {
    url,
    method: 'delete',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const sendResetPasswordLink = async (email) => {
  const url = `${BASE_URI}/forgot-password`;
  const urlData = {
    url,
    method: 'post',
    params: { email },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const verifyResetPasswordLink = async (token) => {
  const url = `${BASE_URI}/reset-password`;
  const urlData = {
    url,
    method: 'post',
    params: { token },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const updateUserPassword = async (email, password) => {
  const url = `${BASE_URI}/update-password`;
  const urlData = {
    url,
    method: 'post',
    params: { email, password },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const updatePerSessionMp3 = async (userId, perSessionMp3) => {
  const url = `${BASE_URI}/per-session-mp3`;
  const urlData = {
    url,
    method: 'post',
    params: { userId, perSessionMp3 },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getUserCurrentSession = async (userId) => {
  const check = await RftsV2Service.getUserCurrentSession(userId);

  const currentSession = check.data.audios.map(({ played, ...audio }) => ({ audio: { _id: audio.audio, ...audio.audioInfo }, played: !!played }));

  const response = {
    data: {
      currentSession,
      sessionIndex: check.data.sessionIndex,
      interestPlayingOrder: [],
    },
  };

  return response;
};

export const updateCurrentSessionByMp3ID = async (userId, mp3Id) => {
  const url = `${BASE_URI}/update-session-mp3-id`;
  const urlData = {
    url,
    method: 'post',
    params: { userId, mp3Id },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const upgradeSubscription = async (userId, userData) => {
  const url = `${BASE_URI}/update-subscription/`;
  const urlData = {
    url,
    method: 'post',
    params: { userId, userData },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getSessionInfoBySessionIndex = async (userId, session, interestPlayingOrder, isPlatinum = false) => {
  const check = await RftsV2Service.getUserSession(userId, session);

  const currentSession = check.data.map(({ played, ...audio }) => ({ audio, played: !!played }));

  const response = {
    data: {
      currentSession,
      sessionIndex: session,
      interestPlayingOrder: [],
    },
  };

  return response;
};

export const setUserSessionDetailsByUserId = async (userId, sessionIndex, currentSession, interestPlayingOrder) => {
  const url = `${BASE_URI}/set-user-session`;
  const urlData = {
    url,
    method: 'post',
    params: { currentSession, interestPlayingOrder, userId, sessionIndex },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const resetUserSessionById = async (userId, all = false) => {
  const url = `${BASE_URI}/resetUserSessions`;
  if (!userId) {
    return;
  }
  const urlData = {
    url,
    method: 'post',
    params: { userId, all },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};
