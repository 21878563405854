import types from '../AdminPanel/UserManagement/Redux/types';
const INITIAL_STATE = {
  userList: [],
  userData: null,
  roleList: [],
  moderatorList: [],
  sessionIndex: 0,
  currentUserSession: null,
  interestPlayingOrder: [],
};

const reducerHandler = (action, state = INITIAL_STATE) => {
  const reducerObject = {
    [types.SET_USER_LIST]: () => ({
      ...state,
      userList: action.value,
    }),
    [types.SET_USER_DATA]: () => ({
      ...state,
      userData: action.value,
    }),
    [types.SET_ROLE_LIST]: () => ({
      ...state,
      roleList: action.value,
    }),
    [types.SET_MODERATOR_LIST]: () => ({
      ...state,
      moderatorList: action.value,
    }),
    [types.SET_CURRENT_USER_SESSION]: () => ({
      ...state,
      currentUserSession: action.value.currentSession,
      sessionIndex: action.value.sessionIndex,
      interestPlayingOrder: action.value.interestPlayingOrder,
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const userManagement = (state, action) => reducerHandler(action, state);

export default userManagement;
