import uuidv4 from 'uuid/v4';

/**
 * generate uuid without hyphens
 */

export const uuidWithoutHyphens = () => {
  const uuid = uuidv4();
  return uuid.replace(/-/g, '');
};

export const isModerator = (roles = []) => roles.includes('moderator');
export const isAdmin = (roles = []) => roles.includes('admin');
export const isAdminORModerator = (roles = []) => isAdmin(roles) || isModerator(roles);

export const reMask = (re) => new RegExp(re);
