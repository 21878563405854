import React from 'react';

import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { IconButton, Grid } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import PropTypes from 'prop-types';

import MaterialDataTable from '../../../components/MaterialDataTable';
import { downloadSalesLeadsCSV } from '../../../Services/salesLeadUserServiceClient';


import './style.scss';
const SalesLeadList = (props) => {
    const { salesLeadList, deleteSalesLead } = props;
    const onDownloadCSV =  () =>  downloadSalesLeadsCSV()
    const data =
      salesLeadList &&
      salesLeadList.map((mp3Item) => {
        const { firstName, lastName, cellPhone, email, _id, createdAt } = mp3Item;
        const actions = (
          <Grid container justify="flex-start">
            <IconButton onClick={() => deleteSalesLead(_id)}>
              <DeleteRoundedIcon color="error" />
            </IconButton>
          </Grid>
        );
        const createdDate = moment(createdAt).format('LL');
        return {
          name: firstName + ' ' + lastName,
          email: email,
          phone: cellPhone,
          createdAt: createdDate,
          actions,
        };
      });
    const headers = [
      { title: 'Name', field: 'name' },
      { title: 'Email', field: 'email' },
      { title: 'Phone', field: 'phone' },
      { title: 'Created At', field: 'createdAt', align: 'center', customSort: (a, b) => new Date(b.createdAt) - new Date(a.createdAt) },
      { title: 'Actions', field: 'actions', width: '15%', sorting: false },
    ];
    return <MaterialDataTable title={
      <>
        {data.length || 0} sales leads
        <IconButton variant="outlined" color="primary" onClick={onDownloadCSV} title="Download to CSV">
          <CloudDownloadIcon />
        </IconButton>
      </>
    } headers={headers} data={data} className="sales-lead-list" />;
}
SalesLeadList.propTypes = {
  salesLeadList: PropTypes.array,
  deleteSalesLead: PropTypes.func,
};

export default SalesLeadList;
