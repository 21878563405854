import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, List, ListItem, Checkbox, ListItemText, ListItemSecondaryAction, makeStyles } from '@material-ui/core';
import ViewInterestMp3List from '../ViewInterestMp3List';

const useStyles = makeStyles(() => ({
  icon: {
    width: 20,
    height: 20,
  },
  interestItemBorder: {
    borderRight: '1px solid',
  },
  checkedIcon: {
    fontWeight: '600',
    color: '#fff',
    borderRadius: 5,
    background: '#f50057',
    '&:before': {
      display: 'flex',
      width: 20,
      height: 20,
    },
  },
}));

function InterestsSelectionForm(props) {
  const { interests, selected, handleListClick, disabled = false } = props;
  const classes = useStyles();
  return (
    <div>
      <hr />
      <span style={{ color: 'black' }}>Interests</span>
      <p style={{ color: 'black' }}>Please select 10 interests in order of importance. The first one will be most important and the last one will be least important.</p>
      <List dense={false}>
        <Grid container spacing={16}>
          {interests.map((item, index) => {
            const { _id, name, audioList } = item;
            const selectedIndex = selected.findIndex((selectedItem) => selectedItem._id === _id);
            const displayBorder = (index + 1) % 3 !== 0;
            const nameComponent = (
              <div className="interest-name">
                {name}
                <ViewInterestMp3List audioList={audioList} name={name} />
              </div>
            );
            return (
              <Grid item xs={12} md={4} key={_id} className={classnames({ [classes.interestItemBorder]: displayBorder })}>
                <ListItem key={_id} onClick={handleListClick(item)}>
                  <ListItemText primary={nameComponent} />
                  <ListItemSecondaryAction>
                    <Checkbox checked={selectedIndex > -1} checkedIcon={<span className={classnames(classes.icon, classes.checkedIcon)}>{selectedIndex + 1}</span>} onClick={handleListClick(item)} disabled={disabled} />
                  </ListItemSecondaryAction>
                </ListItem>
              </Grid>
            );
          })}
        </Grid>
      </List>
    </div>
  );
}

InterestsSelectionForm.propTypes = {
  interests: PropTypes.array,
  selected: PropTypes.bool,
  handleListClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default InterestsSelectionForm;
