import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const VideoComponent = ({ videoURL }) => (
  <div className="intro-video">
    <iframe src={videoURL} style={{ borderRadius: '10px' }} title="Intro Video" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullscreen />
  </div>
);

VideoComponent.propTypes = {
  videoURL: PropTypes.string.isRequired,
};

export const IntroVideo = () => (
  <VideoComponent videoURL='https://player.vimeo.com/video/602719564?autoplay=1' />
);

export const RFTSV2Video = () => (
  <VideoComponent videoURL='https://player.vimeo.com/video/476686676' />
);
