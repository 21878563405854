import React, { useEffect } from 'react';
import { Container, CssBaseline, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import classnames from 'classnames';

import Header from '../components/Header';
import Footer from '../components/Footer';
// import { getLeftNavItems, HOME, authorizedUrls, adminAuthorizedUrls } from '../Routes/routingConstants';
import { getLeftNavItems, HOME } from '../Routes/routingConstants';

import RFTSMusicPlayer from '../RFTSMusicPlayer';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import './style.scss';
import { stopLoader } from '../components/LoaderComponent/Redux/actions';
const theme = createMuiTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
});
// const isAccessAuthorized = (currentUrl, isAuthorized, isAdminUser) => {
//   if (isAuthorized && authorizedUrls.includes(currentUrl)) {
//     if (isAdminUser && adminAuthorizedUrls.includes(currentUrl)) {
//       return true;
//     } else if (!isAdminUser && adminAuthorizedUrls.includes(currentUrl)) {
//       return false;
//     }
//   } else if (!isAuthorized && authorizedUrls.includes(currentUrl)) {
//     return false;
//   }
// };
function AppComponent(props) {
  const history = useHistory();
  const isHomePage = history.location.pathname === HOME;
  const { authenticated, roles = [], stopLoader } = props;
  const isAdminUser = roles && (roles.includes('admin') || roles.includes('moderator'));
  // if (!isAccessAuthorized(history.location.pathname, authenticated, isAdminUser)) {
  //   history.replace(HOME);
  // }

  const leftNavMenu = getLeftNavItems(authenticated, isAdminUser);
  useEffect(() => {
    stopLoader();
  });
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {authenticated && <RFTSMusicPlayer />}
      <Container className={classnames('app-root rfts-root-container', { 'no-offset': isHomePage })} disableGutters>
        <Header title="Reach for the Stars" navList={leftNavMenu} isAdminUser={isAdminUser} isHomePage={isHomePage} />
        {props.children}
        <Footer />
      </Container>
    </MuiThemeProvider>
  );
}
const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  roles: state.authentication.roles,
});

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(stopLoader()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppComponent));
