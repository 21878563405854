import loaderTypes from '../components/LoaderComponent/Redux/types';

const INITIAL_STATE = {
  isLoading: false,
  isLoadingProgress: null,
};

const reducerHandler = (action, state = INITIAL_STATE) => {
  const reducerObject = {
    [loaderTypes.START_LOADER]: () => ({
      ...state,
      isLoading: true,
    }),
    [loaderTypes.STOP_LOADER]: () => ({
      ...state,
      isLoading: false,
      isLoadingProgress: null,
    }),
    [loaderTypes.SET_PROGRESS_BAR_VALUE]: () => ({
      ...state,
      isLoadingProgress: action.value,
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const common = (state, action) => reducerHandler(action, state);

export default common;
