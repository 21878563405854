const SET_FILE_LIST = 'SET_FILE_LIST';
const GET_FILE_LIST = 'GET_FILE_LIST';
const SYNC_FILES_WITH_BOX_COM = 'SYNC_FILES_WITH_BOX_COM';
const GET_FILE_DATA = 'GET_FILE_DATA';
const SET_FILE_DATA = 'SET_FILE_DATA';
const DELETE_FILE = 'DELETE_FILE';
const CREATE_FILE = 'CREATE_FILE';
const UPDATE_FILE = 'UPDATE_FILE';

export default {
  SET_FILE_LIST,
  GET_FILE_LIST,
  SYNC_FILES_WITH_BOX_COM,
  GET_FILE_DATA,
  SET_FILE_DATA,
  DELETE_FILE,
  CREATE_FILE,
  UPDATE_FILE,
};
