import React from 'react';
import { Button, Divider, makeStyles, Box, Typography } from '@material-ui/core';
import { CheckCircle as CheckIcon, Cancel as CancelIcon } from '@material-ui/icons';
import { HIDE_MATURE_CONTENT, IS_FIRST_RESPONDER, IS_USING_HYPNOSIS, IS_USING_POLYAMORY } from '../utils/appConstants';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  boxContainer: {
    display: 'flex',
    padding: '0 62px 8px'
  },
  boxTextTitle: {
    flexGrow: 1,
    fontWeight: 800,
    paddingRight: '16px'
  },
  boxTextDescription: {
    flexGrow: 1,
    textAlign: 'right'
  }
}));

const IconContext = ({ isCheck }) => isCheck ? <CheckIcon htmlColor='green' /> : <CancelIcon htmlColor='red' />;

export default function Review(props) {
  const classes = useStyles();
  const {
    detailProps: { userDetails },
    activeStep,
    handleBack,
    steps,
    handleNext,
  } = props;
  const { firstName, lastName, email, cellPhone, workHours, timeZone, occupation, incomeGoal, wouldLikeToEarn, inYear, age, sex } = userDetails;

  return (
    <>
      <Box>
        <Typography variant="h5" style={{ textAlign:'center' }} gutterBottom>
          Summary
        </Typography>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>Name</Box>
          <Box className={classes.boxTextDescription}>{`${firstName} ${lastName}`}</Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>Email</Box>
          <Box className={classes.boxTextDescription}>{email}</Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>Gender</Box>
          <Box className={classes.boxTextDescription}>{sex}</Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>Age</Box>
          <Box className={classes.boxTextDescription}>{age}</Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>Contact No.</Box>
          <Box className={classes.boxTextDescription}>{cellPhone}</Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>Best time(s) to reach</Box>
          <Box className={classes.boxTextDescription}>{workHours}</Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>Time Zone</Box>
          <Box className={classes.boxTextDescription}>{timeZone}</Box>
        </Box>
        <Divider style={{ width: '100%', marginBottom: '8px' }} />
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>Occupation</Box>
          <Box className={classes.boxTextDescription}>{occupation}</Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>Your income goal is</Box>
          <Box className={classes.boxTextDescription}>{incomeGoal}</Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>Year you want to reach your goal</Box>
          <Box className={classes.boxTextDescription}>{inYear}</Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>This is ____ your current income</Box>
          <Box className={classes.boxTextDescription}>{wouldLikeToEarn}</Box>
        </Box>    
        {
          userDetails.isSubPlatinum && (
            <>
              <Divider style={{ width: '100%', marginBottom: '8px' }} />
              <Box className={classes.boxContainer}>
                <Box className={classes.boxTextTitle}>Have you had a Life Guidance Discovery Session?</Box>
                <Box className={classes.boxTextDescription}><IconContext isCheck={userDetails.lifeDiscoverySession === 'Yes'} /></Box>
              </Box>
              <Box className={classes.boxContainer} style={{ display: userDetails.lifeDiscoverySession === 'No' ? 'none' : null }}>
                <Box className={classes.boxTextTitle}>Date of your Life Guidance Discovery Session</Box>
                <Box className={classes.boxTextDescription}>{userDetails.lifeDiscoverySessionDate}</Box>
              </Box>
              <Box className={classes.boxContainer} style={{ display: userDetails.lifeDiscoverySession === 'No' ? 'none' : null }}>
                <Box className={classes.boxTextTitle}>Success Center Associate that worked with you</Box>
                <Box className={classes.boxTextDescription}>{userDetails.lifeDiscoverySessionAssociate}</Box>
              </Box>
            </>
          )
        }
        <Divider style={{ width: '100%', marginBottom: '8px' }} />
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>{IS_FIRST_RESPONDER}</Box>
          <Box className={classes.boxTextDescription}><IconContext isCheck={userDetails.isFirstResponder} /></Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>{IS_USING_HYPNOSIS}</Box>
          <Box className={classes.boxTextDescription}><IconContext isCheck={userDetails.isUsingHypnosis} /></Box>
        </Box>
        <Box className={classes.boxContainer} style={{ display: age < 18 ? 'none' : null }}>
          <Box className={classes.boxTextTitle}>{HIDE_MATURE_CONTENT}</Box>
          <Box className={classes.boxTextDescription}><IconContext isCheck={userDetails.showMatureContent} /></Box>
        </Box>
        <Box className={classes.boxContainer} style={{ display: userDetails.hideMatureContent ? 'none' : null }}>
          <Box className={classes.boxTextTitle}>{IS_USING_POLYAMORY}</Box>
          <Box className={classes.boxTextDescription}><IconContext isCheck={userDetails.isUsingPolyamory} /></Box>
        </Box>
        <Divider style={{ width: '100%', marginBottom: '8px' }} />
        <Box className={classes.boxContainer}>
          <Box className={classes.boxTextTitle}>How did you find us?</Box>
          <Box className={classes.boxTextDescription}>{userDetails.reference}</Box>
        </Box>
      </Box>
      <div className={classes.buttons}>
        {activeStep !== steps[0] && (
          <Button onClick={handleBack} className={classes.button}>
            Back
          </Button>
        )}
        <Button variant="contained" color="primary" className={classes.button} onClick={handleNext}>
          {activeStep === steps[steps.length - 1] ? 'Sign Up' : 'Next'}
        </Button>
      </div>
    </>
  );
}
