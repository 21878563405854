import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Avatar, Button, TextField, Grid, Typography, InputAdornment, IconButton } from '@material-ui/core';
import { SIGN_IN } from '../Routes/routingConstants';
import { withRouter } from 'react-router-dom';
import { updateUserPassword, verifyResetPasswordLink } from '../Services/userManagementServiceClient';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  navigationLink: {
    color: 'blue',
    cursor: 'pointer',
  },
}));

const ResetPassword = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, handleSubmit, getValues, errors } = useForm();

  const gotoLink = (url) => {
    const { history } = props;
    history.push(url);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const { history } = props;
    const {
      location: { search },
    } = history;
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');
    if (token) {
      verifyResetPasswordLink(token)
        .then((response) => {
          setEmail(response.email);
        })
        .catch(() => {
          setEmail('');
          toast.error('Reset password link is not valid or expired.');
        });
    } else {
      setEmail('');
      gotoLink(SIGN_IN);
      toast.error('Reset password link is not valid or expired.');
    }
  }, [email]);

  const onSubmit = (data) => {
    const { password } = data;
    if (password) {
      updateUserPassword(email, password)
        .then((response) => {
          gotoLink(SIGN_IN);
          toast.success(response.message);
        })
        .catch((err) => {
          toast.error(err.data.message);
        });
    }
  };
  const passwordType = showPassword ? 'text' : 'password';
  const confirmPasswordType = showConfirmPassword ? 'text' : 'password';
  return (
    <Container component="main" maxWidth="xs" className={classnames('sign-in-container', classes.paper)}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Reset password
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextField
          variant="outlined"
          margin="normal"
          type={passwordType}
          required
          fullWidth
          name="password"
          label="Password"
          id="password"
          autoComplete="current-password"
          error={!!errors.password}
          inputRef={register({
            required: 'Password is Required.',
            min: { value: 8, message: 'Password must be at least 8 letters.' },
            pattern: { value: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i, message: 'Password should contain Special character, one Capital and one number.' },
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={errors.password && errors.password.message}
        />
        <TextField
          variant="outlined"
          margin="normal"
          type={confirmPasswordType}
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          id="confirmPassword"
          autoComplete="confirm-password"
          helperText={errors.confirmPassword && errors.confirmPassword.message}
          error={!!errors.confirmPassword}
          inputRef={register({
            required: 'Please confirm password!',
            validate: {
              matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || 'Passwords should match!';
              },
            },
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownPassword}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" disabled={!email} className={classes.submit}>
          Reset Password
        </Button>
        <Grid container>
          <Grid item xs>
            {/* <Typography component="text" variant="body1" onClick={() => gotoLink(FORGOT_PASSWORD)} className={classes.navigationLink}>
              Forgot password?
            </Typography> */}
          </Grid>
          <Grid item>
            <Typography component="text" variant="body1" className={classes.navigationLink} onClick={() => gotoLink(SIGN_IN)}>
              Back to Sign In
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default withRouter(ResetPassword);
