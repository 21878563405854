import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { SIGN_IN } from '../Routes/routingConstants';
import { withRouter } from 'react-router-dom';
import { checkDuplicateEmail } from '../Services/restClient';
import { Checkbox, Container, FormControl, FormControlLabel, IconButton, InputAdornment } from '@material-ui/core';
import SubscriptionCards from '../Subscription/SubscriptionCards';
import { IS_FIRST_RESPONDER } from '../utils/appConstants';
import SubscriptionInfo from '../Subscription/SubscriptionInfo';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  navigationLink: {
    color: 'blue',
    cursor: 'pointer',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  margin0: {
    margin: 0,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignUpForm(props) {
  const { register, handleSubmit, errors } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();

  const gotoLink = (url) => {
    const { history } = props;
    history.push(url);
  };

  const { submitHandler, detailProps } = props;
  const { userDetails, setSignUpDetails } = detailProps;
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    if (!event) {
      return;
    }
    const { name, value } = event.target;
    if (name === 'isFirstResponder') {
      const { checked } = event.target;
      setSignUpDetails({ ...userDetails, [name]: checked });
    } else {
      setSignUpDetails({ ...userDetails, [name]: value });
    }
  };

  const selectSubscription = (subscription) => {
    setSignUpDetails({ ...userDetails, subscription });
    props.selectSubscription(subscription);
  };

  const onSubmit = () => {
    submitHandler();
  };

  const validateEmail = async () => {
    const data = await checkDuplicateEmail(userDetails);
    return !data.isDuplicate;
  };
  const passwordType = showPassword ? 'text' : 'password';

  return (
    <Container component="main" maxWidth="md" className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign Up
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SubscriptionCards selectedPlan={userDetails.subscription} selectSubscription={selectSubscription} showOther={false} showSelected={9} />
        </Grid>
        <Grid item container xs={12} sm={6} justify="center" alignItems="center">
          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} justify="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  value={userDetails.firstName}
                  autoFocus
                  onChange={handleChange}
                  inputRef={register({ required: 'First name Required.', maxLength: { value: 80, message: 'First name must be less than 80 characters.' } })}
                  error={!!errors.firstName}
                  helperText={errors.firstName && errors.firstName.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  value={userDetails.lastName}
                  name="lastName"
                  autoComplete="lname"
                  onChange={handleChange}
                  inputRef={register({ required: 'Last name Required.', maxLength: { value: 100, message: 'First name must be less than 100 characters.' } })}
                  error={!!errors.lastName}
                  helperText={errors.lastName && errors.lastName.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  value={userDetails.email}
                  name="email"
                  autoComplete="email-new"
                  onChange={handleChange}
                  inputRef={register({ required: 'Email Address Required', pattern: { value: /^\S+@\S+$/i, message: 'Enter valid Email Address.' }, validate: validateEmail })}
                  error={!!errors.email}
                  helperText={errors.email && (errors.email.type === 'validate' ? 'Email already in use try another email!' : errors.email.message)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={passwordType}
                  id="password"
                  autoComplete="current-password-new"
                  onChange={handleChange}
                  error={!!errors.password}
                  value={userDetails.password}
                  inputRef={register({
                    required: 'Password is Required.',
                    min: { value: 8, message: 'Password must be at least 8 letters.' },
                    pattern: { value: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i, message: 'Password should contain Special character, one Capital and one number.' },
                  })}
                  helperText={errors.password && errors.password.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormControlLabel label={IS_FIRST_RESPONDER} control={<Checkbox name="isFirstResponder" id="isFirstResponder" checked={userDetails.isFirstResponder} inputProps={{ 'aria-label': IS_FIRST_RESPONDER }} />} onChange={handleChange} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth name="occupation" label="Occupation" type="text" id="occupation" autoComplete="occupation" onChange={handleChange} error={!!errors.occupation} value={userDetails.occupation} inputRef={register()} />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Choose your Interests
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography component="text" variant="body1" onClick={() => gotoLink(SIGN_IN)} className={classes.navigationLink}>
                  Already have an account? Sign in
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <SubscriptionInfo />
      </Grid>
      <SubscriptionCards selectedPlan={userDetails.subscription} selectSubscription={selectSubscription} showOther={true} showSelected={false} />
    </Container>
  );
}

SignUpForm.propTypes = {
  detailProps: PropTypes.object,
  history: PropTypes.object,
  submitHandler: PropTypes.func,
  selectSubscription: PropTypes.func,
};

export default withRouter(SignUpForm);
