import React, { Component } from 'react';
import { Container, Typography, Button, Grid } from '@material-ui/core';
import './style.scss';
import FileList from './FileList';
import { connect } from 'react-redux';
import { getFileList, getFileData, deleteFile, createFile, updateFile, setFileData } from './Redux/actions';
import CreateNewFile from './CreateNewFile';
import ViewUpdateFile from './ViewUpdateFile';
import { setProgressBarValue } from '../../components/LoaderComponent/Redux/actions';
import { setMusicPlayerOptions, setShowPlayer } from '../../RFTSMusicPlayer/Redux/actions';

class FileManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewFileData: false,
      createNewFile: false,
      viewMode: true,
    };
  }
  componentDidMount() {
    const { getFiles } = this.props;
    getFiles();
  }

  toggleViewFileDataModal = () => {
    const { viewFileData } = this.state;
    const { setViewFileData } = this.props;
    if (viewFileData) {
      setViewFileData(null);
    }
    this.setState({ viewFileData: !viewFileData });
  };

  setViewMode = (value) => this.setState({ viewMode: value });

  toggleCreateNewFileModal = () => {
    const { createNewFile } = this.state;
    this.setState({ createNewFile: !createNewFile });
  };

  viewFileHandler = (fileId, viewMode = true) => {
    const { viewFileData } = this.props;
    viewFileData(fileId);
    this.setViewMode(viewMode);
    this.toggleViewFileDataModal();
  };

  render() {
    const { viewFileData, createNewFile, viewMode } = this.state;
    const { fileList, fileData, deleteFileData, createFileData, updateFileData, setProgressBarValue } = this.props;
    const fileListProps = {
      fileList,
      deleteFileData,
      viewFileHandler: this.viewFileHandler,
      musicPlayerOptions: this.props.musicPlayerOptions,
      playMusic: this.props.playMusic,
      updateMusicParams: this.props.updateMusicParams,
      stopMusic: this.props.stopMusic,
    };
    return (
      <Container className="file-management-container" component="main">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className="page-title">
          Files
        </Typography>
        <Grid container justify="center" className="file-management-actions-container">
          <Button variant="contained" color="primary" onClick={this.toggleCreateNewFileModal}>
            Add New File
          </Button>
        </Grid>
        <FileList {...fileListProps} />
        {viewFileData && fileData && <ViewUpdateFile toggle={this.toggleViewFileDataModal} viewMode={viewMode} open={viewFileData} fileProps={fileData} updateFileData={updateFileData} />}
        {createNewFile && <CreateNewFile toggle={this.toggleCreateNewFileModal} open={createNewFile} createFileData={createFileData} setProgressBarValue={setProgressBarValue}/>}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  fileList: state.fileManagement.fileList || [],
  fileData: state.fileManagement.fileData,
  musicPlayerOptions: state.musicPlayer.musicPlayerOptions,
});
const mapDispatchToProps = (dispatch) => ({
  getFiles: () => dispatch(getFileList()),
  viewFileData: (fileId) => dispatch(getFileData(fileId)),
  setViewFileData: (data) => dispatch(setFileData(data)),
  deleteFileData: (fileId) => dispatch(deleteFile(fileId)),
  createFileData: (data) => dispatch(createFile(data)),
  setProgressBarValue: (data) => dispatch(setProgressBarValue(data)),
  updateFileData: (data) => dispatch(updateFile(data)),
  playMusic: () => dispatch(setShowPlayer(true)),
  updateMusicParams: (value) => dispatch(setMusicPlayerOptions(value)),
  stopMusic: () => dispatch(setShowPlayer(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileManagement);
