import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PLAYLIST, HOME } from '../Routes/routingConstants';
import PropTypes from 'prop-types';

const GuardRoute = ({
  exact = false,
  path,
  component,
  guardType,
}) => {
  const isAuthenticated = useSelector(state => state.authentication.authenticated);
  switch(guardType) {
    case 'nonAuthOnly': {
      if (!isAuthenticated) return <Route exact={exact} path={path} component={component} />;
      else return <Redirect to={PLAYLIST} />;
    }
    case 'authOnly': {
      if (isAuthenticated) return <Route exact={exact} path={path} component={component} />;
      else return <Redirect to={HOME} />;
    }
    default:
      return <Route exact={exact} path={path} component={component} />;
  }
};

GuardRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.objectOf(PropTypes.node),
  guardType: PropTypes.string,
}

export default GuardRoute;
