const SET_INTEREST_LIST = 'SET_INTEREST_LIST';
const GET_INTEREST_LIST = 'GET_INTEREST_LIST';
const GET_INTEREST_DATA = 'GET_INTEREST_DATA';
const SET_INTEREST_DATA = 'SET_INTEREST_DATA';
const DELETE_INTEREST = 'DELETE_INTEREST';
const CREATE_INTEREST = 'CREATE_INTEREST';
const UPDATE_INTEREST = 'UPDATE_INTEREST';

export default {
  SET_INTEREST_LIST,
  GET_INTEREST_LIST,
  GET_INTEREST_DATA,
  SET_INTEREST_DATA,
  DELETE_INTEREST,
  CREATE_INTEREST,
  UPDATE_INTEREST,
};
