import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Toolbar, AppBar, Typography, Link, Container, IconButton, makeStyles, MenuItem, useMediaQuery, useTheme, Grow, Popper, Paper, ClickAwayListener, MenuList } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ProfileHeader from '../ProfileHeader';
import { HOME } from '../../Routes/routingConstants';
import { canShowConstantAppBar } from '../../Routes/routeUtils';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    zIndex: 999,
  },
  menuIconButton: {
    color: 'inherit',
    backgroundColor: 'inherit',
  },
  menuIcon: {
    backgroundColor: 'inherit',
    fontSize: '2rem',
    '&:hover': {
      color: '#3260B1',
      opacity: 1,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: '#fff',
    transition: 'box-shadow 0.35s,transform 0.35s cubic-bezier(0.5, 0, 0.35, 1),opacity 0.35s cubic-bezier(0.5, 0, 0.35, 1),background 0.35s,-webkit-transform 0.35s cubic-bezier(0.5, 0, 0.35, 1)',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#292f38',
      boxShadow: '0 1px 0px rgba(0,0,0,0.13)',
      '& $menuIcon': {
        color: '#000',
      },
    },
  },
  toolbarTitle: {
    fontFamily: "'Work Sans', sans-serif",
    cursor: 'pointer',
    position: 'absolute',
    left: '42%',
    [theme.breakpoints.down('sm')]: {
      left: '36%',
    },
    [theme.breakpoints.down('xs')]: {
      left: '25%',
    },
    fontWeight: 600,
    color: 'inherit',
    '&:hover': {
      color: '#3260B1',
      opacity: 1,
    },
  },
  titleMargin: {
    marginLeft: 0,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  navigationLink: {
    fontFamily: "'Work Sans', sans-serif",
    cursor: 'pointer',
    color: 'inherit',
    marginRight: '10px',
    fontWeight: 600,
    '&:hover': {
      color: '#3260B1',
      opacity: 1,
    },
  },

  constantAppBar: {
    color: '#292f38',
    '&:hover': {
      boxShadow: '0 1px 0px rgba(0,0,0,0.1), 0 4px 10px rgba(0,0,0,0.1)',
    },
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { sections, title, navList, history } = props;
  const { pathname = '' } = history.location;
  const showConstantAppBar = canShowConstantAppBar(pathname);
  const gotoLink = (url) => {
    if (url.includes('acesuccess')) {
      window.open(url, '_blank');
    } else {
      history.push(url);
    }
  };
  const getNavItemData = (navItem) => {
    const { url, title } = navItem;
    return (
      <Typography component="text" variant="body1" onClick={() => gotoLink(url)} className={classes.navigationLink} key={url}>
        {title}
      </Typography>
    );
  };
  return (
    <AppBar position="absolute" className={classes.appBar} elevation={0}>
      <Container disableGutters>
        <Toolbar className={classnames(classes.toolbar, { [classes.constantAppBar]: showConstantAppBar })}>
          <div className="nav-list">
            {xsDevice && (
              <div className="burger-menu">
                <IconButton ref={anchorRef} className={classes.menuIconButton} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle}>
                  <MenuIcon className={classes.menuIcon} />
                </IconButton>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            {navList &&
                              navList.map((navItem) => {
                                const menuItemData = getNavItemData(navItem);
                                if (!menuItemData) {
                                  return null;
                                }
                                return (
                                  <MenuItem key={navItem.title} onClick={handleClose}>
                                    {menuItemData}
                                  </MenuItem>
                                );
                              })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}
            {!xsDevice && <div className="horizontal-menu">{navList && navList.map(getNavItemData)}</div>}
          </div>

          <Typography component="h2" variant="h5" color="inherit" align="center" noWrap className={classnames(classes.toolbarTitle, { [classes.titleMargin]: xsDevice })} onClick={() => gotoLink(HOME)}>
            {title}
          </Typography>
          <ProfileHeader />
        </Toolbar>
        {sections && !!sections.length && (
          <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
            {sections.map((section) => (
              <Link color="inherit" noWrap key={section.title} variant="body2" href={section.url} className={classes.toolbarLink}>
                {section.title}
              </Link>
            ))}
          </Toolbar>
        )}
      </Container>
    </AppBar>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  navList: PropTypes.array,
  title: PropTypes.string,
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
});

export default connect(mapStateToProps, null)(withRouter(Header));
