import appConfig from '../config/appConfig';

class RftsV2Service {
  static async getUserSession(userId, sessionId) {
    try {
      const response = await fetch(`${appConfig.rftsv2_url}/media/${userId}/user-session/${sessionId}`, {
        method: 'GET',
        headers: {
          'x-api-key': appConfig.rftsv2_apikey,
        },
      });

      const rawResponse = await response.json();

      return rawResponse;
    } catch (err) {
      throw Error(err.message);
    }
  }

  static async getUserCurrentSession(userId) {
    try {
      const response = await fetch(`${appConfig.rftsv2_url}/media/${userId}/user-session`, {
        method: 'GET',
        headers: {
          'x-api-key': appConfig.rftsv2_apikey,
        },
      });

      const rawResponse = await response.json();

      return rawResponse;
    } catch (err) {
      throw Error(err.message);
    }
  }

  static async getUserCgmrStatus(userId) {
    try {
      const response = await fetch(`${appConfig.rftsv2_url}/user/platinum/cgmr/${userId}`, {
        method: 'GET',
        headers: {
          'x-api-key': appConfig.rftsv2_apikey,
        },
      });

      const rawResponse = await response.json();

      return rawResponse;
    } catch (err) {
      throw Error(err.message);
    }
  }

  static async updateUserCgmrStatus(userId, cgmrStatus) {
    try {
      const response = await fetch(`${appConfig.rftsv2_url}/user/platinum/toggle-cgmr/${userId}?status=${cgmrStatus}`, {
        method: 'GET',
        headers: {
          'x-api-key': appConfig.rftsv2_apikey,
        },
      });

      const rawResponse = await response.json();

      return rawResponse;
    } catch (err) {
      throw Error(err.message);
    }
  }

  static async getUserInterest(userId) {
    try {
      const response = await fetch(`${appConfig.rftsv2_url}/interests/${userId}`, {
        method: 'GET',
        headers: {
          'x-api-key': appConfig.rftsv2_apikey,
        },
      });

      const rawResponse = await response.json();

      return rawResponse;
    } catch (err) {
      throw Error(err.message);
    }
  }
}

export default RftsV2Service;
