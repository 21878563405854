import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './style.scss';

const SubscriptionCard = (props) => {
  const { tier, selectedSubscription, selectSubscriptionPlan, showSelected } = props;
  const selected = selectedSubscription && selectedSubscription.title === tier.title;
  return (
    <Grid item key={tier.title} xs={12} sm={12} md={selected ? showSelected : 4} className={classnames('subscription-card', { selected })}>
      <Card className={classnames('card', tier.title.toLowerCase())} onClick={() => selectSubscriptionPlan(tier)}>
        <CardHeader title={tier.title} subheader={tier.subheader} titleTypographyProps={{ align: 'center' }} subheaderTypographyProps={{ align: 'center' }} className={classnames('card-header', tier.title.toLowerCase())} />
        <CardContent>
          <div className="card-pricing">
            <Typography component="h2" variant="h3" color="textPrimary" align="center">
              ${tier.amount}
            </Typography>
          </div>
          <ul>
            {tier.pricingDescription.map((line) => (
              <Typography component="li" variant="subtitle1" align="center" key={line}>
                {line}
              </Typography>
            ))}
          </ul>
          <ul className="left-aligned-list">
            {tier.description.map((line) => (
              <Typography component="li" variant="subtitle1" align="left" key={line}>
                {line}
              </Typography>
            ))}
          </ul>
        </CardContent>
      </Card>
    </Grid>
  );
};

SubscriptionCard.propTypes = {
  tier: PropTypes.object,
  selectedSubscription: PropTypes.object,
  selectSubscriptionPlan: PropTypes.func,
  showSelected: PropTypes.number || PropTypes.bool,
};

export default SubscriptionCard;
