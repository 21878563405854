import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import { deleteSalesLeadUser, getAllSalesLeads, getSalesLeadUserById } from '../../../Services/salesLeadUserServiceClient';

import { stopLoader, startLoader } from '../../../components/LoaderComponent/Redux/actions';
import actions from './actions';

function* getSalesLeads() {
  try {
    yield put(startLoader());

    const salesLeadList = yield call(getAllSalesLeads);
    if (salesLeadList && salesLeadList.data) {
      yield put(actions.setSalesLeadList(salesLeadList.data));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching sales leads');
  }
}

function* getSalesLeadUserData(inputObj) {
  const salesLeadUserId = inputObj.value;
  try {
    yield put(startLoader());
    const userData = yield call(getSalesLeadUserById, salesLeadUserId);
    if (userData && userData.data) {
      yield put(actions.setSalesLeadUserData(userData.data));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching sales lead user data');
  }
}

function* deleteSalesLeadUserData(inputObj) {
  const salesLeadUserId = inputObj.value;
  try {
    yield put(startLoader());
    const userData = yield call(deleteSalesLeadUser, salesLeadUserId);
    if (userData) {
      toast.success(userData.message);
      yield put(actions.getSalesLeadList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while deleting sales lead data');
  }
}

export const salesLeadManagementSagas = [takeLatest(types.GET_SALES_LEAD_LIST, getSalesLeads), takeLatest(types.GET_SALES_LEAD_USER_DATA, getSalesLeadUserData), takeLatest(types.DELETE_SALES_LEAD, deleteSalesLeadUserData)];
