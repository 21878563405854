import React from 'react';
import { Typography } from '@material-ui/core';

const DirectionsForT18 = () => {
  return (
    <div>
      <Typography className="page-para">Listening to this guided meditation while you are falling asleep brings abundance to every area of your life from the financial to the spiritual.</Typography>
      <Typography className="page-para">
        To make this guided meditation most effective, before bed, spend about 5 minutes creating an image about what your life would be like once you have achieved a goal. To start, create a goal that is concrete so you can tell when you have reached it, and give yourself a deadline. For example,
        an income goal which is an exact dollar amount achieved by a certain date. Then, describe, in detail (preferably on paper or on a note in your phone), what your life is like now that your goal has been achieved. Create your visualization so that when you close your eyes your goal isn’t just
        a possibility; instead, it is reality, it already happened. Just before you hit play on the recording, visualize this concrete image of your goal (preferably by reading over what you jotted down). Then, hit "Start Playing" and listen to the recording while you fall asleep and during sleep.
      </Typography>
      <Typography className="page-para">When you wake up, however long you have been sleeping, that is how long you'll have been actively drawing that reality into this one.</Typography>
    </div>
  );
};

export default DirectionsForT18;
