import React, { useState } from 'react';
import DialogComponent from '../../../components/DialogComponent';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import './style.scss';
import { findMp3ByName } from '../../../Services/fileManagementServiceClient';
import FileTypeSelection from '../FileTypeSelection';
import { bool, func, object } from 'prop-types';
import { IS_FOR_HYPNOSIS, IS_FOR_POLYAMORY, IS_MATURE_CONTENT } from '../../../utils/appConstants';
import { toast } from 'react-toastify';
import appConfig from '../../../config/appConfig';

const ViewUpdateFile = (props) => {
  const { toggle, open, fileProps, viewMode = true } = props;
  const [fileData, setFileData] = useState(fileProps);

  const { register, handleSubmit, errors } = useForm();

  const header = 'File Data';

  const isNameExist = async (name) => {
    const response = await findMp3ByName(name);
    const isExistArray = response.data.filter((item) => item._id !== fileProps._id);
    return isExistArray.data && !!isExistArray.data.length;
  };
  const checkMimeType = (event) => {
    const { name } = event.target;
    const types = {
      file: ['audio/mpeg'],
      cover: ['image/jpeg', 'image/png'],
    };
    //getting file object
    const file = event.target.files[0];
    //define message container
    let err = '';
    // list allow mime type
    if (!types[name].includes(file.type)) {
      // create error message and assign to container
      err += file.type + ' is not a supported format\n';
    }

    if (err !== '') {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      toast.warn(err);
      return false;
    }
    return true;
  };

  const onFileChange = (e) => {
    const { name } = e.target;
    if (!checkMimeType(e)) {
      return;
    }
    setFileData({ ...fileData, [name]: e.target.files[0] });
  };

  const onChangeHandler = async (event) => {
    const { name, value, checked, type } = event.target;
    if (type === 'file') {
      onFileChange(event);
    } else if (type === 'checkbox') {
      setFileData({ ...fileData, [name]: checked });
    } else {
      setFileData({ ...fileData, [name]: value });
    }
  };

  const onSubmit = () => {
    const { updateFileData } = props;
    const formData = new FormData();
    for (const i in fileData) {
      if (fileData.hasOwnProperty(i)) {
        debugger;
        formData.append(i, fileData[i]);
      }
    }
    updateFileData(formData);
    toggle();
  };

  const footer = viewMode && (
    <Button color="primary" className="close-button" onClick={toggle}>
      close
    </Button>
  );

  const body = (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="view-update-file-form" encType="multipart/form-data">
      <Grid container spacing={3} className="view-update-file-form-container">
        <Grid item xs={12}>
          <TextField
            required
            variant="outlined"
            size="small"
            id="name"
            name="name"
            label="File Name"
            color="primary"
            defaultValue={fileData.name}
            value={fileData.name}
            fullWidth
            autoComplete="file-name"
            inputRef={register({ required: 'File name Required.', validate: async (value) => !(await isNameExist(value)) || 'File name already exist.', maxLength: { value: 80, message: 'File name should be less than 80 characters.' } })}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
            onChange={onChangeHandler}
            disabled={viewMode}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="outlined" size="small" disabled={viewMode} id="sku" name="sku" label="SKU" defaultValue={fileData.sku} fullWidth autoComplete="file-sku" onChange={onChangeHandler} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant="outlined"
            size="small"
            disabled={viewMode}
            id="description"
            name="description"
            label="Mp3 name (End user Side)"
            defaultValue={fileData.description}
            fullWidth
            autoComplete="file-description"
            onChange={onChangeHandler}
            inputRef={register({ required: 'Mp3 name (End user Side) Required.' })}
            error={!!errors.description}
            helperText={errors.description && errors.description.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="outlined" disabled={viewMode} size="small" id="shortDescription" name="shortDescription" label="Short Description" defaultValue={fileData.shortDescription} fullWidth autoComplete="short-description" onChange={onChangeHandler} />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="outlined" disabled={viewMode} size="small" id="longDescription" name="longDescription" label="Long Description" defaultValue={fileData.longDescription} fullWidth autoComplete="long-description" onChange={onChangeHandler} />
        </Grid>
        <Grid item xs={12}>
          <FileTypeSelection fileData={fileData} setFileData={setFileData} />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel label={IS_MATURE_CONTENT} control={<Checkbox name="isMatureContent" id="isMatureContent" checked={fileData.isMatureContent} inputProps={{ 'aria-label': IS_MATURE_CONTENT }} />} onChange={onChangeHandler} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel label={IS_FOR_POLYAMORY} control={<Checkbox name="isForPolyamory" id="isForPolyamory" checked={fileData.isForPolyamory} inputProps={{ 'aria-label': IS_FOR_POLYAMORY }} />} onChange={onChangeHandler} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel label={IS_FOR_HYPNOSIS} control={<Checkbox name="isForHypnosis" id="isForHypnosis" checked={fileData.isForHypnosis} inputProps={{ 'aria-label': IS_FOR_HYPNOSIS }} />} onChange={onChangeHandler} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <img src={`${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/thumbnail/${fileData._id}`} alt="cover mp3" width="50" />
          <TextField type="file" label="Mp3 Cover" variant="outlined" name="cover" autoComplete="cover" fullWidth onChange={onChangeHandler} />
        </Grid>
        <Grid item xs={12}>
          <TextField type="file" label="Mp3 File" variant="outlined" name="file" fullWidth onChange={onChangeHandler} />
          {/* <TextField type="text" label="File URL" variant="outlined" name="file" autoComplete="file-data" disabled={true} fullWidth defaultValue={fileData.filePath} /> */}
        </Grid>
        {!viewMode && (
          <Grid item xs={12} container justify="flex-end">
            <Button color="primary" type="submit" className="upload-button">
              Update
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
  return <DialogComponent body={body} header={header} open={open} footer={footer} onClose={toggle} fullWidth={false} />;
};

ViewUpdateFile.propTypes = {
  toggle: func,
  open: bool,
  fileProps: object,
  viewMode: bool,
  updateFileData: func,
};

export default ViewUpdateFile;
