import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SignUpForm from './SignUpForm';
import SignUpStepper from './SignUpStepperContainer';
import { connect } from 'react-redux';
import { signUpUser, setSignUpSuccessful } from './redux/actions';
import { getInterestList } from '../AdminPanel/InterestManagement/Redux/actions';
import { setSubscriptionPlan } from '../Subscription/Redux/actions';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const initialSignUpDetails = {
  address: '',
  city: '',
  state: '',
  zip: '',
  timezone: '',
  cellphone: '',
  workPhone: '',
  workHour: '',
  homePhone: '',
  homeHour: '',
  age: '',
  subscription: { amount: '5', title: 'Bronze' },
  status: '',
  sex: '',
  sexuality: '',
  study: '',
  hideMatureContent: true,
  iam: '',
  wouldLikeToEarn: '',
  inYear: '',
  email: '',
  password: '',
  selectedInterests: [],
};
function SignUp(props) {
  const classes = useStyles();
  const [showStepper, setShowStepper] = useState(false);
  initialSignUpDetails.subscription = props.selectedSubscription;
  const [userDetails, setSignUpDetails] = useState(initialSignUpDetails);

  const detailProps = {
    userDetails,
    setSignUpDetails,
  };
  const { signUpUserData, setSignUpSuccess, singUpSuccess, interestList, getInterests, selectSubscription } = props;
  const handleSignUpClick = () => {
    setShowStepper(true);
    setSignUpSuccess(false);
  };
  if (!interestList || !interestList.length) {
    getInterests();
  }

  return (
    <>
      {!showStepper && <SignUpForm detailProps={detailProps} submitHandler={handleSignUpClick} selectSubscription={selectSubscription} />}
      <Container component="main" maxWidth="xs" className={classes.paper}>
        {showStepper && <SignUpStepper detailProps={detailProps} signUpUserData={signUpUserData} singUpSuccess={singUpSuccess} interestList={interestList} subscription={props.selectedSubscription} />}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedSubscription: state.subscription.selectedSubscription,
  singUpSuccess: state.onBoardingReducer.singUpSuccess,
  interestList: state.interestManagement.interestList || [],
});
const mapDispatchToProps = (dispatch) => ({
  signUpUserData: (data) => dispatch(signUpUser(data)),
  getInterests: () => dispatch(getInterestList()),
  setSignUpSuccess: (data) => dispatch(setSignUpSuccessful(data)),
  selectSubscription: (valueObj) => dispatch(setSubscriptionPlan(valueObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
