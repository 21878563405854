import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import { getAllFiles, syncFilesWithBoxCom, getFileInformation, deleteFile, createOrUploadFile, updateFile } from '../../../Services/fileManagementServiceClient';

import { stopLoader, startLoader } from '../../../components/LoaderComponent/Redux/actions';
import actions from './actions';
import rftsLogo from '../../../images/rftsLogo.jpg';
import appConfig from '../../../config/appConfig';

function* getFileList() {
  try {
    yield put(startLoader());

    const fileList = yield call(getAllFiles);
    if (fileList && fileList.data) {
      const files = fileList.data.map((fileItem) => ({ ...fileItem, label: fileItem.name, value: fileItem._id, cover: rftsLogo, musicSrc: () => Promise.resolve(`${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/download/${fileItem._id}`) }));
      yield put(actions.setFileList(files));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching file list');
  }
}

function* getFileData(inputObj) {
  const fileId = inputObj.value;
  try {
    yield put(startLoader());
    const fileData = yield call(getFileInformation, fileId);
    if (fileData && fileData.data) {
      yield put(actions.setFileData(fileData.data));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching file data');
  }
}

function* deleteFileData(inputObj) {
  const fileId = inputObj.value;
  try {
    yield put(startLoader());
    const fileData = yield call(deleteFile, fileId);
    if (fileData) {
      toast.success(fileData.message);
      yield put(actions.getFileList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while deleting file data');
  }
}

function* createFileData(inputObj) {
  const { formData, onUploadProgress } = inputObj.value;
  try {
    if (!formData) {
      return;
    }
    yield put(startLoader());
    const fileData = yield call(createOrUploadFile, formData, onUploadProgress);
    if (fileData) {
      toast.success(fileData.message);
      yield put(actions.getFileList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while creating file');
  }
}

function* updateFileData(inputObj) {
  const formData = inputObj.value;
  try {
    if (!formData) {
      return;
    }
    yield put(startLoader());
    const fileData = yield call(updateFile, formData);
    if (fileData) {
      toast.success(fileData.message);
      yield put(actions.getFileList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while updating file');
  }
}

function* syncFilesWithBox() {
  try {
    yield put(startLoader());
    yield call(syncFilesWithBoxCom);
    yield put(getFileList());
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching file list');
  }
}

export const fileManagementSagas = [
  takeLatest(types.GET_FILE_LIST, getFileList),
  takeLatest(types.UPDATE_FILE, updateFileData),
  takeLatest(types.CREATE_FILE, createFileData),
  takeLatest(types.GET_FILE_DATA, getFileData),
  takeLatest(types.DELETE_FILE, deleteFileData),
  takeLatest(types.SYNC_FILES_WITH_BOX_COM, syncFilesWithBox),
];
