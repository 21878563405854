import types from './types';

export const getSalesLeadList = (value) => ({
  type: types.GET_SALES_LEAD_LIST,
  value,
});

export const setSalesLeadList = (value) => ({
  type: types.SET_SALES_LEAD_LIST,
  value,
});

export const getSalesLeadUserData = (value) => ({
  type: types.GET_SALES_LEAD_USER_DATA,
  value,
});
export const setSalesLeadUserData = () => ({
  type: types.SET_SALES_LEAD_USER_DATA,
});

export const deleteSalesLeadUser = (value) => ({
  type: types.DELETE_SALES_LEAD,
  value,
});

export default {
  getSalesLeadList,
  setSalesLeadList,
  getSalesLeadUserData,
  setSalesLeadUserData,
  deleteSalesLeadUser,
};
