import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import SalesLeadList from './SalesLeadList';
import { connect } from 'react-redux';
import { getSalesLeadList, deleteSalesLeadUser } from './Redux/actions';

import './style.scss';

class SalesLeadManagement extends Component {
  componentDidMount() {
    const { getSalesLeads } = this.props;
    getSalesLeads();
  }

  render() {
    const { salesLeadList, deleteSalesLead } = this.props;
    const fileListProps = {
      salesLeadList,
      deleteSalesLead,
    };
    return (
      <Container className="sales-lead-management-container" component="main">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className="page-title">
          Sales Leads
        </Typography>
        <SalesLeadList {...fileListProps} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  salesLeadList: state.salesLeadManagement.salesLeadList || [],
});
const mapDispatchToProps = (dispatch) => ({
  getSalesLeads: () => dispatch(getSalesLeadList()),
  deleteSalesLead: (salesLeadUserId) => dispatch(deleteSalesLeadUser(salesLeadUserId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesLeadManagement);
