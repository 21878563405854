import React, { useState } from 'react';
import DialogComponent from '../../../components/DialogComponent';
import { Button, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import './style.scss';
import ReactSelect from 'react-select';

const CreateNewInterest = (props) => {
  const { toggle, open, musicList } = props;
  const [interestDetails, setInterestData] = useState({
    name: '',
    audioList: [],
  });

  const { register, handleSubmit, errors } = useForm();
  const header = 'Create New Interest';

  const handleChange = (value, eventData) => {
    const { name } = eventData;
    if (name.includes('fileSelect')) {
      const fileIndex = name.split('fileSelect')[1];
      let { audioList } = interestDetails;
      if (value) {
        audioList[fileIndex] = value;
      } else {
        audioList.splice(fileIndex, 1);
      }
      setInterestData({ ...interestDetails, audioList });
    } else {
      setInterestData({ ...interestDetails, [name]: value });
    }
  };

  const onSubmit = () => {
    const { createInterestData } = props;
    createInterestData(interestDetails);
    toggle();
  };

  const addMoreAudio = () => {
    const { audioList } = interestDetails;
    audioList.push('');
    setInterestData({ ...interestDetails, audioList });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="create-interest-form">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            variant="outlined"
            size="small"
            id="name"
            name="name"
            label="Interest name"
            defaultValue={interestDetails.name}
            fullWidth
            autoComplete="first-name"
            inputRef={register({ required: 'Interest name Required.', maxLength: { value: 30, message: 'Interest name must be less than 30 characters.' } })}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
            onChange={(event) => handleChange(event.target.value, event.target)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            type="number"
            required
            variant="outlined"
            size="small"
            id="order"
            name="order"
            label="Interest Order"
            defaultValue={interestDetails.order}
            fullWidth
            autoComplete="first-name"
            inputRef={register({ required: 'Interest Order Required.' })}
            error={!!errors.order}
            helperText={errors.order && errors.order.message}
            onChange={(event) => handleChange(event.target.value, event.target)}
          />
        </Grid>
        {interestDetails.audioList.map((audioListItem, index) => {
          const listIndex = index + 1;
          const selectedValue = musicList && musicList.find((musicItem) => musicItem._id === audioListItem);
          return (
            <Grid item xs={12} sm={12} key={listIndex}>
              <ReactSelect required options={musicList} value={selectedValue} classNamePrefix="select" isClearable={true} id={listIndex} className="react-select" onChange={handleChange} name={`fileSelect${index}`} placeholder="Select audio to set audio List" />
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6} onClick={addMoreAudio}>
          <Button variant="contained" color="primary">
            Add more audios
          </Button>
        </Grid>
      </Grid>
      <Grid item container xs={12} justify="flex-end">
        <Button type="submit" variant="contained" color="primary">
          Create
        </Button>
      </Grid>
    </form>
  );
  return <DialogComponent body={body} header={header} open={open} onClose={toggle} fullWidth={false} maxWidth="md" />;
};

CreateNewInterest.propTypes = {
  toggle: PropTypes.func,
  open: PropTypes.bool,
  musicList: PropTypes.array,
  createInterestData: PropTypes.func,
};

export default CreateNewInterest;
