import types from './types';

export const setUserList = (value) => ({
  type: types.SET_USER_LIST,
  value,
});

export const getUserRoles = (value) => ({
  type: types.GET_ROLE_LIST,
  value,
});

export const setUserRoles = (value) => ({
  type: types.SET_ROLE_LIST,
  value,
});
export const getUserList = () => ({
  type: types.GET_USER_LIST,
});

export const getUserData = (value) => ({
  type: types.GET_USER_DATA,
  value,
});

export const setUserData = (value) => ({
  type: types.SET_USER_DATA,
  value,
});

export const createUser = (value) => ({
  type: types.CREATE_USER,
  value,
});

export const deleteUser = (value) => ({
  type: types.DELETE_USER,
  value,
});

export const updateUser = (value) => ({
  type: types.UPDATE_USER,
  value,
});

export const getModeratorList = () => ({
  type: types.GET_MODERATOR_LIST,
});

export const setModeratorList = (value) => ({
  type: types.SET_MODERATOR_LIST,
  value,
});

export const getCurrentUserSession = (value) => ({
  type: types.GET_CURRENT_USER_SESSION,
  value,
});

export const setCurrentUserSession = (value) => ({
  type: types.SET_CURRENT_USER_SESSION,
  value,
});

export const getSessionInfoBySessionIndex = (value) => ({
  type: types.GET_SESSION_INFO_BY_SESSION_INDEX,
  value,
});

export const resetUserSessions = (value) => ({
  type: types.RESET_USER_SESSIONS,
  value,
});

export const moveUserSession = (value) => ({
  type: types.MOVE_USER_SESSION,
  value,
});

export default {
  setUserList,
  getUserList,
  getUserData,
  setUserData,
  createUser,
  deleteUser,
  updateUser,
  getUserRoles,
  setUserRoles,
  getModeratorList,
  setModeratorList,
  getCurrentUserSession,
  setCurrentUserSession,
  getSessionInfoBySessionIndex,
  resetUserSessions,
  moveUserSession,
};
