import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import DialogComponent from '../../components/DialogComponent';
import './style.scss';

const PlayingInstructions = (props) => {
  const { open, toggle } = props;
  const header = 'Welcome to reachforthestars.today, where sleeping to your full potential is now a “Thing”';
  const body = (
    <Grid container className="playing-instructions">
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="modal-para">
        If you are using a phone or other device, make sure the screen saver is turned off so that it does not stop the playback. You’ll want the volume at a comfortable level.
      </Typography>
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="modal-para">
        Once on the playlist page, for all subscribers, all you need to do is click on the “Start Playing” button, an audio player will show up on the bottom of the screen and start playing. Lay back relax and let it work for you.
      </Typography>
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="modal-para">
        You will first hear a short piece of relaxing music that will allow you the time to get into a relaxed comfortable position. The Meditation Session will play two recordings during each session 2 1/2 hours apart, the sessions will rotate through your selected interests you have chosen. Each
        one will eventually play for 21 times to get the maximum effect.
      </Typography>
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="modal-para">
        If you are a Silver or Platinum Subscriber, then you will also have an option to listen to your other chosen recordings that you can play separately outside of your set meditation sessions. To do that click on “Meditation Playlist” and then click on play from the specific recording you want
        to listen to!
      </Typography>
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="modal-para">
        And if you are a Platinum Subscriber you also will have the option of Playing everything in our Meditation Library at any time outside of your set Meditation Sessions by clicking on the “Meditation Library” button then choosing a recording by clicking on play.
      </Typography>
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="modal-para">
        Just remember that the hardest thing to do to make this work is to discipline yourself to hit the play button every night when you go to bed. That’s it.
      </Typography>
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="modal-para">
        Please enjoy meditations and we would appreciate knowing about any of your successes.
      </Typography>
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="modal-para">
        Thank you for joining.
      </Typography>
    </Grid>
  );
  return <DialogComponent body={body} header={header} open={open} onClose={toggle} fullWidth={false} maxWidth="md" />;
};

PlayingInstructions.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
};

export default PlayingInstructions;
