import SecureLS from 'secure-ls';
export const secureLS = new SecureLS({
  encodingType: 'aes',
});

export const storeData = (key, value) => {
  secureLS.set(key, value);
};

export const getStoredData = (key) => {
  return secureLS.get(key);
};

export const clearAllData = () => {
  secureLS.removeAll();
};

export const removeKey = (key) => {
  secureLS.remove(key);
};

export const storeSignInDetails = (data) => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const element = data[key];
      storeData(key, element);
    }
  }
};
