import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { IconButton, Grid } from '@material-ui/core';

import MaterialDataTable from '../../../components/MaterialDataTable';
import './style.scss';
import { downloadUsersCSV } from '../../../Services/userManagementServiceClient';
import ViewSessionInfo from '../ViewSessionInfo';
import ViewInterestList from '../ViewInterestList';

const UserList = (props) => {
  const { userList, viewUserHandler, deleteUserData, roleList } = props;
  const rolesObject = {};
  roleList.forEach((element) => {
    rolesObject[element._id] = element;
  });
  const onDownloadCSV = () => downloadUsersCSV();
  const data =
    userList &&
    userList.map((mp3Item) => {
      const { email, firstName, lastName, _id, subscription, roles, createdAt, sessionIndex, cellPhone, sessionUpdateDate, lastPlayedDate: lpDate, updatedAt } = mp3Item;
      const actions = (
        <Grid container justify="flex-start">
          <IconButton onClick={() => viewUserHandler(_id, false)} title="Edit User Info">
            <EditRoundedIcon color="primary" />
          </IconButton>
          <ViewInterestList userId={_id} />
          <IconButton onClick={() => deleteUserData(_id)} title="Delete User">
            <DeleteRoundedIcon color="error" />
          </IconButton>
        </Grid>
      );
      const isFree = !subscription || subscription.toLowerCase() === 'free';
      const role = roles[0] || roles;
      const roleText = (rolesObject[role] && rolesObject[role].name) || 'user';
      const isUser = roleText === 'user';
      const createdDate = moment(createdAt).format('LL');
      const sessionDate = moment(sessionUpdateDate).format('LL');
      const lastPlayedDate = moment(lpDate).format('LL');
      const lastUpdateDate = moment(updatedAt).format('LL');
      const sessionInfo = <ViewSessionInfo sessionIndex={sessionIndex} userId={_id} email={email} firstName={firstName} lastName={lastName} subscription={subscription} sessionDate={sessionDate} />;

      return {
        name: `${firstName} ${lastName}`,
        role: roleText,
        membership: (isUser && subscription) || '-',
        actions,
        phone: cellPhone,
        email,
        createdAt: createdDate,
        session: isUser ? sessionInfo : '-',
        lastPlayedDate: lpDate ? lastPlayedDate : null,
        lastUpdateDate,
      };
    });

  const headers = [
    { title: 'Name', field: 'name' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
    { title: 'Role', field: 'role', width: '7%', align: 'center' },
    { title: 'Membership', field: 'membership', width: '12%', align: 'center' },
    { title: 'Created At', field: 'createdAt', width: '12%', align: 'center', customSort: (a, b) => new Date(b.createdAt) - new Date(a.createdAt) },
    { title: 'Last played At', field: 'lastPlayedDate', width: '12%', align: 'center', customSort: (a, b) => new Date(b.lastPlayedDate) - new Date(a.lastPlayedDate) },
    { title: 'Session', field: 'session', width: '5%', align: 'center' },
    { title: 'Actions', field: 'actions', width: '40%', align: 'center', sorting: false },
  ];
  return (
    <MaterialDataTable
      title={
        <>
          {data.length || 0} users
          <IconButton variant="outlined" color="primary" onClick={onDownloadCSV} title="Download to CSV">
            <CloudDownloadIcon />
          </IconButton>
        </>
      }
      headers={headers}
      data={data}
      className="user-list"
    />
  );
};
UserList.propTypes = {
  userList: PropTypes.array,
  viewUserHandler: PropTypes.func,
  deleteUserData: PropTypes.func,
  roleList: PropTypes.array,
};

export default UserList;
