import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './authReducer';
import subscription from './subscription';
import common from './common';
import musicPlayer from './musicPlayer';
import fileManagement from './fileManagement';
import userManagement from './userManagement';
import onBoardingReducer from './onBoardingReducer';
import interestManagement from './interestManagement';
import salesLeadManagement from './salesLeadReducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    authentication: authReducer,
    common,
    subscription,
    musicPlayer,
    onBoardingReducer,
    fileManagement,
    userManagement,
    interestManagement,
    salesLeadManagement,
  });

export default rootReducer;
