import React from 'react';

import PropTypes from 'prop-types';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { IconButton, Grid } from '@material-ui/core';
import moment from 'moment';

import MaterialDataTable from '../../../components/MaterialDataTable';

import './style.scss';
const InterestList = (props) => {
  const { interestList, viewInterestHandler, deleteInterestData, isAdminRole } = props;
  const data =
    interestList &&
    interestList.map((mp3Item) => {
      const { name, _id, createdAt, order } = mp3Item;
      const actions = (
        <Grid container justify="flex-start">
          <IconButton onClick={() => viewInterestHandler(_id, false)}>
            <EditRoundedIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => deleteInterestData(_id)}>
            <DeleteRoundedIcon color="error" />
          </IconButton>
        </Grid>
      );
      const createdDate = moment(createdAt).format('LL');
      return {
        name,
        order,
        actions,
        createdAt: createdDate,
      };
    });
  const headers = [
    { title: 'Name', field: 'name' },
    { title: 'Order', field: 'order' },
    { title: 'Created At', field: 'createdAt', align: 'center', customSort: (a, b) => new Date(b.createdAt) - new Date(a.createdAt) },
    { title: 'Actions', field: 'actions', width: '15%', align: 'center', hidden: !isAdminRole, sorting: false },
  ];
  return <MaterialDataTable headers={headers} data={data} className="interest-list" />;
};

InterestList.propTypes = {
  interestList: PropTypes.array,
  viewInterestHandler: PropTypes.func,
  deleteInterestData: PropTypes.func,
  isAdminRole: PropTypes.bool,
};

export default InterestList;
