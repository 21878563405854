const SET_PLAY_LIST = 'SET_PLAY_LIST';
const GET_ALL_MUSIC = 'GET_ALL_MUSIC';
const GET_USER_INTEREST_PLAYLIST = 'GET_USER_INTEREST_PLAYLIST';
const SYNC_MUSIC_WITH_BOX_COM = 'SYNC_MUSIC_WITH_BOX_COM';
const GET_PLAY_LIST = 'GET_PLAY_LIST';
const GET_NEXT_SESSION = 'GET_NEXT_SESSION';
const SET_SHOW_PLAYER = 'SET_SHOW_PLAYER';
const SET_PLAYLIST_INDEX = 'SET_PLAYLIST_INDEX';
const SET_MUSIC_PLAYER_OPTIONS = 'SET_MUSIC_PLAYER_OPTIONS';
const SET_PER_SESSION_MP3 = 'SET_PER_SESSION_MP3';
const SET_MP3_AS_PLAYED = 'SET_MP3_AS_PLAYED';
const GET_FREE_MP3_PLAYLIST = 'GET_FREE_MP3_PLAYLIST';
const SET_FREE_MP3_PLAYLIST = 'SET_FREE_MP3_PLAYLIST';

export default {
  SET_PLAY_LIST,
  GET_PLAY_LIST,
  GET_USER_INTEREST_PLAYLIST,
  GET_ALL_MUSIC,
  SET_SHOW_PLAYER,
  GET_NEXT_SESSION,
  SYNC_MUSIC_WITH_BOX_COM,
  SET_PLAYLIST_INDEX,
  SET_MUSIC_PLAYER_OPTIONS,
  SET_PER_SESSION_MP3,
  SET_MP3_AS_PLAYED,
  GET_FREE_MP3_PLAYLIST,
  SET_FREE_MP3_PLAYLIST,
};
