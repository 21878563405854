import React from 'react';
import { Typography } from '@material-ui/core';
import { bool } from 'prop-types';

const PlaylistInformation = (props) => {
  const { isSilver, isPlatinum, isFree } = props;
  return (
    <div>
      {(isSilver || isPlatinum || isFree) && (
        <>
          <Typography className="page-para">
            The <b>"Meditation Session"</b> button will play two mediation recordings per night from your <b>"Meditation Playlist"</b> starting from the top three of your 10 interests. The first one will play immediately and then the second 2.5 hours later. They will continue rotating every session
            using your first 3 interests plus your Customized Goal Manifestation meditation for the first 7 sessions. Each week thereafter it will add another one of the interests on your list until each one has played for 21 sessions.
          </Typography>
          <Typography className="page-para">
            You can click on the <b>"Meditation Playlist"</b> and click on any recordings in your program to play outside of your "Meditation Session" and cycle.
          </Typography>
        </>
      )}
      {(isPlatinum || isFree) && (
        <Typography className="page-para">
          You can click on the <b>"Meditation Library"</b> button and you can choose anything in the complete Reach for the Stars library to play outside of your <b>"Meditation Session"</b> and cycle.
        </Typography>
      )}
    </div>
  );
};

PlaylistInformation.propTypes = {
  isSilver: bool,
  isPlatinum: bool,
  isFree: bool,
};

export default PlaylistInformation;
