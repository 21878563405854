const SET_SALES_LEAD_LIST = 'SET_SALES_LEAD_LIST';
const GET_SALES_LEAD_LIST = 'GET_SALES_LEAD_LIST';
const GET_SALES_LEAD_USER_DATA = 'GET_SALES_LEAD_USER_DATA';
const SET_SALES_LEAD_USER_DATA = 'SET_SALES_LEAD_USER_DATA';
const DELETE_SALES_LEAD = 'DELETE_SALES_LEAD';

export default {
  SET_SALES_LEAD_LIST,
  GET_SALES_LEAD_LIST,
  GET_SALES_LEAD_USER_DATA,
  SET_SALES_LEAD_USER_DATA,
  DELETE_SALES_LEAD,
};
