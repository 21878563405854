import React, { Component } from 'react';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import 'react-jinke-music-player/assets/index.css';
class MusicPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioInstance: null,
      timeoutId: null,
    };
  }

  setAudioInstance = (audioInstance) => {
    this.setState({ ...this.state, audioInstance });
  };

  getAudioInstance = (instance) => {
    this.setAudioInstance(instance);
  };

  onPlayIndexChange = (playIndex) => {
    const { timeoutId, audioInstance } = this.state;
    const { playlistIndex, updateParams } = this.props;
    if (playlistIndex === 0 && playIndex > 1 && playIndex % 2 === 0 && !timeoutId) {
      const timeoutId = setTimeout(() => {
        const timeoutId = this.timeoutId;
        audioInstance.play();
        this.timeoutId = null;
        clearTimeout(timeoutId);
      }, 9000000);
      audioInstance.pause();
      this.timeoutId = timeoutId;
    }
    updateParams({ playIndex });
  };

  onAudioPlay = (audioInfo) => {
    const { audioInstance } = this.state;
    if (this.timeoutId) {
      audioInstance.pause();
    }
  };

  onAudioEnded = (currentPlayId, audioLists, audioInfo) => {
    const { playlistIndex, setPlayed } = this.props;
    const { _id } = audioInfo;
    if (playlistIndex === 0) {
      setPlayed(_id);
    }
  };

  render() {
    const { destroyPlayer, params, updateParams } = this.props;
    return (
      <ReactJkMusicPlayer
        {...params}
        onThemeChange={(theme) => {
          updateParams({ theme });
        }}
        onModeChange={(mode) => {
          updateParams({ mode });
        }}
        showPlayMode= {false}
        onPlayIndexChange={this.onPlayIndexChange}
        onAudioPlay={this.onAudioPlay}
        getAudioInstance={this.getAudioInstance}
        onAudioEnded={this.onAudioEnded}
        onDestroyed={destroyPlayer}
        locale={{ destroyText: 'Are you sure you would like to close the player?' }}
      />
    );
  }
}

export default MusicPlayer;
