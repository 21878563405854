import React from 'react';
import { Container, Typography } from '@material-ui/core';
import './style.scss';

function SubscriptionFooter() {
  return (
    <Container maxWidth="md" component="main" className="subscription-footer">
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
        By signing up for Reach for the Stars, you will receive emails from Success Center and from Success Center affiliates with information about upcoming opportunities suitable to your individual goals and desires. We do our best to work with integrity and part of that includes not sharing your
        information with those we have not personally vetted. For more information about trusted affiliates who can assist you in a number of ways, please contact the office at (800) GOAL NOW (462-5669).
      </Typography>
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
        <b>* Founder's Benefit:</b> First 50 people to sign up keep this price and benefits package as long as your membership continues (regardless of future price increases).
      </Typography>
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
        ** All memberships may be canceled at any time with the user responsible only for past payments and the current month's membership payment.
      </Typography>
    </Container>
  );
}

export default SubscriptionFooter;
