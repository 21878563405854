import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InterestsSelectionForm from '../components/InterestSelectionForm';

const styles = (theme) => ({
  container: {
    display: 'flex', //flex
    flexFlow: 'row',
    textAlign: 'center',
    color: 'black',
  },
  text: {
    padding: '10px 0',
    minWidth: '200px',
  },

  checkboxes: {
    position: 'relative',
    right: '10px',
  },

  orders: {
    position: 'relative',
    top: '20px',
    right: '10px',
  },

  textField: {
    margin: theme.spacing.unit,
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },

  formControl: {
    margin: theme.spacing.unit,
    minWidth: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footerButton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

class InterestsForm extends React.Component {
  setInterestsList = (selectedInterests) => {
    const {
      detailProps: { setSignUpDetails, userDetails },
    } = this.props;
    setSignUpDetails({ ...userDetails, selectedInterests });
  };

  handleOrder = (increase, key) => {
    const {
      detailProps: { userDetails },
    } = this.props;
    const { selectedInterests: selected } = userDetails;
    const selectedInterests = selected.slice();
    const item = selectedInterests.splice(key, 1);
    if (increase) {
      selectedInterests.splice(key - 1, 0, item[0]);
    } else {
      selectedInterests.splice(key + 1, 0, item[0]);
    }
    this.setInterestsList(selectedInterests);
  };
  handleListClear = () => {
    this.setInterestsList([]);
  }

  handleListClick = (value) => () => {
    const {
      detailProps: { userDetails },
    } = this.props;
    const { selectedInterests } = userDetails;
    const currentIndex = selectedInterests.indexOf(value);
    const newChecked = [...selectedInterests];

    if (newChecked.length < 10 || currentIndex >= 0) {
    if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      this.setInterestsList(newChecked);
    }
  };

  render() {
    const { classes, activeStep, handleBack, steps, handleNext, interests } = this.props;
    const {
      detailProps: {
        userDetails: { selectedInterests: selected = [] },
      },
    } = this.props;
    return (
      <>
        <InterestsSelectionForm selected={selected} interests={interests} handleListClick={this.handleListClick} />
        <div className={classes.buttons}>
          <Button onClick={this.handleListClear} color="secondary" variant='contained' className={classes.footerButton}>
            Reset
          </Button>
          {activeStep !== steps[0] && (
            <Button onClick={handleBack} className={classes.footerButton}>
              Back
            </Button>
          )}
          {activeStep !== steps[steps.length - 1] && (
            <Button variant="contained" color="primary" className={classes.footerButton} onClick={handleNext}>
              Next
            </Button>
          )}
        </div>
      </>
    );
  }
}

InterestsForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InterestsForm);
