import { Grid, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DialogComponent from '../DialogComponent';
import { getMp3ByIdArray } from '../../Services/mp3ServiceClient';
import "./style.scss";

const ViewInterestMp3List = (props) => {
  const { audioList, name } = props;
  const [viewInterest, setViewInterest] = useState(false);
  const [musicList, setMusicList] = useState([]);

  const toggle = (e) => {
    e.preventDefault();

    if (!viewInterest) {
      getMp3ByIdArray(audioList)
        .then((response) => {
          const { data } = response;
          setMusicList(data);
        })
        .catch(() => {
          setMusicList([]);
        });
    }
    setViewInterest(!viewInterest);
  };

  if (!audioList || audioList.length === 0) {
    return null;
  }

  const body =
    musicList.length > 0 &&
    musicList.map((audioListItem) => {
      return (
        <Grid item xs={12} sm={12} key={audioListItem._id}>
          {audioListItem.description}
        </Grid>
      );
    });
  return (
    <>
      <IconButton onClick={toggle} title="View tagged audios" className="view-mp3-icon">
        <VisibilityIcon fontSize="small"/>
      </IconButton>
      <DialogComponent body={body} header={name} open={viewInterest} onClose={toggle} fullWidth={false} maxWidth="md" />
    </>
  );
};

export default ViewInterestMp3List;
