import types from './types';

export const setFileList = (value) => ({
  type: types.SET_FILE_LIST,
  value,
});

export const getFileList = () => ({
  type: types.GET_FILE_LIST,
});

export const syncFilesWithBoxCom = () => ({
  type: types.SYNC_FILES_WITH_BOX_COM,
});

export const getFileData = (value) => ({
  type: types.GET_FILE_DATA,
  value,
});

export const setFileData = (value) => ({
  type: types.SET_FILE_DATA,
  value,
});

export const createFile = (value) => ({
  type: types.CREATE_FILE,
  value,
});

export const deleteFile = (value) => ({
  type: types.DELETE_FILE,
  value,
});

export const updateFile = (value) => ({
  type: types.UPDATE_FILE,
  value,
});

export default {
  setFileList,
  getFileList,
  syncFilesWithBoxCom,
  getFileData,
  setFileData,
  createFile,
  deleteFile,
  updateFile,
};
