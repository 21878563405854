import React, { useState } from 'react';
import CountryList from 'react-select-country-list';
import ReactSelect from 'react-select';
import { useForm } from 'react-hook-form';
import { Button, Grid, TextField, MenuItem, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateUserDetails } from '../../SignIn/redux/actions';
import { HIDE_MATURE_CONTENT, IS_FIRST_RESPONDER, IS_USING_HYPNOSIS } from '../../utils/appConstants';

const PersonalDetails = (props) => {
  const { userDetails: userProps, viewMode } = props;
  const [userDetails, setUserData] = useState(userProps);
  const { register, handleSubmit, errors } = useForm();

  const countries = CountryList().getData();
  const currentYear = new Date().getFullYear();
  const yearList = Array.from(new Array(20), (val, index) => index + currentYear);

  const handleChange = async (event) => {
    const { name, value, type } = event.target;
    if (type === 'checkbox') {
      setUserData({ ...userDetails, [name]: event.target.checked });
      return;
    }
    setUserData({ ...userDetails, [name]: value });
  };
  const handleCountrySelect = (selected) => {
    setUserData({ ...userDetails, country: selected.value });
  };

  const onSubmit = () => {
    const { updateUserData } = props;
    userDetails.roles = [userDetails.roles];
    userDetails.username = userDetails.email;
    const interestList = [...userDetails.selectedInterests];
    let interestsChanged = false;
    userDetails.selectedInterests = interestList.map((interestItem, index) => {
      if (interestItem.id !== userProps.selectedInterests[index].id) {
        interestsChanged = true;
      }
      return interestItem._id;
    });
    if (interestsChanged) {
      userDetails.sessionIndex = 0;
      userDetails.interestPlayingOrder = [];
    }
    if (userDetails.age < 18) {
      userDetails.hideMatureContent = true;
    }
    updateUserData(userDetails);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="view-update-user-form">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            size="small"
            id="firstName"
            name="firstName"
            label="First name"
            value={userDetails.firstName}
            fullWidth
            disabled={viewMode}
            autoComplete="first-name"
            inputRef={register({ required: 'First name Required.', maxLength: { value: 80, message: 'First name must be less than 80 characters.' } })}
            error={!!errors.firstName}
            helperText={errors.firstName && errors.firstName.message}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            variant="outlined"
            size="small"
            name="lastName"
            label="Last name"
            value={userDetails.lastName}
            fullWidth
            disabled={viewMode}
            autoComplete="last-name"
            inputRef={register({ required: 'Last name Required.', maxLength: { value: 100, message: 'First name must be less than 100 characters.' } })}
            error={!!errors.lastName}
            helperText={errors.lastName && errors.lastName.message}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            size="small"
            fullWidth
            disabled={true}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email-new"
            value={userDetails.email}
            inputRef={register({ required: 'Email Address Required', pattern: { value: /^\S+@\S+$/i, message: 'Enter valid Email Address.' } })}
            error={!!errors.email}
            onChange={handleChange}
            helperText={errors.email && errors.email.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            size="small"
            fullWidth
            disabled={viewMode}
            name="password"
            label="Password"
            type="password"
            id="password"
            error={!!errors.password}
            value={userDetails.password}
            autoComplete="current-password-new"
            inputRef={register({
              required: 'Password is Required.',
              min: { value: 8, message: 'Password must be at least 8 letters.' },
              pattern: { value: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i, message: 'Password should contain Special character, one Capital and one number.' },
            })}
            helperText={errors.password && errors.password.message}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            variant="outlined"
            size="small"
            name="address1"
            label="Address line 1"
            fullWidth
            disabled={viewMode}
            value={userDetails.address1}
            error={!!errors.address1}
            autoComplete="shipping address-line1"
            inputRef={register({ required: 'Address is Required.', maxLength: { value: 100, message: 'Address must be less than 100 characters' } })}
            onChange={handleChange}
            helperText={errors.address1 && errors.address1.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            variant="outlined"
            size="small"
            name="address2"
            label="Address line 2"
            fullWidth
            value={userDetails.address2}
            disabled={viewMode}
            autoComplete="shipping address-line2"
            inputRef={register({ maxLength: { value: 100, message: 'Address must be less than 100 characters' } })}
            helperText={errors.address2 && errors.address2.message}
            error={!!errors.address2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            variant="outlined"
            size="small"
            name="city"
            label="City"
            fullWidth
            value={userDetails.city}
            disabled={viewMode}
            autoComplete="shipping address-level2"
            inputRef={register({ required: 'City is Required.', maxLength: { value: 100, message: 'City must be less than 100 characters' }, pattern: { value: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i, message: 'Enter Valid City.' } })}
            helperText={errors.city && errors.city.message}
            error={!!errors.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="state"
            variant="outlined"
            size="small"
            name="state"
            label="State/Province/Region"
            value={userDetails.state}
            inputRef={register({ required: 'State/Province/Region is Required.', maxLength: { value: 100, message: 'State must be less than 100 characters' }, pattern: { value: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i, message: 'Enter Valid State.' } })}
            helperText={errors.state && errors.state.message}
            error={!!errors.state}
            fullWidth
            disabled={viewMode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            variant="outlined"
            size="small"
            name="zip"
            label="Zip / Postal code"
            value={userDetails.zip}
            inputRef={register({ required: 'Zip / Postal code is Required.', maxLength: { value: 10, message: 'Zip / Postal code must be less than 10 characters' } })}
            helperText={errors.zip && errors.zip.message}
            error={!!errors.zip}
            fullWidth
            disabled={viewMode}
            autoComplete="shipping postal-code"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="flex-end">
          <ReactSelect
            options={countries}
            classNamePrefix="select"
            value={{ label: CountryList().getLabel(userDetails.country || ''), value: userDetails.country }}
            id="country"
            className="country-select"
            isDisabled={viewMode}
            helperText={errors.country && errors.country.message}
            error={!!errors.country}
            onChange={handleCountrySelect}
            name="country"
            placeholder="Select Country"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="timezone"
            variant="outlined"
            value={userDetails.timeZone}
            size="small"
            name="timeZone"
            inputRef={register}
            helperText={errors.timeZone && errors.timeZone.message}
            error={!!errors.timeZone}
            label="Time Zone"
            fullWidth
            disabled={viewMode}
            autoComplete="time zone"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="cellphone"
            required
            variant="outlined"
            size="small"
            type="tel"
            name="cellPhone"
            value={userDetails.cellPhone}
            inputRef={register({ required: 'Cell Phone is required.', maxLength: { value: 15, message: 'Cell phone must be less than 15 numbers.' } })}
            helperText={errors.cellPhone && errors.cellPhone.message}
            error={!!errors.cellPhone}
            label="Cell Phone"
            fullWidth
            disabled={viewMode}
            autoComplete="cell phone"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="workhours"
            variant="outlined"
            value={userDetails.workHours}
            size="small"
            name="workHours"
            inputRef={register}
            helperText={errors.workHours && errors.workHours.message}
            error={!!errors.workHours}
            label="Best time(s) Reached"
            fullWidth
            disabled={viewMode}
            autoComplete="Best time(s) Reached"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="workphone"
            type="tel"
            variant="outlined"
            size="small"
            value={userDetails.workPhone}
            name="workPhone"
            inputRef={register({ maxLength: { value: 12, message: 'Work phone must be less than 12 numbers.' } })}
            helperText={errors.workPhone && errors.workPhone.message}
            error={!!errors.workPhone}
            label="Work Phone"
            fullWidth
            disabled={viewMode}
            autoComplete="work phone"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="age"
            type="number"
            variant="outlined"
            size="small"
            name="age"
            label="Age"
            value={userDetails.age}
            inputRef={register({ required: 'Age is required.' })}
            helperText={errors.age && errors.age.message}
            error={!!errors.age}
            fullWidth
            disabled={viewMode}
            autoComplete="Age"
            onChange={handleChange}
          />
        </Grid>
        {+userDetails.age >= 18 && (
          <Grid item xs={12}>
            <FormControl>
              <FormControlLabel label={HIDE_MATURE_CONTENT} control={<Checkbox name="hideMatureContent" id="hideMatureContent" checked={userDetails.hideMatureContent} inputProps={{ 'aria-label': HIDE_MATURE_CONTENT }} />} onChange={handleChange} />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            select
            size="small"
            id="relationshipStatus"
            value={userDetails.relationshipStatus}
            inputRef={register}
            helperText={errors.relationshipStatus && errors.relationshipStatus.message}
            error={!!errors.relationshipStatus}
            name="relationshipStatus"
            label="Relationship Status"
            fullWidth
            disabled={viewMode}
            autoComplete="Relationship Status"
            onChange={handleChange}
          >
            <MenuItem value="">--Select--</MenuItem>
            <MenuItem value="Single">Single</MenuItem>
            <MenuItem value="Married">Married</MenuItem>
            <MenuItem value="Relationship">In a Relationship</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl>
            <FormControlLabel label={IS_FIRST_RESPONDER} control={<Checkbox name="isFirstResponder" id="isFirstResponder" checked={userDetails.isFirstResponder} inputProps={{ 'aria-label': IS_FIRST_RESPONDER }} />} onChange={handleChange} />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField variant="outlined" size="small" fullWidth name="occupation" label="Occupation" type="text" id="occupation" autoComplete="occupation" onChange={handleChange} error={!!errors.occupation} value={userDetails.occupation} inputRef={register()} />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel label={IS_USING_HYPNOSIS} control={<Checkbox name="isUsingHypnosis" id="isUsingHypnosis" checked={userDetails.isUsingHypnosis} inputProps={{ 'aria-label': IS_USING_HYPNOSIS }} />} onChange={handleChange} />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            select
            variant="outlined"
            size="small"
            id="sex"
            value={userDetails.sex}
            name="sex"
            inputRef={register({ required: 'Gender is required.' })}
            helperText={errors.sex && errors.sex.message}
            error={!!errors.sex}
            label="Sex"
            fullWidth
            disabled={viewMode}
            autoComplete="Sex"
            onChange={handleChange}
          >
            <MenuItem value="">--Select--</MenuItem>
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            size="small"
            id="incomeGoal"
            inputRef={register}
            helperText={errors.incomeGoal && errors.incomeGoal.message}
            error={!!errors.incomeGoal}
            name="incomeGoal"
            value={userDetails.incomeGoal}
            label="Your income goal is:"
            fullWidth
            autoComplete="incomeGoal"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            select
            size="small"
            id="inYear"
            name="inYear"
            value={userDetails.inYear}
            inputRef={register}
            helperText={errors.inYear && errors.inYear.message}
            error={!!errors.inYear}
            label="Year you want to reach your goal:"
            fullWidth
            autoComplete="inYear"
            onChange={handleChange}
          >
            {yearList.map((year, index) => {
              return (
                <MenuItem key={`year${index}`} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            select
            size="small"
            id="wouldLikeToEarn"
            inputRef={register}
            helperText={errors.wouldLikeToEarn && errors.wouldLikeToEarn.message}
            error={!!errors.wouldLikeToEarn}
            name="wouldLikeToEarn"
            value={userDetails.wouldLikeToEarn}
            label="This is _____ your current income"
            fullWidth
            autoComplete="wouldLikeToEarn"
            onChange={handleChange}
          >
            <MenuItem value="">--Select--</MenuItem>
            <MenuItem value="Modest Increase">Modest Increase</MenuItem>
            <MenuItem value="Doubling">Doubling</MenuItem>
            <MenuItem value="Tripling">Tripling</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="reference"
            type="text"
            value={userDetails.reference}
            variant="outlined"
            size="small"
            name="reference"
            label="How did you find us?"
            inputRef={register()}
            helperText={errors.reference && errors.reference.message}
            error={!!errors.reference}
            fullWidth
            autoComplete="reference"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} justify="flex-end">
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </Grid>
    </form>
  );
};
const mapStateToProps = (state) => ({ authenticated: state.authentication.authenticated, userDetails: state.authentication.userDetails });
const mapDispatchToProps = (dispatch) => ({
  updateUserData: (data) => dispatch(updateUserDetails(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalDetails));
