import types from './types';

export const startLoader = () => ({
  type: types.START_LOADER,
});
export const stopLoader = () => ({
  type: types.STOP_LOADER,
});
export const setProgressBarValue = (value) => ({
  type: types.SET_PROGRESS_BAR_VALUE,
  value,
});
export default {
  startLoader,
  stopLoader,
  setProgressBarValue,
};
