export const reMask = (re) => new RegExp(re);
export const PRODUCT_NAME = 'Reach for the Stars';
export const IS_FIRST_RESPONDER = 'I am a first responder or in the healthcare industry (to receive custom audio).';
export const IS_USING_HYPNOSIS = 'I am interested in building my private hypnotherapy, coaching, or healing practice (to receive custom audio).';
export const IS_USING_POLYAMORY = 'Would you like to have recordings related to polyamory?';
export const HIDE_MATURE_CONTENT = 'Include Mature and Adult content';
export const IS_MATURE_CONTENT = 'is this mature/adult content?';
export const IS_FOR_POLYAMORY = 'is this related to polyamory?';
export const IS_FOR_HYPNOSIS = 'is this building your practice?';
export const UPGRADE_TO_ANY_PAID_MEMBERSHIP = 'To start listening to tailored audios based on your priorities, please upgrade to any paid membership.';
export const UPGRADE_TO_SILVER_OR_PLATINUM_PAID_MEMBERSHIP = 'Upgrade to Silver or Platinum Membership to start listening to your priority based audio list whenever you wish.';
export const UPGRADE_TO_PLATINUM_PAID_MEMBERSHIP = 'Our complete set of guided meditation audios. Upgrade to Platinum Membership to choose among any or all of these and listen to them whenever you wish.';

// export const freePlaylist = process.env.NODE_ENV === 'development' ? ['5f7dc85e7c52a1104013b927', '5ff6f7a1a6221e4d50604a85'] : ['5f20647928ee3266850e6a78', '604a0ff24828a4394bac5b8c'];
export const freePlaylist = (isFirstResponder, isUsingHypnosis) => {
  const t18 = '5f20647928ee3266850e6a78';
  const t19 = '60cc1c34eeb4157c79ec4ae9';
  const t54b = '5fed7346e55c517b5ed08b04';
  const t54 = '5f20647928ee3266850e6a88';
  if (isFirstResponder && isUsingHypnosis) {
    return [t19, t54b];
  } else if (isFirstResponder) {
    return [t18, t54b];
  } else if (isUsingHypnosis) {
    return [t19, t54];
  } else {
    return [t18, t54];
  }
};
export const buildTypeConstants = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  STAGING: 'staging',
  TESTING: 'test',
};
export const errorMessages = {
  LOGIN_ERROR: 'There is some error while Logging in. Please check to make sure email address and password typed in correctly.',
};
export const startingMusicName = 'RFTS_starting_music.mp3';
