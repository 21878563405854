import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setShowPlayer, getPlayList, getNextSession, getAllMusic, getUserInterestPlaylist, setPlaylistIndex, setMusicPlayerOptions, setPerSessionMp3 } from '../Redux/actions';
import { Container, Typography, Button, Grid, ButtonGroup, Tooltip, TextField, MenuItem } from '@material-ui/core';
import './style.scss';
import { getUserDetails } from '../../SignIn/redux/actions';
import MaterialDataTable from '../../components/MaterialDataTable';
import { startingMusicName, UPGRADE_TO_ANY_PAID_MEMBERSHIP, UPGRADE_TO_PLATINUM_PAID_MEMBERSHIP, UPGRADE_TO_SILVER_OR_PLATINUM_PAID_MEMBERSHIP } from '../../utils/appConstants';
import PlayingInstructions from '../PlayingInstructions';
import UpgradePopup from '../../components/UpgradePopup';
import MusicListItem from '../../components/MusicListItem';
import InfoPopover from '../../components/InfoPopover';
import PlaylistInformation from '../../components/PlaylistInformation';
import NotActiveSubscription from '../../components/NotActiveSubscription';

class Mp3List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      showUpgrade: false,
      instructionsOpen: false,
      showNotActiveModal: false,
    };
  }

  componentDidMount() {
    const { getUser, userId } = this.props;
    getUser(userId);
    this.setCurrentPlaylist(0);
  }

  toggleShowActiveModal = () => this.setState({ showNotActiveModal: !this.state.showNotActiveModal });

  toggleUpgradePopup = () => this.setState({ showUpgrade: !this.state.showUpgrade });

  toggleInstructions = () => this.setState({ instructionsOpen: !this.state.instructionsOpen });

  setAnchorEl = (anchorEl) => this.setState({ anchorEl });

  handlePopoverClick = (event) => {
    this.setAnchorEl(event.currentTarget);
  };

  handlePopoverClose = () => {
    this.setAnchorEl(null);
  };

  setCurrentPlaylist = (playlistIndex = 0) => {
    const { getAllMusicList, getMusicList, getInterestPlaylist, setPlaylistIndex } = this.props;
    setPlaylistIndex(playlistIndex);
    if (playlistIndex === 2) {
      getAllMusicList();
    } else if (playlistIndex === 1) {
      getInterestPlaylist();
    } else {
      getMusicList();
    }
  };

  startPlayingMusic = (music, isFree = false) => {
    const { playList, updateMusicParams, playMusic, musicPlayerOptions, stopMusic, freePlaylist, isHavingActiveSubscription } = this.props;
    stopMusic();
    let list = [];
    if (!isHavingActiveSubscription) {
      this.toggleShowActiveModal();
      return;
    }
    if (isFree) {
      list = freePlaylist;
      if (music) {
        this.toggleUpgradePopup();
      } else {
        updateMusicParams({ ...musicPlayerOptions, audioLists: list, playIndex: 0 });
        setTimeout(() => playMusic(), 0);
      }
    } else {
      list = music ? [music] : playList;
      updateMusicParams({ ...musicPlayerOptions, audioLists: list, playIndex: 0 });
      setTimeout(() => playMusic(), 0);
    }
  };

  getTitle = (isFree) => {
    const { userDetails, playlistIndex, setPerSessionAudios } = this.props;
    return (
      playlistIndex === 0 && (
        <div className="mp3-list-title">
          <span>{userDetails.sessionIndex ? `Session ${userDetails.sessionIndex}` : `Session 1`}</span>
          <div className="perSessionMp3-container">
            <TextField variant="outlined" onChange={(e) => setPerSessionAudios(e.target.value)} disabled={isFree} size="small" defaultValue={userDetails.perSessionMp3 || '2'} select id="perSessionMp3" name="perSessionMp3" label="Mp3s per session" fullWidth autoComplete="perSessionMp3">
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="1">1</MenuItem>
            </TextField>
          </div>
        </div>
      )
    );
  };

  getUpgradeText = (isFree, isBronze, isSilver) => {
    const { playlistIndex } = this.props;
    if (playlistIndex === 0 && isFree) {
      return UPGRADE_TO_ANY_PAID_MEMBERSHIP;
    } else if (playlistIndex === 1 && (isBronze || isFree)) {
      return UPGRADE_TO_SILVER_OR_PLATINUM_PAID_MEMBERSHIP;
    } else if (playlistIndex === 2 && (isSilver || isBronze || isFree)) {
      return UPGRADE_TO_PLATINUM_PAID_MEMBERSHIP;
    } else {
      return '';
    }
  };

  getMusicList = (playList, isFree = false) => {
    if (!playList || playList.length === 0) {
      return [];
    }
    const { playlistIndex } = this.props;
    return playList
      .filter((mp3Item) => {
        const { name } = mp3Item;

        return name !== startingMusicName;
      })
      .map((mp3Item) => {
        const playButton = playlistIndex !== 0 ? <Button onClick={() => this.startPlayingMusic(mp3Item, isFree)}>Play</Button> : '';
        return { description: <MusicListItem {...mp3Item} />, playButton };
      });
  };

  render() {
    const { playList, userDetails, playlistIndex, freePlaylist, isHavingActiveSubscription } = this.props;
    const { instructionsOpen, showUpgrade, showNotActiveModal } = this.state;
    const headers = [
      { title: 'Music', field: 'description' },
      { title: '', width: '5%', field: 'playButton' },
    ];
    const isBronze = userDetails.subscription === 'Bronze';
    const notBronze = !isBronze;
    const isSilver = userDetails.subscription === 'Silver';
    const isPlatinum = userDetails.subscription === 'Platinum';
    const isFree = userDetails.subscription === 'Free';
    const data = this.getMusicList(playList, isFree);
    const freeDataList = isFree ? this.getMusicList(freePlaylist) : [];
    const title = this.getTitle(isFree);

    return (
      <Container className="mp3-list-container" maxWidth="md" component="main">
        <Grid container justify="center" alignItems="center">
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className="page-title">
            Playlist
          </Typography>
          <InfoPopover>
            <PlaylistInformation isSilver={isSilver} isPlatinum={isPlatinum} isFree={isFree} />
          </InfoPopover>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Tooltip title="Click to start meditation session">
            <Button variant="contained" color="primary" onClick={() => this.startPlayingMusic(null, isFree)} className="start-playing">
              Start Playing
            </Button>
          </Tooltip>
          <Button variant="text" color="primary" onClick={this.toggleInstructions} className="playing-instruction-button">
            Playing Instructions
          </Button>
        </Grid>
        {notBronze && (
          <Grid container justify="center" className="playlist-tabs">
            <Grid item>
              <ButtonGroup variant="outlined" color="primary">
                <Button onClick={() => this.setCurrentPlaylist(0)} tabindex="0" className={{ active: playlistIndex === 0 }}>
                  Meditation Session
                </Button>
                {(isSilver || isPlatinum || isFree) && (
                  <Button onClick={() => this.setCurrentPlaylist(1)} className={{ active: playlistIndex === 1 }} tabindex="1">
                    Meditation Playlist
                  </Button>
                )}
                {(isPlatinum || isFree) && (
                  <Button onClick={() => this.setCurrentPlaylist(2)} className={{ active: playlistIndex === 2 }} tabindex="2">
                    Meditation Library
                  </Button>
                )}
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
        {isFree && <UpgradePopup open={showUpgrade} toggle={this.toggleUpgradePopup} subscription={userDetails.subscription} />}
        <PlayingInstructions open={instructionsOpen} toggle={this.toggleInstructions} />
        {freeDataList.length > 0 && playlistIndex === 0 && <MaterialDataTable headers={headers} data={freeDataList} className="mp3-list free-data-list" />}
        {isFree && (
          <div className="upgrade-section">
            <Typography component="h5" variant="h5" align="center" color="textPrimary" gutterBottom className="upgrade-text">
              {this.getUpgradeText(isFree, isBronze, isSilver)}
            </Typography>
            <div className="upgrade-button-container">
              <Button variant="contained" color="primary" onClick={this.toggleUpgradePopup} className="upgrade-button">
                Upgrade
              </Button>
            </div>
          </div>
        )}
        {!isHavingActiveSubscription && <NotActiveSubscription open={showNotActiveModal} toggle={this.toggleShowActiveModal} />}
        <MaterialDataTable title={title} headers={headers} data={data} className="mp3-list" disableSearch />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  playList: state.musicPlayer.playList,
  userId: state.authentication.userId,
  userDetails: state.authentication.userDetails,
  playlistIndex: state.musicPlayer.playlistIndex,
  musicPlayerOptions: state.musicPlayer.musicPlayerOptions,
  freePlaylist: state.musicPlayer.freePlaylist,
  isHavingActiveSubscription: state.authentication.isHavingActiveSubscription,
});

const mapDispatchToProps = (dispatch) => ({
  getMusicList: () => dispatch(getPlayList()),
  getAllMusicList: () => dispatch(getAllMusic()),
  getInterestPlaylist: () => dispatch(getUserInterestPlaylist()),
  getUser: (userId) => dispatch(getUserDetails(userId)),
  nextSession: () => dispatch(getNextSession()),
  playMusic: () => dispatch(setShowPlayer(true)),
  setPlaylistIndex: (index) => dispatch(setPlaylistIndex(index)),
  updateMusicParams: (value) => dispatch(setMusicPlayerOptions(value)),
  setPerSessionAudios: (value) => dispatch(setPerSessionMp3(+value)),
  stopMusic: () => dispatch(setShowPlayer(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mp3List);
