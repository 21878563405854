import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import rootSaga from './saga';
import { sagaMiddleware, store, history, load } from './reduxStore';
import App from './App';
import { unregister } from './serviceWorker';

import './index.scss';

sagaMiddleware.run(rootSaga);

load(store).then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    document.getElementById('root'),
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
