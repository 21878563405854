import axios from "axios";
import appConfig from "../config/appConfig";

export const axiosCountry = async (url) => {
  let worldToken = sessionStorage.getItem('world_token');
  try {
    if (!worldToken) {
      const requestToken = await axios({
        url: `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/auth/worldAuth`
      });
  
      worldToken = requestToken.data.worldToken;
      sessionStorage.setItem('world_token', worldToken);
    }
  
    const request = await axios({
      url: `https://www.universal-tutorial.com/api${url}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${worldToken}`,
        Accept: 'application/json',
      },
    });
  
    return request.data;
  } catch (err) {
    return [];
  }
};

