import React from 'react';
import PropTypes from 'prop-types';
import InfoPopover from '../InfoPopover';
import DirectionsForT18 from '../DirectionsForT18';
import './style.scss';
import { Chip } from '@material-ui/core';

const MusicListItem = (props) => {
  const { sku, description, shortDescription, cover, played } = props;
  const isDirectionNeeded = sku === 'T-18' || sku === 'T-19';
  return (
    <div className="music-list-item">
      <div className="image-data">
        <img width="50" src={cover} alt="rftsLogo" className="playlist-image" />
      </div>
      <div className="description-data">
        <div className="description">
          <span className="description-text">
            {description}
            {isDirectionNeeded && (
              <InfoPopover>
                <DirectionsForT18 />
              </InfoPopover>
            )}
          </span>
          {played === false && <Chip variant="outlined" color="secondary" label="Yet to be Played" size="small" />}
        </div>
        <div className="sku">{sku}</div>
        <div className="short-description">{shortDescription}</div>
      </div>
    </div>
  );
};

MusicListItem.propTypes = {
  cover: PropTypes.string,
  sku: PropTypes.string,
  description: PropTypes.string,
  shortDescription: PropTypes.string,
  played: PropTypes.bool,
};

export default MusicListItem;
