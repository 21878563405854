import React from 'react';
import { string, array, node, number, bool, func } from 'prop-types';
import MaterialTable from 'material-table';

function MaterialDataTable(props) {
  const { title, headers, data, className, paginate } = props;
  return (
    <div className={className}>
      <MaterialTable
        title={title}
        columns={headers}
        data={data}
        options={{
          paging: paginate,
        }}
      />
    </div>
  );
}

MaterialDataTable.defaultProps = {
  noDataMessage: 'No data available',
  isFetching: false,
  paginate: false,
  isSortActive: false,
  title: '',
};

MaterialDataTable.propTypes = {
  className: string,
  headers: array,
  data: array,
  title: node,
  pageCount: number,
  onPageChange: func,
  noDataMessage: node,
  isFetching: bool,
  onPageSizeChange: func,
  size: number,
  paginate: bool,
  colCount: number,
};

export default MaterialDataTable;
