/* eslint-disable react/prop-types */
import { Box, Typography, Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FadedModal from '../components/Modal';
import MusicPlayer from './MusicPlayer';
import { getPlayList, setShowPlayer, setMusicPlayerOptions, setMp3AsPlayed } from './Redux/actions';

const useStyles = makeStyles({
  boxContainers: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  header: {
    width: '100%',
    color: '#7D7D7D',
    marginBottom: '5px',
  },
  bottom: {
    marginTop: '5px'
  },
  componentPadding: {
    margin: 'auto'
  }
});

const RFTSMusicPlayer = ({
  playList,
  showPlayer,
  destroyPlayer,
  playlistIndex,
  updateMusicParams,
  musicPlayerOptions,
  setPlayed,
  freePlaylist,
  isHavingActiveSubscription,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    destroyPlayer();
  }, []);

  const paramsOptions = {
    ...musicPlayerOptions,
    onBeforeDestroy () {
      setOpen(true);
      return new Promise((_resolve, reject) => {
        reject();
      })
    }
  }

  if (!showPlayer || !isHavingActiveSubscription) {
      return null;
    }
    return (
      <div>
        <MusicPlayer playList={playList} freePlaylist={freePlaylist} destroyPlayer={destroyPlayer} playlistIndex={playlistIndex} params={paramsOptions} updateParams={updateMusicParams} setPlayed={setPlayed} />
        <FadedModal open={open} handleClose={() => setOpen(false)}>
          <Box className={`${classes.boxContainers} ${classes.header}`}>
          <Typography variant='h5' className={classes.componentPadding}>
            Music Player
          </Typography>
          </Box>
          <Typography variant='subtitle1'>
            Do you want to reset the player?
          </Typography>
          <Box className={`${classes.boxContainers} ${classes.bottom}`}>
            <Button
              color='primary'
              className={classes.componentPadding}
              onClick={() => {
                destroyPlayer();
                setOpen(false);
              }}
            >Yes</Button>
            <Button
              color='secondary'
              className={classes.componentPadding}
              onClick={() => setOpen(false)}
            >No</Button>
          </Box>
        </FadedModal>
      </div>
    );
}

const mapStateToProps = (state) => ({
  playList: state.musicPlayer.playList,
  freePlaylist: state.musicPlayer.freePlaylist,
  showPlayer: state.musicPlayer.showPlayer,
  playlistIndex: state.musicPlayer.playlistIndex,
  musicPlayerOptions: state.musicPlayer.musicPlayerOptions,
  isHavingActiveSubscription: state.authentication.isHavingActiveSubscription,
});
const mapDispatchToProps = (dispatch) => ({
  getMusicList: () => dispatch(getPlayList()),
  destroyPlayer: () => dispatch(setShowPlayer(false)),
  updateMusicParams: (value) => dispatch(setMusicPlayerOptions(value)),
  setPlayed: (value) => dispatch(setMp3AsPlayed(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RFTSMusicPlayer);
