import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import SubscriptionInfo from '../SubscriptionInfo';

import './style.scss';

function SubscriptionInfoSection() {
  return (
    <Container maxWidth="md" component="main" className="subscription-info-section">
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className="page-title">
        Subscriptions
      </Typography>
      <SubscriptionInfo />
      <Typography variant="h6" align="justify" color="textPrimary" component="p" className="page-para">
        Recordings are automatically scheduled based on goals and priorities given when signed up. The first recording plays each night as you are going to sleep with a second one automatically playing 2.5 hours after the first and is scheduled according to your interests and priorities.
      </Typography>
      <Typography variant="h6" align="center" color="textPrimary" component="p" className="page-para">
        With three different membership levels, pick the one that fits you today!
      </Typography>
    </Container>
  );
}

export default withRouter(SubscriptionInfoSection);
