import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import './style.scss';
import { KeyboardArrowDown, KeyboardArrowUp, Close } from '@material-ui/icons';

const EditMusicList = (props) => {
  const { playlist, isViewable, musicList, setFileList } = props;
  if (!isViewable) {
    return null;
  }

  const handleFileSelect = (selectedValue, eventData) => {
    const { name } = eventData;
    if (name.includes('fileSelect')) {
      const fileIndex = name.split('fileSelect')[1];
      const fileList = [...playlist];
      fileList[fileIndex] = selectedValue;
      setFileList(fileList);
    }
  };

  const handleOrder = (increase, key) => {
    const fileList = [...playlist];
    const selectedPlaylist = fileList.slice();
    const item = selectedPlaylist.splice(key, 1);
    if (increase) {
      selectedPlaylist.splice(key - 1, 0, item[0]);
    } else {
      selectedPlaylist.splice(key + 1, 0, item[0]);
    }
    setFileList(selectedPlaylist);
  };

  const removeItem = (key) => {
    const fileList = [...playlist];
    fileList.splice(key, 1);
    setFileList(fileList);
  };

  const addMoreAudio = () => {
    const fileList = [...playlist];
    fileList.push({});
    setFileList(fileList);
  };

  return (
    <Grid container className="edit-music-list" spacing={16} alignItems="center">
      <Grid container item xs={12} sm={12} justify="center">
        <Button variant="contained" color="primary" onClick={addMoreAudio}>
          Add more audios
        </Button>
      </Grid>
      <Grid item xs={12} sm={12}>
        <List dense={false}>
          {playlist.map((playlistItem, index) => {
            const listIndex = `fileSelect${index}`;
            const selectedValue = musicList.find((musicItem) => {
              const { value } = playlistItem || {};
              if (value) {
                return musicItem.value === value;
              }
              return musicItem.value === playlistItem;
            });
            return (
              <ListItem key={index}>
                <Grid container justify="center">
                  <Grid item xs={8}>
                    <ListItemText primary={<Select required options={musicList} value={selectedValue} classNamePrefix="select" isClearable={true} id={listIndex} className="react-select" onChange={handleFileSelect} name={listIndex} placeholder="Select audio to set playlist" />} />
                  </Grid>
                  <Grid container item xs={4}>
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => removeItem(index)}>
                        <Close />
                      </IconButton>
                      <IconButton disabled={index === 0} onClick={() => handleOrder(true, index)}>
                        <KeyboardArrowUp />
                      </IconButton>
                      <IconButton disabled={index === playlist.length - 1} onClick={() => handleOrder(false, index)}>
                        <KeyboardArrowDown />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid container item xs={12} sm={12} justify="center">
        <Button variant="contained" color="primary" onClick={addMoreAudio}>
          Add more audios
        </Button>
      </Grid>
    </Grid>
  );
};
EditMusicList.propTypes = {
  playlist: PropTypes.array,
  isEditable: PropTypes.bool,
  isViewable: PropTypes.bool,
  musicList: PropTypes.array,
  setFileList: PropTypes.func,
};

export default EditMusicList;
