import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  mediaCard: {
    maxWidth: 250,
    width: '100%',
    cursor: 'pointer',
  },
  mediaText: {
    fontSize: '1.1rem',
    fontFamily: "'Nunito Sans', sans-serif",
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const { text, title, image, onClick } = props;

  return (
    <Card className={classes.mediaCard} onClick={onClick}>
      <CardMedia className={classes.media} title={title} image={image} />
      <CardContent>
        <Typography variant="body1" component="p" className={classes.mediaText}>
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
}

MediaCard.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.node,
  title: PropTypes.string,
  onClick: PropTypes.func,
};
