import React from 'react';
import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router-dom';
import SubscriptionInfoSection from './SubcriptionInfoSection';
import SubscriptionCards from './SubscriptionCards';
import SubscriptionFooter from './SubscriptionFooter';
import { connect } from 'react-redux';
import { setSubscriptionPlan } from './Redux/actions';

function Subscription(props) {
  const { selectedPlan, selectSubscription } = props;
  window.scrollTo(0, 0);
  return (
    <Container>
      <SubscriptionInfoSection />
      <SubscriptionCards selectedPlan={selectedPlan} selectSubscription={selectSubscription} showSelected={4} showOther={true}/>
      <SubscriptionFooter />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  selectedPlan: state.subscription.selectedSubscription,
});
const mapDispatchToProps = (dispatch) => ({
  selectSubscription: (valueObj) => dispatch(setSubscriptionPlan(valueObj)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Subscription));
