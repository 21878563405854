import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Grow, Paper, Popper, MenuItem, MenuList, Divider, Typography, Button, useMediaQuery } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SIGN_IN, SIGN_UP, PROFILE, USER_INTERESTS } from '../../Routes/routingConstants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser as logoutUserData } from '../../SignIn/redux/actions';
import { bool, func, object, string } from 'prop-types';
import { getBillingSessionUrl } from '../../Services/stripeServiceClient';
import { startLoader, stopLoader } from '../LoaderComponent/Redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  profileName: {
    marginLeft: '5px',
    textTransform: 'capitalize',
  },
  profileMenuPopper: {
    zIndex: 2,
    width: '210px',
    minWidth: '210px',
  },
  profileMenuItem: {
    padding: '16px 25px',
    fontFamily: '"Nunito Sans", sans-serif',
    color: 'inherit',
    '&:hover': {
      color: '#3260B1',
      opacity: 1,
    },
  },
  profileMenuList: {
    padding: 0,
  },
  profileIconContainer: {
    color: 'inherit',
  },
  profileIcon: {
    color: 'inherit',
    fontSize: '2rem',
    '&:hover': {
      color: '#3260B1',
      opacity: 1,
    },
  },
}));

function ProfileHeader(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const theme = useTheme();
  const smDevice = useMediaQuery(theme.breakpoints.up('sm'));
  const { history, logoutUser, stripeCustomerId, startLoader, stopLoader } = props;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = async (event, url = null, action = null) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (action) {
      if (action === 'logout') {
        logoutUser();
      } else if (action === 'payments') {
        startLoader();
        const redirectData = await getBillingSessionUrl(stripeCustomerId);
        if (redirectData) {
          window.open(redirectData.paymentUrl, '_self');
        }
        stopLoader();
      }
      url && history.replace(url);
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const menuList = [
    {
      name: 'Sign In',
      action: 'signIn',
      authNeed: false,
      url: SIGN_IN,
    },
    {
      name: 'Sign Up',
      action: 'signUp',
      authNeed: false,
      url: SIGN_UP,
    },
    {
      name: 'Interests',
      action: 'interests',
      url: USER_INTERESTS,
      authNeed: true,
    },
    {
      name: 'Payments',
      action: 'payments',
      authNeed: true,
    },
    {
      name: 'Profile',
      action: 'profile',
      url: PROFILE,
      authNeed: true,
    },
    {
      name: 'Logout',
      action: 'logout',
      authNeed: true,
    },
  ];

  const { authenticated = false, profileName = '' } = props;

  return (
    <>
      <Button size="medium" className={classes.profileIconContainer} ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle} label="">
        <AccountCircleIcon className={classes.profileIcon} />
        {smDevice && (
          <Typography component="text" variant="body1" className={classes.profileName}>
            {profileName}
          </Typography>
        )}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition placement={'bottom-end'} disablePortal className={classes.profileMenuPopper}>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} className={classes.profileMenuList}>
                  {menuList.map((menuItem, index, ar) => {
                    if (menuItem.authNeed !== authenticated) {
                      return null;
                    }
                    if (menuItem.action === 'payments' && !stripeCustomerId) {
                      return null;
                    }
                    return (
                      <>
                        <MenuItem onClick={(event) => handleClose(event, menuItem.url, menuItem.action)} className={classes.profileMenuItem}>
                          {menuItem.name}
                        </MenuItem>
                        {index < ar.length - 1 && <Divider />}
                      </>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

ProfileHeader.propTypes = {
  authenticated: bool,
  profileName: string,
  history: object,
  logoutUser: func,
  stripeCustomerId: string,
  startLoader: func,
  stopLoader: func,
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  stripeCustomerId: state.authentication.userDetails.stripeCustomerId,
  profileName: state.authentication.name,
});
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUserData()),
  startLoader: () => dispatch(startLoader()),
  stopLoader: () => dispatch(stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileHeader));
