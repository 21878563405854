import subscriptionTypes from '../Subscription/Redux/types';
import { subscriptions } from '../Subscription/subscriptions';

const INITIAL_STATE = {
  selectedSubscription: subscriptions[0],
};

const reducerHandler = (action, state = INITIAL_STATE) => {
  const reducerObject = {
    [subscriptionTypes.SET_SUBSCRIPTION_PLAN]: () => ({
      ...state,
      selectedSubscription: action.value,
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const subscription = (state, action) => reducerHandler(action, state);

export default subscription;
