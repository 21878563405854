import React, { Component } from 'react';
import { Container, Typography, Button, Grid } from '@material-ui/core';
import InterestList from './InterestList';
import { connect } from 'react-redux';
import { getInterestList, getInterestData, deleteInterest, createInterest, updateInterest, setInterestData } from './Redux/actions';
import { getFileList } from '../FileManagement/Redux/actions';
import './style.scss';

import CreateNewInterest from './CreateNewInterest';
import ViewUpdateInterest from './ViewUpdateInterest';
import { isAdmin } from '../../utils/stringUtils';

class InterestManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewInterestData: false,
      createNewInterest: false,
      viewMode: true,
    };
  }
  componentDidMount() {
    const { getInterests, getFiles } = this.props;
    getInterests();
    getFiles();
  }

  toggleViewInterestDataModal = () => {
    const { viewInterestData } = this.state;
    const { setViewInterestData } = this.props;
    if (viewInterestData) {
      setViewInterestData(null);
    }
    this.setState({ viewInterestData: !viewInterestData });
  };

  setViewMode = (value) => this.setState({ viewMode: value });

  toggleCreateNewInterestModal = () => {
    const { createNewInterest } = this.state;
    this.setState({ createNewInterest: !createNewInterest });
  };

  viewInterestHandler = (interestId, viewMode = true) => {
    const { viewInterestData } = this.props;
    viewInterestData(interestId);
    this.setViewMode(viewMode);
    this.toggleViewInterestDataModal();
  };

  render() {
    const { viewInterestData, createNewInterest, viewMode } = this.state;
    const { interestList, interestData, deleteInterestData, createInterestData, updateInterestData, fileList, authRoles } = this.props;
    const isAdminRole = isAdmin(authRoles);
    const interestListProps = {
      interestList,
      deleteInterestData,
      isAdminRole,
      viewInterestHandler: this.viewInterestHandler,
    };
    return (
      <Container className="interest-management-container" maxWidth="md" component="main">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className="page-title">
          Interests
        </Typography>
        {isAdminRole && (
          <Grid container justify="center" className="interest-management-actions-container">
            <Button variant="contained" color="primary" onClick={this.toggleCreateNewInterestModal}>
              Add New Interest
            </Button>
          </Grid>
        )}
        <InterestList {...interestListProps} />
        {viewInterestData && interestData && <ViewUpdateInterest toggle={this.toggleViewInterestDataModal} viewMode={viewMode} open={viewInterestData} interestProps={interestData} updateInterestData={updateInterestData} musicList={fileList} />}
        {createNewInterest && <CreateNewInterest toggle={this.toggleCreateNewInterestModal} open={createNewInterest} createInterestData={createInterestData} musicList={fileList} />}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  interestList: state.interestManagement.interestList || [],
  interestData: state.interestManagement.interestData,
  authRoles: state.authentication.roles,
  fileList: state.fileManagement.fileList,
});
const mapDispatchToProps = (dispatch) => ({
  getInterests: () => dispatch(getInterestList()),
  getFiles: () => dispatch(getFileList()),
  viewInterestData: (interestId) => dispatch(getInterestData(interestId)),
  setViewInterestData: (data) => dispatch(setInterestData(data)),
  deleteInterestData: (interestId) => dispatch(deleteInterest(interestId)),
  createInterestData: (data) => dispatch(createInterest(data)),
  updateInterestData: (data) => dispatch(updateInterest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterestManagement);
