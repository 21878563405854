import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import StripePaymentComponent from '../components/StripePaymentComponent';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function PaymentForm(props) {
  const classes = useStyles();
  const { handleNext, activeStep, handleBack, steps, detailProps } = props;
  const {
    userDetails: { subscription: { amount, title: subscriptionName } = {} },
  } = detailProps;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        You are subscribing for {subscriptionName} membership.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Amount to be paid: {amount}$
      </Typography>
      <Grid container xs={12}>
        <StripePaymentComponent handleNext={handleNext} detailProps={detailProps} withAddress />
      </Grid>
      <div className={classes.buttons}>
        {activeStep !== steps[0] && (
          <Button onClick={handleBack} className={classes.button}>
            Back
          </Button>
        )}
        {activeStep !== steps[steps.length - 1] && (
          <Button variant="contained" color="primary" className={classes.button} onClick={handleNext}>
            Next
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

PaymentForm.propTypes = {
  handleNext: PropTypes.func,
  activeStep: PropTypes.string,
  handleBack: PropTypes.func,
  steps: PropTypes.array,
  detailProps: PropTypes.object,
};

export default PaymentForm;
