import React, { useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { element } from 'prop-types';

const InfoPopover = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const { children } = props;
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;
  return (
    <>
      <IconButton aria-describedby={popoverId} variant="contained" color="default" onClick={handlePopoverClick}>
        <InfoIcon />
      </IconButton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="page-popover">
            {children}
        </div>
      </Popover>
    </>
  );
};
InfoPopover.propTypes = {
    children: element
}

export default InfoPopover;
