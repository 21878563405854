import types from '../AdminPanel/SalesLeadManagement/Redux/types';
const INITIAL_STATE = {
  salesLeadList: [],
  salesLeadUserData: null,
};

const reducerHandler = (action, state = INITIAL_STATE) => {
  const reducerObject = {
    [types.SET_SALES_LEAD_LIST]: () => ({
      ...state,
      salesLeadList: action.value,
    }),
    [types.SET_SALES_LEAD_USER_DATA]: () => ({
      ...state,
      salesLeadUserData: action.value,
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const salesLeadManagement = (state, action) => reducerHandler(action, state);

export default salesLeadManagement;
