import React, { useState } from 'react';
import classnames from 'classnames';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Avatar, Button, TextField, Grid, Typography } from '@material-ui/core';
import { SIGN_IN } from '../Routes/routingConstants';
import { withRouter } from 'react-router-dom';
import { sendResetPasswordLink } from '../Services/userManagementServiceClient';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  navigationLink: {
    color: 'blue',
    cursor: 'pointer',
  },
}));

const ForgotPassword = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');

  const gotoLink = (url) => {
    const { history } = props;
    history.push(url);
  };

  const handleChange = (event) => {
    if (!event) {
      return;
    }
    const { value } = event.target;
    setEmail(value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    if (email) {
      sendResetPasswordLink(email).then((response) => {
        toast.success(response.message);
      }).catch((err)=>{
        toast.error(err.data.message);
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs" className={classnames('sign-in-container', classes.paper)}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Forgot password?
      </Typography>
      <form className={classes.form} noValidate onSubmit={submitHandler}>
        <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus onChange={handleChange} />
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Send reset password link
        </Button>
        <Grid container>
          <Grid item xs>
            {/* <Typography component="text" variant="body1" onClick={() => gotoLink(FORGOT_PASSWORD)} className={classes.navigationLink}>
              Forgot password?
            </Typography> */}
          </Grid>
          <Grid item>
            <Typography component="text" variant="body1" className={classes.navigationLink} onClick={() => gotoLink(SIGN_IN)}>
              Back to Sign In
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default withRouter(ForgotPassword);
