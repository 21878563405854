import React from 'react';
import PropTypes from 'prop-types';

const InterestListItem = (props) => {
  const { item, showAudios } = props;
  const { audioList } = item;
  const musicList = (showAudios && audioList && audioList.length > 0) && (
    <ul className="music-list">
      {audioList.map((audioListItem) => (
        <li key={audioListItem._id}>{audioListItem.description}</li>
      ))}
    </ul>
  );
  return (
    <div className="interest-list-item">
      {item.name}
      {musicList}
    </div>
  );
};

InterestListItem.propTypes = {
  item: PropTypes.object,
  showAudios: PropTypes.bool,
};

export default InterestListItem;
