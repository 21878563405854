import React, { useState } from 'react';
import { Button, Grid, TextField, MenuItem, FormControlLabel, FormControl, Checkbox } from '@material-ui/core';
import CountryList from 'react-select-country-list';
import ReactSelect from 'react-select';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import DialogComponent from '../../../components/DialogComponent';
import EditMusicList from '../UserMusicList/EditMusicList';

import './style.scss';
import { HIDE_MATURE_CONTENT, IS_FIRST_RESPONDER, IS_USING_HYPNOSIS } from '../../../utils/appConstants';

const CreateNewUser = (props) => {
  const { toggle, open, musicList, moderatorList } = props;
  const [userDetails, setUserData] = useState({
    address: '',
    city: '',
    state: '',
    zip: '',
    timezone: '',
    cellphone: '',
    workPhone: '',
    workHour: '',
    homePhone: '',
    homeHour: '',
    age: '',
    subscription: { amount: '5', title: 'Bronze' },
    status: '',
    sex: '',
    roles: 'user',
    sexuality: '',
    study: '',
    iam: '',
    wouldLikeToEarn: '',
    inYear: '',
    email: '',
    password: '',
    playlist: [],
    selectedInterests: [],
    paymentBypassed: true,
  });

  const { register, handleSubmit, errors } = useForm();
  const countries = CountryList().getData();
  const currentYear = new Date().getFullYear();
  const yearList = Array.from(new Array(20), (val, index) => index + currentYear);
  const header = 'Create New User';

  const handleChange = async (event) => {
    const { name, value, type } = event.target;
    if (type === 'checkbox') {
      setUserData({ ...userDetails, [name]: event.target.checked });
      return;
    }
    setUserData({ ...userDetails, [name]: value });
  };

  const setFileList = (fileList) => {
    setUserData({ ...userDetails, playlist: fileList });
  };

  const handleCountrySelect = (selected) => {
    setUserData({ ...userDetails, country: selected.value });
  };

  const handleModeratorSelect = (selected) => {
    setUserData({ ...userDetails, moderator: selected.value });
  };
  const adminOrModerator = () => {
    const adminRoleArray = ['admin', 'moderator'];
    return adminRoleArray.includes(userDetails.roles);
  };

  const onSubmit = () => {
    const { createUserData } = props;
    if (adminOrModerator()) {
      userDetails.subscription = '';
    }
    userDetails.playlist = userDetails.playlist.filter((playlistItem) => !!playlistItem);
    userDetails.roles = [userDetails.roles];
    userDetails.username = userDetails.email;
    createUserData(userDetails);
    toggle();
  };

  const isPlatinumSubscription = userDetails.subscription.title ? userDetails.subscription.title.toLowerCase() === 'platinum' : userDetails.subscription.toLowerCase() === 'platinum';

  const body = (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="create-user-form">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            variant="outlined"
            size="small"
            id="roles"
            inputRef={register({ required: 'Role selection is Required.' })}
            helperText={errors.roles && errors.roles.message}
            error={!!errors.roles}
            name="roles"
            defaultValue={userDetails.roles}
            label="Select Role"
            fullWidth
            autoComplete="Role"
            onChange={handleChange}
          >
            <MenuItem value="">--Select--</MenuItem>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="moderator">Moderator</MenuItem>
          </TextField>
        </Grid>
        {!adminOrModerator() && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                variant="outlined"
                size="small"
                id="subscription"
                inputRef={register({ required: 'Subscription selection is Required.' })}
                helperText={errors.subscription && errors.subscription.message}
                error={!!errors.subscription}
                name="subscription"
                defaultValue={userDetails.subscription}
                label="Select Subscription"
                fullWidth
                autoComplete="subscription"
                onChange={handleChange}
              >
                <MenuItem value="">--Select--</MenuItem>
                <MenuItem value="Free">Free</MenuItem>
                <MenuItem value="Platinum">Platinum</MenuItem>
                <MenuItem value="Silver">Silver</MenuItem>
                <MenuItem value="Bronze">Bronze</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ReactSelect required options={moderatorList} classNamePrefix="select" id="moderator" className="react-select" onChange={handleModeratorSelect} name="moderator" placeholder="Select Moderator" />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            size="small"
            id="firstName"
            name="firstName"
            label="First name"
            defaultValue={userDetails.firstName}
            fullWidth
            autoComplete="first-name"
            inputRef={register({ required: 'First name Required.', maxLength: { value: 80, message: 'First name must be less than 80 characters.' } })}
            error={!!errors.firstName}
            helperText={errors.firstName && errors.firstName.message}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            variant="outlined"
            size="small"
            name="lastName"
            label="Last name"
            defaultValue={userDetails.lastName}
            fullWidth
            autoComplete="last-name"
            inputRef={register({ required: 'Last name Required.', maxLength: { value: 100, message: 'First name must be less than 100 characters.' } })}
            error={!!errors.lastName}
            helperText={errors.lastName && errors.lastName.message}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            size="small"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email-new"
            defaultValue={userDetails.email}
            inputRef={register({ required: 'Email Address Required', pattern: { value: /^\S+@\S+$/i, message: 'Enter valid Email Address.' } })}
            error={!!errors.email}
            onChange={handleChange}
            helperText={errors.email && errors.email.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            size="small"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={!!errors.password}
            defaultValue={userDetails.password}
            autoComplete="current-password-new"
            inputRef={register({
              required: 'Password is Required.',
              min: { value: 8, message: 'Password must be at least 8 letters.' },
              pattern: { value: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i, message: 'Password should contain Special character, one Capital and one number.' },
            })}
            helperText={errors.password && errors.password.message}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            variant="outlined"
            size="small"
            name="address1"
            label="Address line 1"
            fullWidth
            error={!!errors.address1}
            autoComplete="shipping address-line1"
            inputRef={register({ required: 'Address is Required.', maxLength: { value: 100, message: 'Address must be less than 100 characters' } })}
            onChange={handleChange}
            helperText={errors.address1 && errors.address1.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            variant="outlined"
            size="small"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
            inputRef={register({ maxLength: { value: 100, message: 'Address must be less than 100 characters' } })}
            helperText={errors.address2 && errors.address2.message}
            error={!!errors.address2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            variant="outlined"
            size="small"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            inputRef={register({ required: 'City is Required.', maxLength: { value: 100, message: 'City must be less than 100 characters' }, pattern: { value: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i, message: 'Enter Valid City.' } })}
            helperText={errors.city && errors.city.message}
            error={!!errors.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            variant="outlined"
            size="small"
            name="state"
            label="State/Province/Region"
            inputRef={register({ required: 'State/Province/Region is Required.', maxLength: { value: 100, message: 'State must be less than 100 characters' }, pattern: { value: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i, message: 'Enter Valid State.' } })}
            helperText={errors.state && errors.state.message}
            error={!!errors.state}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            variant="outlined"
            size="small"
            name="zip"
            label="Zip / Postal code"
            inputRef={register({ required: 'Zip / Postal code is Required.', maxLength: { value: 10, message: 'Zip / Postal code must be less than 10 characters' } })}
            helperText={errors.zip && errors.zip.message}
            error={!!errors.zip}
            fullWidth
            autoComplete="shipping postal-code"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="flex-end">
          <ReactSelect required options={countries} classNamePrefix="select" id="country" className="react-select" helperText={errors.country && errors.country.message} error={!!errors.country} onChange={handleCountrySelect} name="country" placeholder="Select Country" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField id="timezone" variant="outlined" size="small" name="timeZone" inputRef={register} helperText={errors.timeZone && errors.timeZone.message} error={!!errors.timeZone} label="Time Zone" fullWidth autoComplete="time zone" onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="cellphone"
            required
            variant="outlined"
            size="small"
            type="tel"
            name="cellPhone"
            inputRef={register({ required: 'Cell Phone is required.', maxLength: { value: 15, message: 'Cell phone must be less than 15 numbers.' } })}
            helperText={errors.cellPhone && errors.cellPhone.message}
            error={!!errors.cellPhone}
            label="Cell Phone"
            fullWidth
            autoComplete="cell phone"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField id="workhours" variant="outlined" size="small" name="workHours" inputRef={register} helperText={errors.workHours && errors.workHours.message} error={!!errors.workHours} label="Best time(s) Reached" fullWidth autoComplete="Best time(s) Reached" onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="workphone"
            type="tel"
            variant="outlined"
            size="small"
            name="workPhone"
            inputRef={register({ maxLength: { value: 12, message: 'Work phone must be less than 12 numbers.' } })}
            helperText={errors.workPhone && errors.workPhone.message}
            error={!!errors.workPhone}
            label="Work Phone"
            fullWidth
            autoComplete="work phone"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField required id="age" type="number" variant="outlined" size="small" name="age" label="Age" inputRef={register({ required: 'Age is required.' })} helperText={errors.age && errors.age.message} error={!!errors.age} fullWidth autoComplete="Age" onChange={handleChange} />
        </Grid>
        {userDetails.age >= 18 && (
          <Grid item xs={12}>
            <FormControl>
              <FormControlLabel label={HIDE_MATURE_CONTENT} control={<Checkbox name="hideMatureContent" id="hideMatureContent" defaultChecked={true} inputProps={{ 'aria-label': HIDE_MATURE_CONTENT }} />} onChange={handleChange} />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            size="small"
            select
            id="relationshipStatus"
            defaultValue=""
            inputRef={register}
            helperText={errors.relationshipStatus && errors.relationshipStatus.message}
            error={!!errors.relationshipStatus}
            name="relationshipStatus"
            label="Relationship Status"
            fullWidth
            autoComplete="Relationship Status"
            onChange={handleChange}
          >
            <MenuItem value="">--Select--</MenuItem>
            <MenuItem value="Single">Single</MenuItem>
            <MenuItem value="Married">Married</MenuItem>
            <MenuItem value="Relationship">In a Relationship</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl>
            <FormControlLabel label={IS_FIRST_RESPONDER} control={<Checkbox name="isFirstResponder" id="isFirstResponder" inputProps={{ 'aria-label': IS_FIRST_RESPONDER }} />} onChange={handleChange} />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField variant="outlined" size="small" fullWidth name="occupation" label="Occupation" type="text" id="occupation" autoComplete="occupation" onChange={handleChange} error={!!errors.occupation} inputRef={register()} />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel label={IS_USING_HYPNOSIS} control={<Checkbox name="isUsingHypnosis" id="isUsingHypnosis" inputProps={{ 'aria-label': IS_USING_HYPNOSIS }} />} onChange={handleChange} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField variant="outlined" size="small" fullWidth name="occupation" label="Occupation" type="text" id="occupation" autoComplete="occupation" onChange={handleChange} error={!!errors.occupation} inputRef={register()} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField required select variant="outlined" size="small" id="sex" defaultValue="" name="sex" inputRef={register({ required: 'Gender is required.' })} helperText={errors.sex && errors.sex.message} error={!!errors.sex} label="Sex" fullWidth autoComplete="Sex" onChange={handleChange}>
            <MenuItem value="">--Select--</MenuItem>
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            size="small"
            id="incomeGoal"
            inputRef={register}
            helperText={errors.incomeGoal && errors.incomeGoal.message}
            error={!!errors.incomeGoal}
            name="incomeGoal"
            value={userDetails.incomeGoal}
            label="Your yearly income goal is:"
            fullWidth
            autoComplete="incomeGoal"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            select
            size="small"
            id="inYear"
            name="inYear"
            value={userDetails.inYear}
            inputRef={register}
            helperText={errors.inYear && errors.inYear.message}
            error={!!errors.inYear}
            label="Year you want to reach your goal:"
            fullWidth
            autoComplete="inYear"
            onChange={handleChange}
          >
            {yearList.map((year, index) => {
              return (
                <MenuItem key={`year${index}`} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            select
            size="small"
            id="wouldLikeToEarn"
            inputRef={register}
            helperText={errors.wouldLikeToEarn && errors.wouldLikeToEarn.message}
            error={!!errors.wouldLikeToEarn}
            name="wouldLikeToEarn"
            value={userDetails.wouldLikeToEarn}
            label="This is _____ your current income"
            fullWidth
            autoComplete="wouldLikeToEarn"
            onChange={handleChange}
          >
            <MenuItem value="">--Select--</MenuItem>
            <MenuItem value="Modest Increase">Modest Increase</MenuItem>
            <MenuItem value="Doubling">Doubling</MenuItem>
            <MenuItem value="Tripling">Tripling</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField variant="outlined" select size="small" id="inYear" name="inYear" inputRef={register} helperText={errors.inYear && errors.inYear.message} error={!!errors.inYear} label="Year you want to reach your goal?" fullWidth autoComplete="inYear" onChange={handleChange}>
            {yearList.map((year, index) => {
              return (
                <MenuItem key={`year${index}`} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="reference"
            type="text"
            value={userDetails.reference}
            variant="outlined"
            size="small"
            name="reference"
            label="How did you find us?"
            inputRef={register()}
            helperText={errors.reference && errors.reference.message}
            error={!!errors.reference}
            fullWidth
            autoComplete="reference"
            onChange={handleChange}
          />
        </Grid>
        <EditMusicList playlist={userDetails.playlist} isViewable={!adminOrModerator() && isPlatinumSubscription} musicList={musicList} setFileList={setFileList} />
      </Grid>
      <Grid item container xs={12} justify="flex-end" className="submit-button-container">
        <Button type="submit" variant="contained" color="primary">
          Create
        </Button>
      </Grid>
    </form>
  );
  return <DialogComponent body={body} header={header} open={open} onClose={toggle} fullWidth={false} maxWidth="md" />;
};

CreateNewUser.propTypes = {
  toggle: PropTypes.func,
  open: PropTypes.bool,
  musicList: PropTypes.array,
  moderatorList: PropTypes.array,
  createUserData: PropTypes.func,
};

export default CreateNewUser;
