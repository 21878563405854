import axios from 'axios';
import { getStoredData } from './sessionUtils';

export const objToParams = (obj) => new URLSearchParams(obj).toString();

export const promiseReturn = (urlData, resolve, reject) => {
  const { url, method = 'get', params = null, options = null } = urlData;
  const methodName = axios[method];
  const accessToken = getStoredData('accessToken');
  const methodArgs = [url];
  params && methodArgs.push(params);
  const optionsObject = { ...options };
  let headers = null;
  if (accessToken) {
    headers = {
      'Authorization': accessToken,
    };
    optionsObject.headers = { ...headers };
  }
  if (optionsObject && Object.keys(optionsObject).length > 0) {
    methodArgs.push(optionsObject);
  }
  methodName(...methodArgs)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error.response);
    });
};
