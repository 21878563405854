import React from 'react';
import { InputLabel, FormHelperText, FormControl, Select } from '@material-ui/core';
import PropTypes from 'prop-types';

const SelectField = ({ id, value, error, helperText, children, label, name, onChange, onBlur, required, size }) => {
  return (
    <FormControl variant='outlined' size={size} error={error} style={{ width: '100%' }}>
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          required={required}
          labelId={`${id}-label`}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
        >
          {children}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
  );
};

SelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool, 
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  size: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

SelectField.defaultProps = {
  id: '',
  label: '',
  name: '',
  helperText: '',
  error: false,
  required: false,
  value: '',
  size: 'small',
  onChange: () => {},
  onBlur: () => {},
}

export default SelectField;