import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0),
    marginTop: '32px',
  },
  contactUs: {
    fontFamily: "'Nunito Sans', sans-serif",
  },
}));

function Copyright() {
  const classes = useStyles();
  return (
    <Typography variant="body2" color="textSecondary" align="center" className={classes.copyrightText}>
      {'Copyright © '}
      <Link color="inherit" href="https://acesuccess.com/" target="_blank">
        Success Center,Inc.
      </Link>
      {` ${new Date().getFullYear()}.`}
    </Typography>
  );
}

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg" className={classes.footerContainer}>
        {/* <Download /> */}
        <Typography variant="h6" align="center" gutterBottom className={classes.contactUs}>
          800-GOAL-NOW (462-5669)
        </Typography>
        <Copyright />
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
