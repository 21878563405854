import types from './types';

export const setInterestList = (value) => ({
  type: types.SET_INTEREST_LIST,
  value,
});

export const getInterestList = () => ({
  type: types.GET_INTEREST_LIST,
});

export const getInterestData = (value) => ({
  type: types.GET_INTEREST_DATA,
  value,
});

export const setInterestData = (value) => ({
  type: types.SET_INTEREST_DATA,
  value,
});

export const createInterest = (value) => ({
  type: types.CREATE_INTEREST,
  value,
});

export const deleteInterest = (value) => ({
  type: types.DELETE_INTEREST,
  value,
});

export const updateInterest = (value) => ({
  type: types.UPDATE_INTEREST,
  value,
});

export default {
  setInterestList,
  getInterestList,
  getInterestData,
  setInterestData,
  createInterest,
  deleteInterest,
  updateInterest,
};
