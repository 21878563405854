import React, { Component } from 'react';
import { Container, Typography, Button, Grid } from '@material-ui/core';
import './style.scss';
import UserList from './UserList';
import { connect } from 'react-redux';
import { getUserList, getUserData, deleteUser, createUser, updateUser, setUserData, getUserRoles, getModeratorList } from './Redux/actions';
import { getFileList } from '../FileManagement/Redux/actions';

import CreateNewUser from './CreateNewUser';
import ViewUpdateUser from './ViewUpdateUser';
import { isAdmin } from '../../utils/stringUtils';

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewUserData: false,
      createNewUser: false,
      viewMode: true,
    };
  }
  componentDidMount() {
    const { getUsers, getFiles, getRoles, getModerators, authRoles } = this.props;
    getUsers();
    getRoles();
    getFiles();
    isAdmin(authRoles) && getModerators();
  }

  toggleViewUserDataModal = () => {
    const { viewUserData } = this.state;
    const { setViewUserData } = this.props;
    if (viewUserData) {
      setViewUserData(null);
    }
    this.setState({ viewUserData: !viewUserData });
  };

  setViewMode = (value) => this.setState({ viewMode: value });

  toggleCreateNewUserModal = () => {
    const { createNewUser } = this.state;
    this.setState({ createNewUser: !createNewUser });
  };

  viewUserHandler = (userId, viewMode = true) => {
    const { viewUserData } = this.props;
    viewUserData(userId);
    this.setViewMode(viewMode);
    this.toggleViewUserDataModal();
  };

  render() {
    const { viewUserData, createNewUser, viewMode } = this.state;
    const { userList, userData, deleteUserData, createUserData, updateUserData, fileList, roleList, authData, authRoles, moderatorList } = this.props;
    const userListProps = {
      userList,
      deleteUserData,
      roleList,
      viewUserHandler: this.viewUserHandler,
    };
    return (
      <Container className="user-management-container" component="main">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className="page-title">
          Users
        </Typography>
        {isAdmin(authRoles) && (
          <Grid container justify="center" className="user-management-actions-container">
            <Button variant="contained" color="primary" onClick={this.toggleCreateNewUserModal}>
              Add New User
            </Button>
          </Grid>
        )}
        <UserList {...userListProps} />
        {viewUserData && userData && <ViewUpdateUser toggle={this.toggleViewUserDataModal} viewMode={viewMode} authData={authData} authRoles={authRoles} open={viewUserData} userProps={userData} updateUserData={updateUserData} musicList={fileList} roles={roleList} moderatorList={moderatorList} />}
        {createNewUser && <CreateNewUser toggle={this.toggleCreateNewUserModal} open={createNewUser} createUserData={createUserData} musicList={fileList} roles={roleList} moderatorList={moderatorList} authData={authData} authRoles={authRoles} />}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  userList: state.userManagement.userList || [],
  moderatorList: state.userManagement.moderatorList || [],
  userData: state.userManagement.userData,
  roleList: state.userManagement.roleList,
  authData: state.authentication.userDetails,
  authRoles: state.authentication.roles,
  fileList: state.fileManagement.fileList,
});
const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(getUserList()),
  getModerators: () => dispatch(getModeratorList()),
  getRoles: () => dispatch(getUserRoles()),
  getFiles: () => dispatch(getFileList()),
  viewUserData: (userId) => dispatch(getUserData(userId)),
  setViewUserData: (data) => dispatch(setUserData(data)),
  deleteUserData: (userId) => dispatch(deleteUser(userId)),
  createUserData: (data) => dispatch(createUser(data)),
  updateUserData: (data) => dispatch(updateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
