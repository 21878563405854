import { promiseReturn } from './serviceUtils';
import appConfig from '../config/appConfig';
const BASE_URI = `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/auth`;

export const login = async (username, password) => {
  const url = `${BASE_URI}/signin`;
  const urlData = {
    url,
    method: 'post',
    params: {
      username,
      password,
    },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const signUp = async (userData) => {
  const url = `${BASE_URI}/signup`;
  const urlData = {
    url,
    method: 'post',
    params: userData,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const checkDuplicateEmail = async (userData) => {
  const url = `${BASE_URI}/check-duplicate-username-email`;
  const urlData = {
    url,
    method: 'post',
    params: userData,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const logout = async (token) => {
  const url = `${BASE_URI}/logout`;
  const urlData = {
    url,
    method: 'get',
    params: {
      token,
    },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};
