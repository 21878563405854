import types from './types';

export const signUpUser = (value) => ({
  type: types.SIGN_UP_USER,
  value,
});

export const setSignUpSuccessful = (value) => ({
  type: types.SET_SIGN_UP_SUCCESSFUL,
  value,
});

export default {
  signUpUser,
  setSignUpSuccessful,
};
