import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SIGN_UP, SUBSCRIPTION } from '../../Routes/routingConstants';
import { subscriptions as tiers } from '../subscriptions';
import SubscriptionCard from './SubscriptionCard';
import './style.scss';

const SubscriptionCards = (props) => {
  const history = useHistory();
  const { showSelected = 4, showOther = true, selectedPlan, selectedTitle, showSelectedTitle, otherTitle, showOtherTitle } = props;
  const selectSubscriptionPlan = (tier) => {
    const { selectSubscription } = props;
    selectSubscription(tier);
    history.pathname === SUBSCRIPTION && history.push(SIGN_UP);
  };

  return (
    <Container maxWidth="md" component="main" className="subscription-cards-container">
      {showSelected && (
        <Grid container spacing={3} justify="center">
          {showSelectedTitle && (
            <Grid item>
              <h6>{selectedTitle}</h6>
            </Grid>
          )}
          <Grid container spacing={5} justify="center">
            <SubscriptionCard tier={selectedPlan} selectSubscriptionPlan={() => {}} selectedSubscription={selectedPlan} showSelected={showSelected} />
          </Grid>
        </Grid>
      )}
      {showOther && (
        <Grid container spacing={3} justify="center">
          {showOtherTitle && (
            <Grid item>
              <h6>{otherTitle}</h6>
            </Grid>
          )}
          <Grid container spacing={5} justify="center">
            <Grid item container spacing={5} justify="center">
              {tiers.map((tier, index) => {
                if (tier.title === selectedPlan.title) {
                  return null;
                }
                return <SubscriptionCard tier={tier} key={index} selectSubscriptionPlan={selectSubscriptionPlan} selectedSubscription={selectedPlan} />;
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

SubscriptionCards.defaultProps = {
  showSelected: 4,
  showOther: true,
  selectedTitle: 'Selected Subscription:',
  showSelectedTitle: true,
  otherTitle: 'Other Subscriptions:',
  showOtherTitle: true,
};

SubscriptionCards.propTypes = {
  showSelected: PropTypes.number || PropTypes.bool,
  showOther: PropTypes.bool,
  selectedPlan: PropTypes.object,
  selectSubscription: PropTypes.func,
  selectedTitle: PropTypes.string,
  showSelectedTitle: PropTypes.bool,
  otherTitle: PropTypes.string,
  showOtherTitle: PropTypes.bool,
};

export default SubscriptionCards;
