import { all } from 'redux-saga/effects';
import { authenticationSagas } from './SignIn/redux/operations';
import { signupSagas } from './SignUp/redux/operations';
import { musicPlayerSagas } from './RFTSMusicPlayer/Redux/operations';
import { fileManagementSagas } from './AdminPanel/FileManagement/Redux/operations';
import { userManagementSagas } from './AdminPanel/UserManagement/Redux/operations';
import { interestManagementSagas } from './AdminPanel/InterestManagement/Redux/operations';
import { salesLeadManagementSagas } from './AdminPanel/SalesLeadManagement/Redux/operations';

export default function* rootSaga() {
  yield all([...authenticationSagas, ...signupSagas, ...musicPlayerSagas, ...fileManagementSagas, ...userManagementSagas, ...interestManagementSagas, ...salesLeadManagementSagas]);
}
