import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import appConfig from '../../config/appConfig';
import SplitCardFormAddress from './SplitCardFormAddress';
import SplitCardForm from './SplitCardForm';

const stripePromise = loadStripe(appConfig.stripe_key);

const StripePaymentComponent = (props) => {
  const { handleNext, detailProps, withAddress } = props;
  const Content = withAddress ? SplitCardFormAddress : SplitCardForm;
  return (
    <Elements stripe={stripePromise}>
      <Content handleNext={handleNext} detailProps={detailProps} />
    </Elements>
  );
};
StripePaymentComponent.propTypes = {
  handleNext: PropTypes.func,
  detailProps: PropTypes.object,
  withAddress: PropTypes.bool,
};

export default StripePaymentComponent;
