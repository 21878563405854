import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogComponent from '../../../components/DialogComponent';
import { connect } from 'react-redux';
import { getCurrentUserSession, getSessionInfoBySessionIndex, moveUserSession, resetUserSessions } from '../Redux/actions';
import MusicListItem from '../../../components/MusicListItem';
import './style.scss';
import rftsLogo from '../../../images/rftsLogo.jpg';
import { Button, Typography } from '@material-ui/core';
import appConfig from '../../../config/appConfig';

const ViewSessionInfo = (props) => {
  const { sessionIndex, getSessionDetails, userId, currentUserSession, firstName, lastName, email, getSessionDetailsByIndex, interestPlayingOrder, session, subscription = 'free', resetSession, sessionDate, moveSession } = props;
  const isPlatinum = subscription.toLowerCase() === 'platinum';
  const header = 'Session Information';
  const [open, setOpen] = useState(false);
  const toggle = () => {
    !open && getSessionDetails(userId);
    setOpen(!open);
  };
  const prevSession = () => getSessionDetailsByIndex(userId, session - 1, interestPlayingOrder, isPlatinum);
  const nextSession = () => getSessionDetailsByIndex(userId, session + 1, interestPlayingOrder, isPlatinum);
  const reset = () => {
    resetSession(userId);
    toggle();
  };
  const moveSessionIndex = () => {
    const sessionDetails = currentUserSession.map((sessionItem) => {
      return {
        audio: sessionItem.audio._id,
        played: sessionItem.played,
      };
    });
    moveSession(userId, session, sessionDetails, interestPlayingOrder);
  };
  const body = (
    <div className="view-session-info">
      <div className="session-number">Current Session: {sessionIndex}</div>
      <div className="session-date">Session Last Updated on: {sessionDate}</div>
      <div className="user-name">
        Name: {firstName} {lastName}
      </div>
      <div className="user-email">Email: {email}</div>
      {session !== sessionIndex && <div className="session-nav">Session: {session}</div>}
      <div style={{ display: subscription.toLowerCase() === 'free' ? 'none' : undefined }}>
        <Button onClick={prevSession} disabled={session <= 1}>
          Prev Session
        </Button>
        <Button onClick={nextSession}>Next Session</Button>
        <Button onClick={moveSessionIndex} variant="contained" disabled={session <= sessionIndex} color={'primary'}>
          Set Session
        </Button>
      </div>
      <div className="session-details">
        {currentUserSession &&
          currentUserSession.map((sessionMp3) => {
            if (sessionMp3.audio) {
              const mp3Item = sessionMp3.audio;
              sessionMp3.audio.cover = mp3Item.thumbnailPath ? `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/thumbnail/${mp3Item._id}` : rftsLogo;
            }
            return <MusicListItem {...sessionMp3.audio} key={sessionMp3._id} played={sessionMp3.played} />;
          })}
      </div>
      <div className="reset-section">
        <Button variant="contained" color={'secondary'} onClick={reset}>
          Reset User Session
        </Button>
        <Typography variant="body1" component="p">
          *Please note, this button will reset all the user session details.
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      <span className="href-link" title="Show Session Info" onClick={toggle}>
        {sessionIndex}
      </span>
      <DialogComponent body={body} header={header} open={open} onClose={toggle} fullWidth={false} maxWidth="md" />
    </>
  );
};
ViewSessionInfo.propTypes = {
  sessionIndex: PropTypes.number,
  session: PropTypes.number,
  currentUserSession: PropTypes.object,
  getSessionDetails: PropTypes.func,
  userId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  getSessionDetailsByIndex: PropTypes.func,
  interestPlayingOrder: PropTypes.array,
  subscription: PropTypes.string,
  sessionDate: PropTypes.string,
  resetSession: PropTypes.func,
  moveSession: PropTypes.func,
};
const mapStateToProps = (state) => ({ currentUserSession: state.userManagement.currentUserSession, interestPlayingOrder: state.userManagement.interestPlayingOrder, session: state.userManagement.sessionIndex });
const mapDispatchToProps = (dispatch) => ({
  getSessionDetails: (userId) => dispatch(getCurrentUserSession(userId)),
  getSessionDetailsByIndex: (userId, session, interestPlayingOrder, isPlatinum) => dispatch(getSessionInfoBySessionIndex({ userId, session, interestPlayingOrder, isPlatinum })),
  moveSession: (userId, session, sessionDetails, interestPlayingOrder) => dispatch(moveUserSession({ userId, session, sessionDetails, interestPlayingOrder })),
  resetSession: (userId) => dispatch(resetUserSessions(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSessionInfo);
