import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import types from './types';
import actions, { setAuthenticationData, setUserDetails, getUserDetails as getUser, getCustomerPaymentDetails, setCustomerPaymentDetails, setIsHavingActiveSubscription } from './actions';
import { startLoader, stopLoader } from '../../components/LoaderComponent/Redux/actions';
import { login } from '../../Services/restClient';
import { getUserInfo, updateUserDataById, upgradeSubscription } from '../../Services/userManagementServiceClient';

import { errorMessages } from '../../utils/appConstants';
import { HOME, PLAYLIST } from '../../Routes/routingConstants';
import { storeSignInDetails, clearAllData } from '../../Services/sessionUtils';
import { cancelSubscription, getStripeSubscriptionsByCustomerId } from '../../Services/stripeServiceClient';
import { subscriptions } from '../../Subscription/subscriptions';
import { setSubscriptionPlan } from '../../Subscription/Redux/actions';

function* loginUser(inputObj) {
  try {
    if (!inputObj || !inputObj.value) {
      return;
    }
    const { email, password } = inputObj.value;
    if (!email) {
      return;
    }
    yield put(startLoader());
    const authData = yield call(login, email, password);
    if (authData) {
      storeSignInDetails(authData);
      yield put(setAuthenticationData(authData));
      yield put(push(PLAYLIST));
      toast.success('Successfully logged in');
      yield put(getUser(authData.userId));
    } else {
      toast.error(errorMessages.LOGIN_ERROR);
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    clearAllData();
    if (error && error.data) {
      const { message } = error.data;
      toast.error(message);
    } else {
      toast.error(errorMessages.LOGIN_ERROR);
    }
  }
}

function* getUserDetails(inputObj) {
  try {
    if (!inputObj || !inputObj.value) {
      return;
    }
    const userId = inputObj.value;
    yield put(startLoader());
    const userData = yield call(getUserInfo);
    if (userData) {
      const { subscription, stripeCustomerId } = userData.data;
      let selectedSubscription = subscriptions[0];
      if (subscription !== 'Free') {
        const filteredSubscription = subscriptions.filter(({ title }) => title === subscription);
        if (filteredSubscription.length > 0) {
          selectedSubscription = filteredSubscription[0];
        }
      }
      yield put(setSubscriptionPlan(selectedSubscription));
      yield put(setUserDetails(userData.data));
      const paymentRequestData = {
        customerId: stripeCustomerId,
        subscription,
      };
      yield put(getCustomerPaymentDetails(paymentRequestData));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    toast.error('There is some error while getting user data. Please check your credentials.');
  }
}

function* updateUserDetails(inputObj) {
  const formData = inputObj.value;
  try {
    if (!formData) {
      return;
    }
    yield put(startLoader());
    const userData = yield call(updateUserDataById, formData);
    if (userData) {
      toast.success(userData.message);
      yield put(actions.getUserDetails(formData._id));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while updating user');
  }
}

function* getUserPaymentDetails(inputObj) {
  try {
    if (!inputObj || !inputObj.value) {
      return;
    }
    const { customerId, subscription } = inputObj.value;
    yield put(startLoader());
    const paymentDataResponse = yield call(getStripeSubscriptionsByCustomerId, customerId);
    const paymentDataList = paymentDataResponse.data;
    if (paymentDataList.length > 0) {
      const paymentData = paymentDataList[0];
      const date = new Date().getTime();
      const subscriptionEndDate = paymentData.current_period_end * 1000;
      let isHavingActiveSubscription = false;
      if (!paymentData || (paymentData && paymentData.status === 'canceled' && date > subscriptionEndDate)) {
        isHavingActiveSubscription = false;
        if (subscription.toLowerCase() === 'free') {
          isHavingActiveSubscription = true;
        }
      } else {
        isHavingActiveSubscription = true;
      }
      yield put(setCustomerPaymentDetails(paymentData));
      yield put(setIsHavingActiveSubscription(isHavingActiveSubscription));
    } else {
      yield put(setCustomerPaymentDetails(null));
      yield put(setIsHavingActiveSubscription(false));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    yield put(setCustomerPaymentDetails(null));
    yield put(setIsHavingActiveSubscription(false));
    toast.error('There is some error while getting user data. Please check your credentials.');
  }
}

function* cancelStripeSubscription(inputObj) {
  try {
    if (!inputObj || !inputObj.value) {
      return;
    }
    const email = inputObj.value;
    yield put(startLoader());
    const userData = yield call(cancelSubscription, email);
    if (userData) {
      yield put(getCustomerPaymentDetails(email));
      toast.success('Your subscription is cancelled.');
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    toast.error('There is some error while getting user data. Please check your credentials.');
  }
}

function* logoutUser() {
  try {
    yield put(startLoader());
    const { authenticated } = yield select((state) => state.authentication);
    yield put(
      setAuthenticationData({
        userId: '',
        accessToken: '',
        roles: '',
      }),
    );
    yield put(setUserDetails({}));
    yield put(setCustomerPaymentDetails({}));
    yield put(setIsHavingActiveSubscription(false));
    clearAllData();
    if (!authenticated) {
      yield put(push(HOME));
    } else {
      yield put(push(HOME));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    clearAllData();
    yield put(push(HOME));
    yield put(
      setAuthenticationData({
        userId: '',
        accessToken: '',
        roles: '',
      }),
    );
    // toast.error('There is some error while Logging out.');
  }
}

function* upgradeSubscriptionPlan(inputObj) {
  const userData = inputObj.value;
  try {
    yield put(startLoader());
    yield call(upgradeSubscription, userData._id, userData);
    yield put(actions.getUserDetails(userData._id));
    toast.success('Subscription upgraded successfully.');
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    if (error && error.data && error.data.paymentData) {
      const { status = '', raw: { message = '' } = {} } = error.data.paymentData;
      (!status || status !== 'succeeded') && toast.error(message, { autoClose: false });
    } else if (error.data && error.data.message) {
      toast.error(error.data.message);
    } else {
      toast.error('There is some Error while Signing you up.');
    }
  }
}

export const authenticationSagas = [
  takeLatest(types.AUTHENTICATE_USER, loginUser),
  takeLatest(types.GET_CUSTOMER_PAYMENT_DETAILS, getUserPaymentDetails),
  takeLatest(types.UPDATE_USER_DETAILS, updateUserDetails),
  takeLatest(types.GET_USER_DETAILS, getUserDetails),
  takeLatest(types.LOGOUT_USER, logoutUser),
  takeLatest(types.CANCEL_CUSTOMER_SUBSCRIPTION, cancelStripeSubscription),
  takeLatest(types.UPGRADE_SUBSCRIPTION, upgradeSubscriptionPlan),
];
