import { promiseReturn } from './serviceUtils';
import appConfig from '../config/appConfig';
const BASE_URI = `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/stripe`;

export const cancelSubscription = async (email) => {
  const url = `${BASE_URI}/subscriptions/cancel`;
  const urlData = {
    url,
    method: 'get'
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getStripeCustomerList = async (email) => {
  const url = `${BASE_URI}/customer/info`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getStripeSubscriptionsByCustomerId = async (customerId) => {
  const url = `${BASE_URI}/customer/subscriptions`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getBillingSessionUrl = async (customerId) => {
  if (!customerId) {
    return;
  }
  const url = `${BASE_URI}/get-customer-billing-session-url`;

  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};
