import types from '../SignUp/redux/types';

const INITIAL_STATE = {
  singUpSuccess: false,
};

const reducerHandler = (action, state = INITIAL_STATE) => {
  const reducerObject = {
    [types.SET_SIGN_UP_SUCCESSFUL]: () => ({
      ...state,
      singUpSuccess: action.value,
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const onBoardingReducer = (state, action) => reducerHandler(action, state);

export default onBoardingReducer;
