import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import createSagaMiddleware from 'redux-saga';
import filter from 'redux-storage-decorator-filter';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from './rootReducers';
import buildConfig from '../src/config/buildConfig';
import { buildTypeConstants } from './utils/appConstants';

export const history = createBrowserHistory({ basename: '/' });
export const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({ name: 'rfts' });
const reducer = storage.reducer(createRootReducer(history));
// Blacklist and whitelist part of the state tree to be stored in localstorage
const engine = filter(createEngine('rfts-store'), []);
const storageMiddleware = storage.createMiddleware(engine);

// If build type is production then disable redux dev tools
const enhancedCompose = buildConfig.buildType.name === buildTypeConstants.PRODUCTION ? compose : composeEnhancers;

export const middleware = enhancedCompose(applyMiddleware(sagaMiddleware, routerMiddleware(history), storageMiddleware));
export const store = createStore(reducer, middleware);

// Load previous state from localstorage
export const load = storage.createLoader(engine);
