import React from 'react';
import PropTypes from 'prop-types';

import { SIGN_UP } from '../Routes/routingConstants';
import MainFeaturedPost from '../components/MainFeaturedPost';
import DocInformation from '../components/DocInformation';
import ProductInformation from '../components/ProductInformation';
import { Container, Grid, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './style.scss';
import { IntroVideo } from '../components/Video';

function Home(props) {
  const { authenticated } = props;
  const gotoLink = () => {
    const { history } = props;
    !authenticated && history.push(SIGN_UP);
  };
  const post = {
    title: 'Imagine … The Best You',
    date: 'Nov 12',
    description: 'Overcome present challenges and make your goals a reality all while you sleep.',
  };
  const startNowComponent = !authenticated && (
    <Grid container xs={12} sm={12} md={12} className="signup-button-grid" justify="center">
      <Grid item xs={12} sm={12} md={4}>
        <Button fullWidth variant="contained" color="primary" className="signup-button" onClick={gotoLink}>
          Sign up for your free account
        </Button>
      </Grid>
    </Grid>
  );
  return (
    <Container className="home-container" disableGutters>
      <MainFeaturedPost post={post} />
      <Container className="information-container">
        <IntroVideo />
        <ProductInformation startNowComponent={startNowComponent} gotoSubscription={gotoLink} />
        <DocInformation />
        {startNowComponent}
      </Container>
    </Container>
  );
}

Home.propTypes = {
  authenticated: PropTypes.bool,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({ authenticated: state.authentication.authenticated });

export default withRouter(connect(mapStateToProps, null)(Home));
