import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Avatar, Button, TextField, Grid, Typography, InputAdornment, IconButton } from '@material-ui/core';
import { FORGOT_PASSWORD, SIGN_UP } from '../Routes/routingConstants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { authenticateUser } from './redux/actions';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  navigationLink: {
    color: 'blue',
    cursor: 'pointer',
  },
}));

function SignIn(props) {
  const classes = useStyles();
  const [userDetails, setSignInDetails] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const gotoLink = (url) => {
    const { history } = props;
    history.push(url);
  };

  const handleChange = (event) => {
    if (!event) {
      return;
    }
    const { name, value } = event.target;
    setSignInDetails({ ...userDetails, [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const { email, password } = userDetails;
    const { authenticate } = props;
    authenticate(email, password);
  };
  const passwordType = showPassword ? 'text' : 'password';
  return (
    <Container component="main" maxWidth="xs" className={classnames('sign-in-container', classes.paper)}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign In
      </Typography>
      <form className={classes.form} noValidate onSubmit={submitHandler}>
        <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus onChange={handleChange} />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={passwordType}
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Typography component="text" variant="body1" onClick={() => gotoLink(FORGOT_PASSWORD)} className={classes.navigationLink}>
              Forgot password?
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="text" variant="body1" className={classes.navigationLink} onClick={() => gotoLink(SIGN_UP)}>
              First Time?
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
SignIn.propTypes = {
  authenticate: PropTypes.func,
  history: PropTypes.object,
}


const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (email, password) => dispatch(authenticateUser({ email, password })),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(SignIn));
