import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionCards from '../../Subscription/SubscriptionCards';
import DialogComponent from '../DialogComponent';
import StripePaymentComponent from '../StripePaymentComponent';
import { connect } from 'react-redux';
import { setSubscriptionPlan } from '../../Subscription/Redux/actions';

import './style.scss';
import { upgradeSubscription } from '../../SignIn/redux/actions';
import { subscriptions } from '../../Subscription/subscriptions';
import { useHistory } from 'react-router';
import { USER_INTERESTS } from '../../Routes/routingConstants';

const UpgradePopup = (props) => {
  const { open, toggle, subscription, selectedSubscription, userData, upgrade } = props;
  const header = 'Upgrade your subscription';
  const history = useHistory();
  const selectSubscriptionPlan = (plan) => {
    const { selectSubscription } = props;
    selectSubscription(plan);
  };
  const getInitialSubscription = () => {
    const filteredPlan = subscriptions.filter(({ title }) => userData.subscription === title);
    if (filteredPlan.length > 0) {
      return filteredPlan[0];
    }
    return subscriptions[0];
  };
  const resetSubscription = () => {
    const plan = getInitialSubscription();
    selectSubscriptionPlan(plan);
  };

  const handlePaymentSubmit = (paymentMethodId, promoCode = '') => {
    userData.subscription = selectedSubscription.title;
    userData.amount = selectedSubscription.amount * 100;
    userData.paymentMethodId = paymentMethodId;
    userData.promoCode = promoCode;
    upgrade(userData);
  };
  const selectedTitle = (
    <span>
      Selected Subscription (
      <span className="href-link" onClick={resetSubscription}>
        Change
      </span>
      )
    </span>
  );
  const gotoProfile = () => history.push(USER_INTERESTS);

  const selectSubscriptionContainer = (
    <Grid container className="select-subscription-container">
      <Grid item justify="center">
        <Typography variant="h6" align="center" color="textPrimary" component="p" className="modal-para">
          Select and upgrade to your desired membership to unlock additional features.
        </Typography>
      </Grid>
      <SubscriptionCards selectSubscription={selectSubscriptionPlan} selectedPlan={selectedSubscription} showSelected={false} showOther={true} showSelectedTitle={false} showOtherTitle={false} />
    </Grid>
  );
  const paymentForm = (
    <Grid container className="select-subscription-container" spacing={3}>
      <Grid container item justify="center">
        <SubscriptionCards selectSubscription={() => {}} selectedPlan={selectedSubscription} showSelected={4} showOther={false} showSelectedTitle={true} selectedTitle={selectedTitle} />
      </Grid>
      <Grid item>
        Please{' '}
        <span className="href-link" onClick={gotoProfile}>
          review
        </span>{' '}
        your interests and priorities. Make any changes you want so you may enjoy the tailored meditations for the upcoming days. You will only be able to make further changes as you wish at that time.
      </Grid>
      <Grid container item justify="center">
        <StripePaymentComponent handleNext={handlePaymentSubmit} detailProps={{ userDetails: userData }} />
      </Grid>
    </Grid>
  );
  const body = selectedSubscription.title === subscription ? selectSubscriptionContainer : paymentForm;
  return <DialogComponent body={body} header={header} open={open} onClose={toggle} fullWidth={false} maxWidth="md" />;
};

UpgradePopup.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  subscription: PropTypes.string,
  selectSubscription: PropTypes.func,
  selectedSubscription: PropTypes.object,
  userData: PropTypes.object,
  upgrade: PropTypes.func,
};

const mapStateToProps = (state) => ({
  selectedSubscription: state.subscription.selectedSubscription,
  userData: state.authentication.userDetails,
});
const mapDispatchToProps = (dispatch) => ({
  selectSubscription: (plan) => dispatch(setSubscriptionPlan(plan)),
  upgrade: (userData) => dispatch(upgradeSubscription(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePopup);
