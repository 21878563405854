import { promiseReturn } from './serviceUtils';
import appConfig from '../config/appConfig';
const BASE_URI = `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/sales-lead-user`;

export const getAllSalesLeads = async () => {
  const url = `${BASE_URI}/all`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const downloadSalesLeadsCSV = () => {
  const url = `${BASE_URI}/download-csv`;
  window.open(url);
};

export const getSalesLeadUserById = async (fileId) => {
  const url = `${BASE_URI}/id/${fileId}`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const deleteSalesLeadUser = async (fileId) => {
  const url = `${BASE_URI}/${fileId}`;
  const urlData = {
    url,
    method: 'delete',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const createSalesLeadUser = async (data) => {
  const url = `${BASE_URI}/create`;
  const urlData = {
    url,
    method: 'post',
    params: data,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};