import types from './types';

export const authenticateUser = (value) => ({
  type: types.AUTHENTICATE_USER,
  value,
});

export const logoutUser = (value) => ({
  type: types.LOGOUT_USER,
  value,
});

export const setAuthenticationData = (value) => ({
  type: types.SET_AUTHENTICATION_DATA,
  value,
});

export const getUserDetails = (value) => ({
  type: types.GET_USER_DETAILS,
  value,
});

export const setUserDetails = (value) => ({
  type: types.SET_USER_DETAILS,
  value,
});

export const updateUserDetails = (value) => ({
  type: types.UPDATE_USER_DETAILS,
  value,
});

export const getCustomerPaymentDetails = (value) => ({
  type: types.GET_CUSTOMER_PAYMENT_DETAILS,
  value,
});

export const setCustomerPaymentDetails = (value) => ({
  type: types.SET_CUSTOMER_PAYMENT_DETAILS,
  value,
});

export const cancelStripeSubscription = (value) => ({
  type: types.CANCEL_CUSTOMER_SUBSCRIPTION,
  value,
});

export const upgradeSubscription = (value) => ({
  type: types.UPGRADE_SUBSCRIPTION,
  value,
});

export const setIsHavingActiveSubscription = (value) => ({
  type: types.SET_IS_HAVING_ACTIVE_SUBSCRIPTION,
  value,
});

export default {
  logoutUser,
  authenticateUser,
  setAuthenticationData,
  getUserDetails,
  setUserDetails,
  getCustomerPaymentDetails,
  setCustomerPaymentDetails,
  cancelStripeSubscription,
  upgradeSubscription,
  setIsHavingActiveSubscription,
};
