import React, { Component } from 'react';
import { Grid, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import './style.scss';
import InterestListItem from '../InterestListItem';

export class InterestsOrderingForm extends Component {
  render() {
    const { reset, selected, handleOrder, disabled = false, showAudios = true } = this.props;
    return (
      <div className="interest-ordering-form">
        <hr />
        <span style={{ color: 'black' }}>Interests List (with mp3 list)</span>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <List dense={false}>
              {selected &&
                selected.map((item, key) => {
                  return (
                    <ListItem key={item._id}>
                      <ListItemText primary={<InterestListItem item={item} showAudios={showAudios} />} />
                      <ListItemSecondaryAction>
                        <IconButton disabled={key === 0 || disabled} onClick={() => handleOrder(true, key)}>
                          <KeyboardArrowUp />
                        </IconButton>
                        <IconButton disabled={key === 9 || disabled} onClick={() => handleOrder(false, key)}>
                          <KeyboardArrowDown />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </Grid>
        </Grid>
        {reset}
      </div>
    );
  }
}

export default InterestsOrderingForm;
