import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import InterestsSelectionForm from '../../components/InterestSelectionForm';
import InterestsOrderingForm from '../../components/InterestsOrderingForm';
import { connect } from 'react-redux';
import { getInterestList } from '../../AdminPanel/InterestManagement/Redux/actions';
import { Container, Grid } from '@material-ui/core';
import { getUserDetails, updateUserDetails } from '../../SignIn/redux/actions';

const styles = (theme) => ({
  container: {
    display: 'flex', //flex
    flexFlow: 'row',
    textAlign: 'center',
    color: 'black',
  },
  text: {
    padding: '10px 0',
    minWidth: '200px',
  },

  checkboxes: {
    position: 'relative',
    right: '10px',
  },

  orders: {
    position: 'relative',
    top: '20px',
    right: '10px',
  },

  textField: {
    margin: theme.spacing.unit,
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },

  formControl: {
    margin: theme.spacing.unit,
    minWidth: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footerButton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

class InterestsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: props.userDetails,
    };
  }

  setUserData = (data) => this.setState({ userDetails: data });

  onSubmit = () => {
    const { updateUserData } = this.props;
    const { userDetails } = this.state;
    userDetails.roles = [userDetails.roles];
    userDetails.username = userDetails.email;
    const interestList = [...userDetails.selectedInterests];
    let interestsChanged = false;
    userDetails.selectedInterests = interestList.map((interestItem, index) => {
      if (!this.props.userDetails.selectedInterests[index] || interestItem._id !== this.props.userDetails.selectedInterests[index]._id) {
        interestsChanged = true;
      }
      return interestItem._id;
    });
    if (interestsChanged) {
      userDetails.sessionIndex = 0;
      userDetails.interestPlayingOrder = [];
    }
    updateUserData(userDetails);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userDetails } = nextProps;
    this.setUserData(userDetails);
  }

  componentDidMount() {
    const { getInterests, getUserData } = this.props;
    const { userDetails } = this.props;
    getUserData(userDetails._id);
    getInterests();
  }

  setInterestsList = (selectedInterests) => {
    const { userDetails } = this.state;
    this.setUserData({ ...userDetails, selectedInterests });
  };

  handleListReset = () => {
    this.setInterestsList([]);
    window.scrollTo(0, 0);
  };

  handleOrder = (increase, key) => {
    const { userDetails } = this.state;
    const { selectedInterests: selected } = userDetails;
    const selectedInterests = selected.slice();
    const item = selectedInterests.splice(key, 1);
    if (increase) {
      selectedInterests.splice(key - 1, 0, item[0]);
    } else {
      selectedInterests.splice(key + 1, 0, item[0]);
    }
    this.setInterestsList(selectedInterests);
  };

  handleListClick = (value) => () => {
    const { userDetails } = this.state;
    const { selectedInterests } = userDetails;
    const currentIndex = selectedInterests.indexOf(value);
    const newChecked = [...selectedInterests];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setInterestsList(newChecked);
  };

  render() {
    const { interestList: interests } = this.props;
    const {
      userDetails: { selectedInterests: selected = [], subscription = 'bronze', sessionIndex = 0 },
    } = this.state;
    if (subscription.toLowerCase() === 'platinum') {
      return <p>Please Contact your therapist, if you wish to change your goals.</p>;
    }
    const interestManagementDisabled = (subscription.toLowerCase() === 'silver' && sessionIndex < 30) || (subscription.toLowerCase() === 'bronze' && sessionIndex < 90);
    const reset = (
      <Button onClick={this.handleListReset} disabled={interestManagementDisabled}>
        Reset
      </Button>
    );
    const remainDays = {
      silver: 30,
      bronze: 90,
    };
    const remainingDays = remainDays[subscription.toLowerCase()] - sessionIndex;
    const list = <InterestsSelectionForm selected={selected} interests={interests} handleListClick={this.handleListClick} disabled={interestManagementDisabled} />;
    const list2 = <InterestsOrderingForm handleOrder={this.handleOrder} selected={selected} reset={reset} disabled={interestManagementDisabled} />;
    return (
      <Container className="interest-container" disableGutters maxWidth="md">
        <p>Current Session Index is {sessionIndex}</p>
        {interestManagementDisabled ? (
          <>
            <p>You will be able to make changes to your goals after completing {remainDays[subscription.toLowerCase()]} days.</p>
            <p>{remainingDays} days left to change your interests </p>
          </>
        ) : (
          <p>Please note that making changes in your goals will impact on your playlist sequence.</p>
        )}
        {selected.length < 10 ? list : list2}
        <Grid item container xs={12} justify="flex-end" onClick={this.onSubmit}>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Container>
    );
  }
}

InterestsList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  interestList: state.interestManagement.interestList || [],
  userDetails: state.authentication.userDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getInterests: () => dispatch(getInterestList()),
  updateUserData: (data) => dispatch(updateUserDetails(data)),
  getUserData: (data) => dispatch(getUserDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InterestsList));
