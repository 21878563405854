import types from '../SignIn/redux/types';

const INITIAL_STATE = {
  authenticated: false,
  username: '',
  email: '',
  userId: '',
  accessToken: '',
  roles: '',
  sessionIndex: 0,
  name: '',
  userDetails: {},
  isHavingActiveSubscription: false,
  customerPaymentDetails: null,
};

const reducerHandler = (action, state = INITIAL_STATE) => {
  const reducerObject = {
    [types.SET_AUTHENTICATION_DATA]: () => {
      const { username, email, userId, accessToken, roles, name, sessionIndex } = action.value;
      return {
        ...state,
        authenticated: !!accessToken,
        userId,
        sessionIndex,
        accessToken,
        roles,
        username,
        email,
        name,
      };
    },
    [types.SET_USER_DETAILS]: () => {
      return {
        ...state,
        userDetails: action.value,
      };
    },
    [types.SET_IS_HAVING_ACTIVE_SUBSCRIPTION]: () => ({
      ...state,
      isHavingActiveSubscription: action.value,
    }),
    [types.SET_CUSTOMER_PAYMENT_DETAILS]: () => ({
      ...state,
      customerPaymentDetails: action.value,
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const authReducer = (state, action) => reducerHandler(action, state);

export default authReducer;
