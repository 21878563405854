import { Typography } from '@material-ui/core';
import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { connect } from 'react-redux';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import './style.scss';

const LoaderComponent = (props) => {
  const { isLoading, progress } = props;
  if (!isLoading) {
    return null;
  }

  if (progress !== null) {
    return (
      <div className="overlay">
        <div className="overlay__inner">
          <div className="overlay__content">
            <CircularProgressWithLabel value={progress} />
            <Typography variant="caption" component="div" color="textSecondary" className="percentage">
              Uploading File
            </Typography>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="overlay">
      <div className="overlay__inner">
        <div className="overlay__content">
          <Loader type="Bars" color="#cecece" height={50} width={50} visible={isLoading} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.common.isLoading,
  progress: state.common.isLoadingProgress,
});

export default connect(mapStateToProps, null)(LoaderComponent);
