import types from './types';

export const setSubscriptionPlan = (value) => ({
  type: types.SET_SUBSCRIPTION_PLAN,
  value,
});

export default {
  setSubscriptionPlan,
};
