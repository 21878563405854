import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import { getAllInterests, getInterestById, deleteInterestById, createInterest, updateInterestDataById } from '../../../Services/interestManagementServiceClient';
import { stopLoader, startLoader } from '../../../components/LoaderComponent/Redux/actions';
import actions from './actions';

function* getInterestList() {
  try {
    yield put(startLoader());
    const interestList = yield call(getAllInterests);
    if (interestList && interestList.data) {
      yield put(actions.setInterestList(interestList.data));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching interest list');
  }
}

function* getInterestData(inputObj) {
  const interestId = inputObj.value;
  try {
    yield put(startLoader());
    const interestData = yield call(getInterestById, interestId);
    if (interestData && interestData.data && interestData.data.length) {
      yield put(actions.setInterestData(interestData.data[0]));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching interest data');
  }
}

function* deleteInterestData(inputObj) {
  const interestId = inputObj.value;
  try {
    yield put(startLoader());
    const interestData = yield call(deleteInterestById, interestId);
    if (interestData) {
      toast.success(interestData.message);
      yield put(actions.getInterestList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while deleting interest data');
  }
}

function* createInterestData(inputObj) {
  const formData = inputObj.value;
  try {
    if (!formData) {
      return;
    }
    yield put(startLoader());
    const interestData = yield call(createInterest, formData);
    if (interestData) {
      toast.success(interestData.message);
      yield put(actions.getInterestList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while creating interest');
  }
}

function* updateInterestData(inputObj) {
  const formData = inputObj.value;
  try {
    if (!formData) {
      return;
    }
    yield put(startLoader());
    const interestData = yield call(updateInterestDataById, formData);
    if (interestData) {
      toast.success(interestData.message);
      yield put(actions.getInterestList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while updating interest');
  }
}

export const interestManagementSagas = [
  takeLatest(types.GET_INTEREST_LIST, getInterestList),
  takeLatest(types.UPDATE_INTEREST, updateInterestData),
  takeLatest(types.CREATE_INTEREST, createInterestData),
  takeLatest(types.GET_INTEREST_DATA, getInterestData),
  takeLatest(types.DELETE_INTEREST, deleteInterestData),
];
