import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

const useStylesFacebook = makeStyles(() => ({
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function CircularProgressWithLabel(props) {
  const classes = useStylesFacebook();
  return (
    <Box position="relative" display="inline-flex" className="circular-progress-bar">
      <CircularProgress
        variant="indeterminate"
        {...props}
        size={'6rem'}
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        thickness={4}
      />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="caption" component="div" color="textSecondary" className="percentage">{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};
export default CircularProgressWithLabel;
