import { Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { createSalesLeadUser } from '../../Services/salesLeadUserServiceClient';
import './style.scss';
import { useHistory } from 'react-router-dom';

const DataCollectorForm = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [userData, setUserData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDownloadLink, setShowDownloadLink] = useState(false);
  const {
    location: { search },
  } = useHistory();
  let isFormThere = true;
  if (search) {
    const urlParams = new URLSearchParams(search);
    const newLead = urlParams.get('new');
    isFormThere = newLead && +newLead !== 0;
  }

  const handleChange = (event) => {
    if (!event) {
      return;
    }
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    createSalesLeadUser(data)
      .then((response) => {
        const { success } = response;
        if (success) {
          setShowDownloadLink(true);
        } else {
          setShowDownloadLink(false);
        }
        setIsSubmitting(false);
      })
      .catch(() => {
        setShowDownloadLink(false);
        setIsSubmitting(false);
      });
  };

  const { url, gotoLink } = props;
  if (!isFormThere) {
    return (
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
        <b>
          To download, simply{' '}
          <a href={url} download="PSM for 1st responders.mp3" onClick={gotoLink}>
            click here
          </a>
          . If you want to continue with subscription. Please click below.
        </b>
      </Typography>
    );
  }

  if (showDownloadLink) {
    return (
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
        <b>
          Thank you for filling out the form,{' '}
          <a href={url} download="PSM for 1st responders.mp3" onClick={gotoLink}>
            click here
          </a>{' '}
          to Download your free audio recording.
        </b>
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
        <b>To download, Please let us know about yourself:</b>
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate className="first-responders-data-collector-form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              size="small"
              id="firstName"
              name="firstName"
              label="First name"
              disabled={isSubmitting}
              value={userData.firstName}
              fullWidth
              autoComplete="first-name"
              inputRef={register({ required: 'First name Required.', maxLength: { value: 80, message: 'First name must be less than 80 characters.' } })}
              error={!!errors.firstName}
              helperText={errors.firstName && errors.firstName.message}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              variant="outlined"
              size="small"
              name="lastName"
              label="Last name"
              disabled={isSubmitting}
              value={userData.lastName}
              fullWidth
              autoComplete="last-name"
              inputRef={register({ required: 'Last name Required.', maxLength: { value: 100, message: 'First name must be less than 100 characters.' } })}
              error={!!errors.lastName}
              helperText={errors.lastName && errors.lastName.message}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              size="small"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              disabled={isSubmitting}
              autoComplete="email-new"
              value={userData.email}
              inputRef={register({ required: 'Email Address Required', pattern: { value: /^\S+@\S+$/i, message: 'Enter valid Email Address.' } })}
              error={!!errors.email}
              onChange={handleChange}
              helperText={errors.email && (errors.email.type === 'validate' ? 'Email already in use try another email!' : errors.email.message)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="cellphone"
              variant="outlined"
              size="small"
              type="tel"
              name="cellPhone"
              disabled={isSubmitting}
              value={userData.cellPhone}
              inputRef={register({ required: false, maxLength: { value: 12, message: 'Cell phone must be less than 12 numbers.' } })}
              helperText={errors.cellPhone && errors.cellPhone.message}
              error={!!errors.cellPhone}
              label="Cell Phone"
              fullWidth
              autoComplete="cell phone"
              onChange={handleChange}
            />
          </Grid>
          <Grid container justify="center">
            <Button type="submit" variant="contained" color="primary">
              {isSubmitting ? <CircularProgress /> : 'Get your free Audio recording'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

DataCollectorForm.propTypes = {
  url: PropTypes.string,
  gotoLink: PropTypes.func,
};

export default DataCollectorForm;
