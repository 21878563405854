import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Container, Grid, Typography } from '@material-ui/core';
import bannerTop from '../images/First-Responder-Top.jpg';
import bannerMiddle from '../images/First-Responder-Middle.jpg';
import './style.scss';
import { connect } from 'react-redux';
import { HOME, SIGN_UP } from '../Routes/routingConstants';
import appConfig from '../config/appConfig';
import { getMusicByName } from '../Services/mp3ServiceClient';
import SubscriptionCard from '../Subscription/SubscriptionCards/SubscriptionCard';
import { setSubscriptionPlan } from '../Subscription/Redux/actions';
import DataCollectorForm from './DataCollectorForm';
import { subscriptions } from '../Subscription/subscriptions';

const FirstResponder = (props) => {
  const { authenticated } = props;
  const [psmFile, setPSMFile] = useState(null);
  const history = useHistory();
  const selectSubscriptionPlan = (tier) => {
    const { selectSubscription } = props;
    selectSubscription(tier);
    history.push(SIGN_UP);
  };

  const gotoLink = () => {
    const { history } = props;
    !authenticated && history.push(HOME);
  };

  useEffect(() => {
    getMusicByName('T54B')
      .then((psmFile) => {
        setPSMFile(psmFile.data[0]);
      })
      .catch(() => {
        setPSMFile(null);
      });
  }, []);

  const url = psmFile && `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/download/${psmFile._id}`;
  return (
    <Container className="first-responder-container">
      <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
        <Grid xs={12} sm={8} container direction={'column'} justify={'center'} alignItems={'center'}>
          <Grid xs={12}>
            <Typography component="p" variant="h5" color="textSecondary" className="page-sub-title">
              Complimentary Recording: Positive Stress Management and Healthy Effective Immune System for First Responders, Doctors, Nurses, and Health Care Workers
            </Typography>
          </Grid>
          <Grid xs={12} sm={4}>
            <div className="banner">
              <img className="banner-image" src={bannerTop} alt="banner" />
            </div>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
              The Covid-19 pandemic is not only causing physical harm, but it is making greater than usual demands on first responders, doctors, nurses, and health care workers in terms of their time, separation from loved ones, stress, and possible exposure to this extremely contagious disease.
            </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
              <b>This guided meditation was created specifically to help those on the illness’s front lines handle the potential stress and trauma you are dealing with.</b>
            </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
              Because your service is appreciated and because both your mental and physical health are important to all of us, this recording is our gift to you.
            </Typography>
            <DataCollectorForm url={url} gotoLink={gotoLink} />
            <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
              We recommend our online subscription customized based on your selected interest set in a repeating cycle to maximum effect by simply pressing the play button. For more details click in subscribe.
            </Typography>
            <Grid container spacing={5} justify="center">
              <SubscriptionCard tier={subscriptions[0]} selectSubscriptionPlan={selectSubscriptionPlan} />
            </Grid>
            <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
              Written and recorded by Terry Lee Brussel, Certified Clinical Hypnotherapist, this guided meditation was professionally created in a sound studio with music, post production, etc. It is focused on helping first responders, doctors, nurses, and other health care workers whose own mental
              health and safety may be challenged by the novel coronavirus situation.
            </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
              Terry Lee Brussel, CCHt began her career in 1969, pioneering telephone hypnosis in 1995. From the start, stress management through hypnotherapy and guided meditations have been a major part of her practice. Notably, Terry was featured in The Stress Strategists (1986, Dorothy Walters,
              Ed., Royal Publishing, Inc.), with an introduction by Dr. Normal Vincent Peale.
            </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
              This recording can and should be listened to while going to sleep and while sleeping to help guide the subconscious mind into a greater ability to manage stress; to a healthier immune system; to greater relaxation; and to deeper, more healing sleep.
            </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" component="p" className="page-para">
              It is hoped that using this recording will help with challenges you are feeling; keep healthy people from getting sick; and help those who have contracted the virus to recover more rapidly.
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} sm={4}>
          <div className="banner">
            <img className="banner-image" src={bannerMiddle} alt="banner" />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
const mapStateToProps = (state) => ({ authenticated: state.authentication.authenticated });
const mapDispatchToProps = (dispatch) => ({
  selectSubscription: (valueObj) => dispatch(setSubscriptionPlan(valueObj)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FirstResponder));
