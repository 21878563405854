import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppComponent from '../AppComponent';
import LoaderComponent from '../components/LoaderComponent';

import Home from '../Home';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import { HOME, SIGN_IN, ROOT, SIGN_UP, SUBSCRIPTION, PLAYLIST, FILE_MANAGEMENT, USER_MANAGEMENT, INTEREST_MANAGEMENT, PROFILE, FIRST_RESPONDERS, SALES_LEADS, FORGOT_PASSWORD, RESET_PASSWORD, USER_INTERESTS } from './routingConstants';
import Subscription from '../Subscription';
import Mp3List from '../RFTSMusicPlayer/Mp3List';
import FileManagement from '../AdminPanel/FileManagement';
import UserManagement from '../AdminPanel/UserManagement';
import InterestManagement from '../AdminPanel/InterestManagement';
import Profile from '../Profile';
import FirstResponder from '../FirstResponder';
import SalesLeadManagement from '../AdminPanel/SalesLeadManagement';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import InterestList from '../Profile/InterestList';
import GuardRoute from '../Services/routes.service';

const Routes = () => (
  <AppComponent>
    <LoaderComponent />
    <Switch>
      <Redirect exact from={ROOT} to={HOME} />
      <GuardRoute exact path={HOME} component={Home} />
      <GuardRoute exact path={SIGN_IN} component={SignIn} guardType='nonAuthOnly' />
      <GuardRoute exact path={FORGOT_PASSWORD} component={ForgotPassword} guardType='nonAuthOnly' />
      <GuardRoute exact path={RESET_PASSWORD} component={ResetPassword} />
      <GuardRoute exact path={FILE_MANAGEMENT} component={FileManagement} guardType='authOnly' />
      <GuardRoute exact path={USER_MANAGEMENT} component={UserManagement} guardType='authOnly'  />
      <GuardRoute exact path={INTEREST_MANAGEMENT} component={InterestManagement} guardType='authOnly'  />
      <GuardRoute exact path={SALES_LEADS} component={SalesLeadManagement} guardType='authOnly' />
      <GuardRoute exact path={SIGN_UP} component={SignUp} guardType='nonAuthOnly' />
      <GuardRoute exact path={SUBSCRIPTION} component={Subscription} />
      <GuardRoute exact path={PLAYLIST} component={Mp3List} guardType='authOnly' />
      <GuardRoute exact path={PROFILE} component={Profile} guardType='authOnly' />
      <GuardRoute exact path={USER_INTERESTS} component={InterestList} guardType='authOnly' />
      <GuardRoute exact path={FIRST_RESPONDERS} component={FirstResponder} />
    </Switch>
    <ToastContainer newestOnTop draggable={false} toastClassName="rfts-toast-container" hideProgressBar />
  </AppComponent>
);

export default Routes;
