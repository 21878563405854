import React, { Component } from 'react';

import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { IconButton, Grid } from '@material-ui/core';
import moment from 'moment';

import appConfig from '../../../config/appConfig';
import rftsLogo from '../../../images/rftsLogo.jpg';
import MaterialDataTable from '../../../components/MaterialDataTable';

import './style.scss';
class FileList extends Component {
  startPlayingMusic = (music) => {
    const { updateMusicParams, playMusic, musicPlayerOptions, stopMusic } = this.props;
    stopMusic();
    music.cover= music.thumbnailPath ? `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3/thumbnail/${music._id}` : rftsLogo;
    const list = [music];
    updateMusicParams({ ...musicPlayerOptions, audioLists: list, playIndex: 0 });
    setTimeout(() => playMusic(), 0);
  };
  render() {
    const { fileList, viewFileHandler, deleteFileData } = this.props;
    const data =
      fileList &&
      fileList.map((mp3Item) => {
        const { name, description = '-', _id, isCGMR = false, createdAt, isSpecial = false, sku} = mp3Item;
        const actions = (
          <Grid container justify="flex-start">
            <IconButton onClick={() => viewFileHandler(_id, false)}>
              <EditRoundedIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => deleteFileData(_id)}>
              <DeleteRoundedIcon color="error" />
            </IconButton>
            <IconButton onClick={() => this.startPlayingMusic(mp3Item)}>
              <PlayCircleOutlineIcon color="primary" />
            </IconButton>
          </Grid>
        );
        const createdDate = moment(createdAt).format('LL');
        let type = '';
        if (isCGMR) {
          type = 'CGMR';
        } else if (isSpecial) {
          type = 'Special';
        } else {
          type = 'General';
        }
        return {
          name,
          type,
          createdAt: createdDate,
          description: `${description}${sku?` (${sku})`:''}`,
          actions,
        };
      });
    const headers = [
      { title: 'File Name', field: 'name' },
      { title: 'Recording Title (SKU)', field: 'description' },
      { title: 'Type', field: 'type', width: '10%' },
      { title: 'Created On', field: 'createdAt', width: '12%', align: 'center', customSort: (a, b) => new Date(b.createdAt) - new Date(a.createdAt) },
      { title: 'Actions', field: 'actions', width: '15%', sorting: false, align: 'center' },
    ];
    return <MaterialDataTable headers={headers} data={data} className="file-list" />;
  }
}
export default FileList;
