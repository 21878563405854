import { promiseReturn } from './serviceUtils';
import appConfig from '../config/appConfig';
const BASE_URI = `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/mp3`;

export const getAllFiles = async () => {
  const url = `${BASE_URI}`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getFileInformation = async (fileId) => {
  const url = `${BASE_URI}/id/${fileId}`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const deleteFile = async (fileId) => {
  const url = `${BASE_URI}/${fileId}`;
  const urlData = {
    url,
    method: 'delete',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const syncFilesWithBoxCom = async () => {
  const url = `${BASE_URI}/sync`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const createOrUploadFile = async (data, onUploadProgress) => {
  const url = `${BASE_URI}/upload`;
  const options = {
    onUploadProgress,
  };
  console.log('file data', data);
  const urlData = {
    url,
    method: 'post',
    params: data,
    options,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const findMp3ByName = async (name) => {
  const url = `${BASE_URI}/name`;
  const urlData = {
    url,
    method: 'post',
    params: { name },
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const updateFile = async (data) => {
  const url = `${BASE_URI}/update`;
  const urlData = {
    url,
    method: 'post',
    params: data,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};
