import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const FileTypeSelection = (props) => {
  const valueEntityArray = [
    { value: 'general', label: 'General?' },
    { value: 'isSpecial', label: 'is Special?' },
    { value: 'isCGMR', label: 'is CGMR?' },
  ];
  const { setFileData, fileData } = props;
  const { isSpecial, isCGMR } = fileData;
  const handleChangeEntityData = (event, selectedOption) => {
    if (selectedOption === 'isCGMR') {
      setFileData({ ...fileData, isCGMR: true, isSpecial: false });
    } else if (selectedOption === 'isSpecial') {
      setFileData({ ...fileData, isSpecial: true, isCGMR: false });
    } else {
      setFileData({ ...fileData, isSpecial: false, isCGMR: false });
    }
  };
  let selected = null;
  if (isSpecial) {
    selected = { value: 'isSpecial', label: 'is Special?' };
  } else if (isCGMR) {
    selected = { value: 'isCGMR', label: 'is CGMR?' };
  } else {
    selected = { value: 'general', label: 'General?' };
  }
  return (
    <RadioGroup aria-label="entity types" name="entity-type" value={selected.value} onChange={handleChangeEntityData} className="entity-type">
      {valueEntityArray.map((dataItem) => (
        <FormControlLabel value={dataItem.value} key={dataItem.value} control={<Radio />} label={dataItem.label} />
      ))}
    </RadioGroup>
  );
};

FileTypeSelection.propTypes = {
  setFileData: PropTypes.func,
  fileData: PropTypes.object,
};

export default FileTypeSelection;
