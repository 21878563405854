/* eslint-disable no-undef */
const appConfig = {
  endPointStartWith: '/api',
  rftsUri: '/rfts',
  baseURI: process.env.REACT_APP_URL,
  version: '/v1',
  stripe_key:
    process.env.REACT_APP_ENVIRONMENT === 'staging' || process.env.REACT_APP_ENVIRONMENT === 'development'
      ? 'pk_test_51K50wqJW8OynpHjacfpF5LibKqvrt5qIrTJHbRV27r1x43qyF20lTe6rd7xMI7De2hfEnkYn3t1MkTC7HPH9pvwr00wWyik3OH'
      : 'pk_live_51HSPMYGkZPk9ZIuh9BPcMX3U6JapJQd9vNV4dOis3Mx1YdL3XmC8EEnwOyefUZUDykMyuQCaME5VwPUjiXiOpfcs00xwc9LVVp',
  rftsv2_apikey: '01H00J701W8F6E9B4P73ZD4S13',
  rftsv2_url: process.env.REACT_APP_ENVIRONMENT === 'staging' || process.env.REACT_APP_ENVIRONMENT === 'development' ? 'https://universe-staging.reachforthestars.today/api' : 'https://universe-production.reachforthestars.today/api',
};
export default appConfig;
