import { takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import { getAllUsers, getUserById, deleteUserById, createUser, updateUserDataById, getAllRoles, getUsersByModeratorId, getAllModerators, getUserCurrentSession, getSessionInfoBySessionIndex, resetUserSessionById, setUserSessionDetailsByUserId } from '../../../Services/userManagementServiceClient';

import { stopLoader, startLoader } from '../../../components/LoaderComponent/Redux/actions';
import actions from './actions';
import { isModerator } from '../../../utils/stringUtils';
import RftsV2Service from '../../../Services/rftsv2.service';

function* getUserList() {
  try {
    yield put(startLoader());
    const { roles, userDetails } = yield select((state) => state.authentication);
    let userList;
    if (isModerator(roles)) {
      userList = yield call(getUsersByModeratorId, { userId: userDetails._id });
    } else {
      userList = yield call(getAllUsers);
    }

    if (userList && userList.data) {
      yield put(actions.setUserList(userList.data));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching user list');
  }
}

function* getModeratorList() {
  try {
    yield put(startLoader());
    const moderatorList = yield call(getAllModerators);
    if (moderatorList && moderatorList.data) {
      const moderators = moderatorList.data.map((element) => ({
        ...element,
        value: element._id,
        label: `${element.firstName} ${element.lastName}`,
      }));
      yield put(actions.setModeratorList(moderators));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching moderators list');
  }
}

function* getRoleList() {
  try {
    yield put(startLoader());
    const roleList = yield call(getAllRoles);
    if (roleList && roleList.data) {
      yield put(actions.setUserRoles(roleList.data));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching role list');
  }
}

function* getUserData(inputObj) {
  const userId = inputObj.value;
  try {
    yield put(startLoader());
    const userData = yield call(getUserById, userId);
    const userDataInterest = yield call(RftsV2Service.getUserInterest, userId);
    if (userData && userDataInterest.success) {
      userData.data.selectedInterests = userDataInterest.data.selectedInterests;
      yield put(actions.setUserData(userData.data));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while fetching user data');
  }
}

function* deleteUserData(inputObj) {
  const userId = inputObj.value;
  try {
    yield put(startLoader());
    const userData = yield call(deleteUserById, userId);
    if (userData) {
      toast.success(userData.message);
      yield put(actions.getUserList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while deleting user data');
  }
}

function* createUserData(inputObj) {
  const formData = inputObj.value;
  try {
    if (!formData) {
      return;
    }
    yield put(startLoader());
    const userData = yield call(createUser, formData);
    if (userData) {
      toast.success(userData.message);
      yield put(actions.getUserList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while creating user');
  }
}

function* updateUserData(inputObj) {
  const formData = inputObj.value;
  try {
    if (!formData) {
      return;
    }
    yield put(startLoader());
    const userData = yield call(updateUserDataById, formData);
    if (userData) {
      toast.success(userData.message);
      yield put(actions.getUserList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    console.log(error);
    toast.error('There was some error while updating user');
  }
}

function* getUserSessionDetails(inputObj) {
  const userId = inputObj.value;
  try {
    yield put(startLoader());
    const sessionData = yield call(getUserCurrentSession, userId);
    if (sessionData) {
      yield put(actions.setCurrentUserSession(sessionData.data));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    yield put(
      actions.setCurrentUserSession({
        currentSession: null,
        sessionIndex: 0,
        interestPlayingOrder: [],
      }),
    );
  }
}

function* getSessionInfoBySession(inputObj) {
  const { userId, interestPlayingOrder, session, isPlatinum } = inputObj.value;
  try {
    yield put(startLoader());
    const sessionData = yield call(getSessionInfoBySessionIndex, userId, session, interestPlayingOrder, isPlatinum);
    if (sessionData) {
      yield put(actions.setCurrentUserSession(sessionData.data));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    yield put(
      actions.setCurrentUserSession({
        currentSession: null,
        sessionIndex: 0,
        interestPlayingOrder: [],
      }),
    );
  }
}

function* moveUserSession(inputObj) {
  const { userId, interestPlayingOrder, session, sessionDetails } = inputObj.value;
  try {
    yield put(startLoader());
    const sessionData = yield call(setUserSessionDetailsByUserId, userId, session, sessionDetails, interestPlayingOrder);
    if (sessionData) {
      yield put(actions.getUserList());
      yield put(actions.getCurrentUserSession(userId));
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
  }
}

function* resetUserSessions(inputObj) {
  const userId = inputObj.value;
  try {
    yield put(startLoader());
    const sessionData = yield call(resetUserSessionById, userId, false);
    if (sessionData) {
      yield put(actions.getUserList());
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
  }
}

export const userManagementSagas = [
  takeLatest(types.GET_USER_LIST, getUserList),
  takeLatest(types.GET_ROLE_LIST, getRoleList),
  takeLatest(types.GET_MODERATOR_LIST, getModeratorList),
  takeLatest(types.UPDATE_USER, updateUserData),
  takeLatest(types.CREATE_USER, createUserData),
  takeLatest(types.GET_USER_DATA, getUserData),
  takeLatest(types.DELETE_USER, deleteUserData),
  takeLatest(types.GET_CURRENT_USER_SESSION, getUserSessionDetails),
  takeLatest(types.GET_SESSION_INFO_BY_SESSION_INDEX, getSessionInfoBySession),
  takeLatest(types.MOVE_USER_SESSION, moveUserSession),
  takeLatest(types.RESET_USER_SESSIONS, resetUserSessions),
];
