import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import PersonalDetailsForm from './PersonalDetailsForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import InterestsForm from './InterestsForm';
import { SIGN_IN } from '../Routes/routingConstants';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    width: 'auto',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      width: 800,
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  navigationLink: {
    color: 'blue',
    cursor: 'pointer',
  },
}));

const stepsArray = ['Interest Selection', 'Personal Details', 'Review your details', 'Payment'];

function getStepContent(step, steps, detailProps, interestList, handleNext, handleBack) {
  const stepContentObject = {
    'Interest Selection': () => <InterestsForm detailProps={detailProps} handleNext={handleNext} interests={interestList} steps={steps} activeStep={step} handleBack={handleBack} />,
    'Personal Details': () => <PersonalDetailsForm detailProps={detailProps} handleNext={handleNext} steps={steps} activeStep={step} handleBack={handleBack} />,
    'Review your details': () => <Review detailProps={detailProps} handleNext={handleNext} steps={steps} activeStep={step} handleBack={handleBack} />,
    'Payment': () => <PaymentForm detailProps={detailProps} handleNext={handleNext} steps={steps} activeStep={step} handleBack={handleBack} />,
  };
  return stepContentObject[step]() || null;
}

function SignUpStepperContainer(props) {
  const classes = useStyles();
  const { detailProps, signUpUserData, singUpSuccess, interestList, subscription } = props;
  const isFree = subscription && subscription.title.toLowerCase() === 'free';
  const steps = isFree ? [...stepsArray.slice(0, 3)] : [...stepsArray];
  // const steps = [...stepsArray];
  const [activeStep, setActiveStep] = React.useState(steps[0]);

  const gotoLink = (url) => {
    const { history } = props;
    history.replace(url);
  };
  const handleNext = (paymentMethodId, promoCode = '') => {
    if (activeStep === steps[steps.length - 1]) {
      const { userDetails } = detailProps;
      if (typeof paymentMethodId === 'string') signUpUserData({ ...userDetails, paymentMethodId, promoCode });
      else signUpUserData({ ...userDetails, paymentMethodId: '', promoCode });
    }
    steps.indexOf(activeStep) < steps.length - 1 && setActiveStep(steps[steps.indexOf(activeStep) + 1]);
  };

  const handleBack = () => {
    steps.indexOf(activeStep) > 0 && setActiveStep(steps[steps.indexOf(activeStep) - 1]);
  };

  return (
    <Paper className={classes.paper}>
      <Typography component="h1" variant="h4" align="center">
        Sign Up
      </Typography>
      <Stepper activeStep={steps.indexOf(activeStep)} className={classes.stepper}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        {singUpSuccess ? (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Signed up successfully.
            </Typography>
            <Typography variant="subtitle1" component={'p'} onClick={() => gotoLink(SIGN_IN)} className={classes.navigationLink}>
              Click here to Sign In.
            </Typography>
          </React.Fragment>
        ) : (
          getStepContent(activeStep, steps, detailProps, interestList, handleNext, handleBack)
        )}
      </React.Fragment>
    </Paper>
  );
}

export default withRouter(SignUpStepperContainer);
