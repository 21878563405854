import { Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { HOME } from '../../Routes/routingConstants';
import './style.scss';

const SubscriptionInfo = () => {
  const history = useHistory();
  const gotoLink = (url) => history.push(url);
  return (
    <div className="subscription-info">
      <Typography component="p" variant="h5" align="center" color="textPrimary" className="page-sub-title">
        Begin Reaching Your Highest Potential … All While You Sleep
      </Typography>
      <Typography variant="h6" align="justify" color="textPrimary" component="p" className="page-para">
        The Reach for the Stars phone app customizes guided meditation recordings to your specific desires. With this application, recordings to help you achieve your most important goals will be played in a rotation, ensuring you hear the messages on the subconscious level while falling asleep and
        while sleeping. These recordings assist you in overcoming the challenges in your life and moving beyond them to reach your Highest Potential in areas physical, mental, emotional and spiritual as well as financial. Manifest your goals into reality!
      </Typography>
      <Typography variant="h6" align="justify" color="textPrimary" component="p" className="page-para">
        Guided meditations are recorded by{' '}
        <Typography className="href-link" variant="span" onClick={() => gotoLink(HOME)}>
          Terry Brussel-Rogers
        </Typography>
        , who has spent over 50 years helping clients reach their highest potential through hypnosis sessions, coaching, and pre-recorded guided meditations.
      </Typography>
    </div>
  );
};

export default SubscriptionInfo;
