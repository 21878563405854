import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import healthyImage from '../../images/healthy001.jpg';
import crepuscularImage from '../../images/crepuscular002.jpg';
import ideaImage from '../../images/idea.jpg';
import lovenrespectImage from '../../images/lovenrespect.jpg';
import mindImage from '../../images/mind001.jpg';
import sleepImage from '../../images/sleeping1.jpg';
import usmoneyImage from '../../images/bagcoins.png';

import Grid from '@material-ui/core/Grid';
import MediaCard from '../MediaCard';
import { RFTSV2Video } from '../Video';

const useStyles = makeStyles({
  productInformation: {
    marginBottom: '20px',
  },
  pagePara: {
    fontSize: '1.2rem',
  },
  mediaCardContainer: {
    marginTop: 10,
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
});

const ProductInformation = (props) => {
  const classes = useStyles();
  const { startNowComponent, gotoSubscription } = props;
  return (
    <Grid xs={12} md={12} container className={classes.productInformation}>
      <Grid item xs={12}>
        {startNowComponent}
      </Grid>
      <Grid item xs={12} md={12}>
        {/* <Card className={classes.card}> */}
        <div className={classes.cardDetails}>
          {/* <CardContent> */}
          <Typography variant="subtitle1" align="justify" paragraph className={classes.pagePara}>
            The Reach for the Stars application customizes guided meditation recordings to your specific desires. With this application, recordings to help you with your most important goals will be played in rotation, ensuring you hear the messages on the subconscious level while falling asleep.
            These quality recordings are scheduled based on your unique goals and desires, aiding you to reach your Highest Potential in areas physical, mental, emotional, spiritual, and financial.
          </Typography>
          <Typography variant="subtitle1" align="justify" paragraph className={classes.pagePara}>
            With this application, the meditations play, based upon your choice either 1 or 2 in rotation each night over a period of weeks or months, 21 times each: the required number of repetitions to establish a new mindset. These meditations, constantly being created and added to our library,
            are selected by a highly trained professional hypnotherapist and scheduled according to priorities you select.
          </Typography>
          <Typography variant="subtitle1" align="justify" paragraph className={classes.pagePara}>
            There are four options, there are packages for every budget, ensuring you can easily be on the road to achieving your goals.{' '}
          </Typography>
          <Typography variant="subtitle1" align="justify" paragraph className={classes.pagePara}>
            Recordings can be revised every 30 or 90 days depending on the subscription chosen, so new recordings can be scheduled as your goals change.{' '}
          </Typography>
          <Typography variant="subtitle1" align="justify" paragraph className={classes.pagePara}>
            Imagine the Genie of your powerful subconscious mind activated each night to create:{' '}
          </Typography>
          {/* </CardContent> */}
        </div>

        {/* <Hidden xsDown>
            <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
          </Hidden> */}
        {/* </Card> */}
      </Grid>
      <Grid item container xs={12} md={12} justify={'space-between'}>
        <Grid container item xs={12} md={3} justify={'center'} className={classes.mediaCardContainer}>
          <MediaCard image={healthyImage} text="Healthy longevity, vibrant energy, a body to delight in." onClick={gotoSubscription} />
        </Grid>
        <Grid container item xs={12} md={3} justify={'center'} className={classes.mediaCardContainer}>
          <MediaCard image={usmoneyImage} text="Financial Abundance." onClick={gotoSubscription} />
        </Grid>
        <Grid container item xs={12} md={3} justify={'center'} className={classes.mediaCardContainer}>
          <MediaCard image={lovenrespectImage} text="A joyful new or enhanced present relationship." onClick={gotoSubscription} />
        </Grid>
        <Grid container item xs={12} md={3} justify={'center'} className={classes.mediaCardContainer}>
          <MediaCard image={mindImage} text="The memory and mental focus you want now and lifelong." onClick={gotoSubscription} />
        </Grid>
      </Grid>
      <Grid item container xs={12} md={12} justify={'center'}>
        <Grid container item xs={12} md={3} justify={'center'} className={classes.mediaCardContainer}>
          <MediaCard image={ideaImage} text="Inspiration at Will for creative and entrepreneurial endeavors." onClick={gotoSubscription} />
        </Grid>
        <Grid container item xs={12} md={3} justify={'center'} className={classes.mediaCardContainer}>
          <MediaCard image={crepuscularImage} text="A greater connection with your spirituality." onClick={gotoSubscription} />
        </Grid>
        <Grid container item xs={12} md={3} justify={'center'} className={classes.mediaCardContainer}>
          <MediaCard image={sleepImage} text="A life free of smoking, overeating, and other bad habits." onClick={gotoSubscription} />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '44px' }} >
        <RFTSV2Video />
      </Grid>
    </Grid>
  );
};
ProductInformation.propTypes = {
  startNowComponent: PropTypes.element,
  gotoSubscription: PropTypes.func,
};

export default ProductInformation;
