import { promiseReturn } from './serviceUtils';
import appConfig from '../config/appConfig';
const BASE_URI = `${appConfig.baseURI}${appConfig.endPointStartWith}${appConfig.rftsUri}${appConfig.version}/interest`;

export const getAllInterests = async () => {
  const url = `${BASE_URI}/all`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const getInterestById = async (interestId) => {
  const url = `${BASE_URI}/id/${interestId}`;
  const urlData = {
    url,
    method: 'get',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const createInterest = async (data) => {
  const url = `${BASE_URI}/create`;
  const urlData = {
    url,
    method: 'post',
    params: data,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};


export const updateInterestDataById = async (data) => {
  const url = `${BASE_URI}/update`;
  const urlData = {
    url,
    method: 'post',
    params: data,
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};

export const deleteInterestById = async (interestId) => {
  const url = `${BASE_URI}/${interestId}`;
  const urlData = {
    url,
    method: 'delete',
  };
  return new Promise((resolve, reject) => promiseReturn(urlData, resolve, reject));
};
