import { bool, func } from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import DialogComponent from '../DialogComponent';

const NotActiveSubscription = (props) => {
  const { open, toggle } = props;

  const header = 'Playing Error';
  const body = 'Your do not have an Active Subscription to play this audio. Please review your payment details or contact the administrator.';
  return <DialogComponent body={body} header={header} open={open} onClose={toggle} fullWidth={false} maxWidth="md" />;
};

NotActiveSubscription.propTypes = {
  isHavingActiveSubscription: bool,
  open: bool,
  toggle: func,
};

export default withRouter(NotActiveSubscription);
