import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import { signUp } from '../../Services/restClient';
import { stopLoader, startLoader } from '../../components/LoaderComponent/Redux/actions';
import actions from './actions';

function* signUpUser(inputObj) {
  try {
    if (!inputObj || !inputObj.value) {
      return;
    }
    yield put(startLoader());

    const signupData = inputObj.value;
    const {
      email,
      subscription: { amount, title: subscriptionName },
      selectedInterests: interests = [],
    } = signupData;
    if (!email) {
      return;
    }
    signupData.subscription = subscriptionName;
    if(subscriptionName === 'Free'){
      signupData.paymentBypassed = true;
    }
    if (signupData.age < 18) {
      signupData.hideMatureContent = true;
    }
    signupData.selectedInterests = interests && interests.length ? interests.map((interestItem) => interestItem._id) : [];
    signupData.username = email;
    signupData.roles = ['user'];
    // stripe accepts lowest denomination of dollar.
    signupData.amount = amount * 100;
    const responseData = yield call(signUp, signupData);

    if (responseData) {
      toast.success(responseData.message);
      yield put(actions.setSignUpSuccessful(true));

      if (responseData.paymentData && !signupData.paymentBypassed) {
        const { status = 'succeeded', message } = responseData.paymentData;
        (!!status || status !== 'succeeded') && toast.error(message);
      }
    }
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
    yield put(actions.setSignUpSuccessful(false));
    if (error && error.data && error.data.paymentData) {
      const { status = '', raw: { message = '' } = {} } = error.data.paymentData;
      (!status || status !== 'succeeded') && toast.error(message, { autoClose: false });
    } else if (error && error.data && error.data.message) {
      toast.error(error.data.message);
    } else {
      toast.error('There is some Error while Signing you up.');
    }
  }
}

export const signupSagas = [takeLatest(types.SIGN_UP_USER, signUpUser)];
