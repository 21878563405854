import React from 'react';
import classnames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function DialogComponent(props) {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { header, footer, body, open, onClose, scroll, fullWidth, maxWidth, className } = props;
  return (
    <Dialog fullScreen={fullScreen} open={open} fullWidth={fullWidth} maxWidth={maxWidth} className={classnames('rfts-dialog', className)} onClose={onClose} scroll={scroll}>
      {header && (
        <DialogTitle className="rfts-dialog-header" onClose={onClose}>
          {header}
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      <DialogContent dividers className="rfts-dialog-content">
        {body}
      </DialogContent>
      {footer && <DialogActions className="rfts-dialog-footer">{footer}</DialogActions>}
    </Dialog>
  );
}

DialogComponent.propTypes = {
  header: PropTypes.object,
  footer: PropTypes.object,
  body: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  scroll: PropTypes.string,
  fullWidth: PropTypes.string,
  maxWidth: PropTypes.bool,
  className: PropTypes.string,
};
DialogComponent.defaultProps = {
  scroll: 'paper',
  fullWidth: 'sm',
  maxWidth: false,
};

export default DialogComponent;
