export const ROOT = '/';
export const HOME = '/home';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const SUBSCRIPTION = '/subscription';
export const PLAYLIST = '/playlist';
export const FIRST_RESPONDERS = '/first-responders';

export const ADMIN = '/admin';
export const FILE_MANAGEMENT = `${ADMIN}/file`;
export const USER_MANAGEMENT = `${ADMIN}/user`;
export const INTEREST_MANAGEMENT = `${ADMIN}/interest`;
export const SALES_LEADS = `${ADMIN}/sales-leads`;

export const PROFILE = '/user/profile';
export const USER_INTERESTS = '/user/interests';
export const USER_PAYMENTS = '/user/payments';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const authorizedUrls = [PLAYLIST, FILE_MANAGEMENT, USER_MANAGEMENT, INTEREST_MANAGEMENT, SALES_LEADS, HOME, SUBSCRIPTION, PROFILE, USER_INTERESTS];
export const adminAuthorizedUrls = [FILE_MANAGEMENT, USER_MANAGEMENT, INTEREST_MANAGEMENT, SALES_LEADS, HOME, SUBSCRIPTION];

export const leftNavMenuItems = {
  home: {
    url: HOME,
    title: 'Home',
  },
  subscription: {
    url: SUBSCRIPTION,
    title: 'Subscription',
  },
  playlist: {
    url: PLAYLIST,
    title: 'Playlist',
    isAuthorized: true,
  },
  fileManagement: {
    url: FILE_MANAGEMENT,
    title: 'Files',
    isAdmin: true,
  },
  userManagement: {
    url: USER_MANAGEMENT,
    title: 'Users',
    isAdmin: true,
  },
  interestManagement: {
    url: INTEREST_MANAGEMENT,
    title: 'Interests',
    isAdmin: true,
  },
  salesLeads: {
    url: SALES_LEADS,
    title: 'Sales Leads',
    isAdmin: true,
  },
  firstResponders: {
    url: FIRST_RESPONDERS,
    title: 'First Responders',
  },
  acesuccess: {
    url: 'https://acesuccess.com/',
    title: 'Success Center',
  },
};

export const getLeftNavItems = (authenticated, isAdminRole) => {
  const { home, playlist, fileManagement, userManagement, interestManagement, acesuccess, salesLeads } = leftNavMenuItems;
  if (!authenticated) {
    return [home, acesuccess];
  } else {
    if (isAdminRole) {
      return [home, fileManagement, userManagement, interestManagement, salesLeads];
    } else {
      return [home, playlist, acesuccess];
    }
  }
};
