import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import useResponsiveFontSize from './useResponsiveFontSize';
import './style.scss';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  return useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: '"Nunito Sans", sans-serif',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  );
};

const SplitCardForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { handleNext, detailProps } = props;
    const {
      userDetails: { city, country, address1: line1, address2: line2, zip: postal_code, state, firstName, lastName },
    } = detailProps;
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const billing_details = {
      name: `${firstName} ${lastName}`,
      address: {
        line1,
        line2,
        postal_code,
        city,
        state,
        country,
      },
    };
    const { promoCode: promoCodeElement } = event.target;
    let promoCode = '';
    if (promoCodeElement) {
      promoCode = promoCodeElement.value;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details,
    });
    console.log('[PaymentMethod]', paymentMethod);

    if (error) {
      console.log('error', error);
      return;
    }

    handleNext(paymentMethod.id, promoCode);
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-form">
      <label className="stripe-label">
        Card number
        <CardNumberElement options={options} />
      </label>
      <label className="stripe-label">
        Expiration date
        <CardExpiryElement options={options} />
      </label>
      <label className="stripe-label">
        CVC
        <CardCvcElement options={options} />
      </label>
      <label className="stripe-label">
        Promo Code
        <input type="text" name="promoCode" className="InputElement StripeElement" placeholder="Promo Code" />
      </label>
      <button type="submit" disabled={!stripe} className="stripe-button">
        Pay and Signup
      </button>
    </form>
  );
};

SplitCardForm.propTypes = {
  handleNext: PropTypes.func,
  detailProps: PropTypes.object,
};

export default SplitCardForm;
